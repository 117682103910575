import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBrandListAction, getMerchantByBrandIdAction } from '../../services/newBrandBranch/checkBrandExistApiCall';
import { getCuisines, getWhitelabels, getWhitelabelsData } from '../../services/newBrandBranch/checkBrandExistApiCall';
import { dashboardSelectors } from '../../redux/dashboard';
import Select from "react-select";
import Icon from '@material-ui/core/Icon';
import MultiSelect from "react-multi-select-component";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import {
  success as successNotificationAction,
  error as errorNotificationAction,
} from 'react-notification-system-redux';
import { notificationOpts } from '../../config';
import * as yup from 'yup';

import axios from "axios";
import BranchAssets from "./BranchAssests";
import { isSubmitting } from "redux-form";
import { updateBranchWhiteLabelAction } from '../../services/newBrandBranch/createBranchApiCall';
import { updateBrandWhiteLabelAction } from '../../services/newBrandBranch/createBrandApiCall';
import AWS from 'aws-sdk';

export default function WhitelabelControls() {
  const [allBrandList, setAllBrandList] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [brandData, setbrandData] = useState({});
  const [brandDetails, setBrandDetails] = useState({});
  const [brandAdsDetails, setbrandAdsDetails] = useState({});
  const [branchAdsDetails, setbranchAdsDetails] = useState({});
  const [phases, setPhases] = useState([]);
  const [selectedPhase, setSelectedPhase] = useState(8);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [phaseList, setPhaseList] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [whitelabels, setWhitelabels] = useState([]);
  const [allCuisinesList, setAllCuisinesList] = useState([]);
  const [assets, setAssets] = useState([]);
  const dispatch = useDispatch();
  const [phasesAvailable, setPhasesAvailable] = useState([])
  const [selectedWhiteLabel, setSelectedWhiteLabel] = useState({});
  const [cuisineOptions, setCuisineOptions] = useState([]);
  const [branchFieldError, setBranchFieldError] = useState({});
  const [showAds, setShowAds] = useState(false);
  const [lastAddedImage, setLastAddedImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [newImage, setNewImage] = useState("");
  const AWS = require('aws-sdk');

  // console.log("selected branch :   " + JSON.stringify(selectedBranch))
  const selectedCuisines = brandDetails?.branches?.[selectedBranch]?.cuisines_list ?? [];
  // const selectedPriceRange = brandDetails?.branches?.[selectedBranch]?.price_range ?? "";

  // const [allCuisinesList, setAllCuisinesList] = useState([]);
  // console.log("Phase list   :   " + JSON.stringify(allCuisinesList))
  // console.log(brandDetails)
  const clearData = () => {
    console.log("Clear button clicked");

    setSelectedBrand(null);
    setBrandDetails({ branches: {} }); // Ensure deep reset
    setSelectedBranch("");
    setBranchFieldError({});

    setTimeout(() => {
      // console.log("selectedBrand:", selectedBrand);
      // console.log("brandDetails:", brandDetails);
      // console.log("selectedBranch:", selectedBranch);
    }, 1000);
  };


  const [imageAssets, setImageAssets] = useState([]);
  const [videoAssets, setVideoAssets] = useState([]);

  const handleImageChange = (index, value) => {
    const updatedImages = [...imageAssets];
    updatedImages[index] = value;
    setImageAssets(updatedImages);
  };
  const handleUpdateClick = async () => {
    setIsSubmitting(true);
    try {
      await handleBranchUpdate();
    } catch (error) {
      console.error("Update failed:", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleVideoChange = (index, value) => {
    const updatedVideos = [...videoAssets];
    updatedVideos[index] = value;
    setVideoAssets(updatedVideos);
  };


  useEffect(() => {
    if (lastAddedImage) {
      const timer = setTimeout(() => setLastAddedImage(null), 3000); // Remove tick after 3 seconds
      return () => clearTimeout(timer); // Cleanup on re-run
    }
  }, [lastAddedImage]);


  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => setErrorMessage(""), 3000); // Remove error after 3s
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const branchDateUpdate = (newValue, index, field) => {
    if (!selectedBranch) {
      console.warn("No branch selected for update!");
      return;
    }

    console.log("New Value:", newValue);
    console.log("Field:", field);

    setBrandDetails((prevDetails) => {
      const updatedBranches = JSON.parse(JSON.stringify(prevDetails.branches)); // Deep clone 🔥

      if (field === "branch_experience") {
        updatedBranches[selectedBranch].branch_experience = newValue; // Set directly the whole array
      } else if (field.includes("branch_experience")) {
        const [_, expIndex, expField] = field.split(".");
        updatedBranches[selectedBranch].branch_experience[expIndex] = {
          ...updatedBranches[selectedBranch].branch_experience[expIndex],
          [expField]: newValue,
        };
      } else {
        updatedBranches[selectedBranch][field] = newValue;
      }

      console.log("UPDATED BRANCHES", updatedBranches);
      return { ...prevDetails, branches: updatedBranches }; // This triggers re-render now 💪
    });
  };

  const handleBranchSelection = (branchId) => {
    console.log("Branch selected:", branchId);
    setSelectedBranch(branchId);
  };
  // console.log("All Brand List:", allCuisinesList);
  const [, setRender] = useState(false);
  const forceRender = () => setRender((prev) => !prev);

  // Update assets only when a brand is selected
  useEffect(() => {
    // console.log("Selected Branch Changed:", selectedBranch);
    // console.log("Brand Details:", brandDetails);
    // console.log("Branches Object:", brandDetails?.branches);

    if (!brandDetails?.branches) {
      console.warn("Brand details do not contain branches.");
      setAssets([]);
      return;
    }

    const branchKey = selectedBranch?.trim();
    // console.log("Selected Branch Data:", brandDetails.branches?.[branchKey]);

    if (branchKey && brandDetails.branches[branchKey]) {
      // console.log("Updating assets for branch:", branchKey);
      const { creatives = [] } = brandDetails.branches[branchKey];
      setAssets([...creatives]);
    } else {
      console.warn("No branch selected or branch not found, clearing assets.");
      setAssets([]);
    }
  }, [selectedBranch, brandDetails]);

  useEffect(() => {
    // console.log("Brand Details Updated:", brandDetails);
  }, [brandDetails]);


  // Handle input change
  const handleAssetChange = (index, newValue) => {
    const updatedAssets = [...assets];
    updatedAssets[index] = newValue;
    setAssets(updatedAssets);
  };
  // useEffect(() => {
  //     const fetchData = async () => {
  //         getWhitelabelsData(response => {
  //             console.log("Response   ?:   " + JSON.stringify(response))
  //             const phasesAvailable = [];
  //             if (response) {
  //                 response.forEach(e => {
  //                     console.log("Response   Serial No?:  E  " + JSON.stringify(e))
  //                     if (e.partnerSerialNo === "KPSNAM01") {
  //                         console.log("Response   Serial No?: No  " + JSON.stringify(e))
  //                         if (e.phases) {
  //                             console.log("Response   Serial No?:  Phase  " + JSON.stringify(e.phases))
  //                             e.phases.forEach(element => {
  //                                 element.label = element.phaseNumber;
  //                                 element.value = element.phaseNumber;
  //                                 if (new Date() >= new Date(element.phaseStartDate) && new Date() <= new Date(element.phaseEndDate)) {
  //                                     setSelectedPhase(element);
  //                                     phasesAvailable.push(element.value);
  //                                     console.log("Get WHite Labels info: Phases ", JSON.stringify(phasesAvailable));
  //                                     setPhasesAvailable(phasesAvailable);
  //                                 }
  //                             });
  //                             setPhaseList(e.phases);
  //                         }
  //                     }
  //                 });
  //                 // setPhasesAvailable(phasesAvailable);
  //                 console.log("Get WHite Labels info: 1", JSON.stringify(phasesAvailable));
  //                 console.log("Get WHite Labels info: 2", JSON.stringify(phaseList));
  //             }
  //             console.log("Get WHite Labels info: 3", JSON.stringify(phasesAvailable));
  //             setWhitelabels(response);
  //         })
  //     };
  //     fetchData();
  // }, [phaseList.length]);


  //phasessssss
  useEffect(async () => {
    dispatch(getWhitelabels(response => {
      console.log("Response   ?:   1" + JSON.stringify(response))
      if (response) {
        response.forEach(e => {
          if (e.partnerSerialNo == "KPSNAM01") {
            if (e.phases) {
              setPhaseList(e.phases);
            }
            setSelectedWhiteLabel(e);
          }
        });
      }
      setWhitelabels(response);
    }));

  }, []);

  // Default Whitelabel
  const defaultWhiteLabel = "Amex Dining";

  // Fetch all brands on mount
  const handlePhaseChange = (option) => {
    setSelectedPhase(option.value); // Update selected phase
  };

  // Fetch brands when a phase is selected
  useEffect(() => {
    if (!selectedPhase) return; // Ensure selectedPhase is set before making the request

    setIsLoader(true); // Indicate loading state
    fetch("https://hba7ukrbsa.execute-api.eu-west-2.amazonaws.com/default/amex_whitelabel_fetch_brand_names", {
      method: "POST",
      headers: {
        "accept": "application/json",
        "content-type": "application/json"
      },
      body: JSON.stringify({
        phase_number: selectedPhase // Send dynamically selected phase number
      })
    })
      .then(response => response.json())
      .then(data => {
        // console.log("API Response:", data);
        setAllBrandList(data); // Store fetched brand details
      })
      .catch(error => console.error("Error fetching brand names:", error))
      .finally(() => setIsLoader(false));
  }, [selectedPhase]);
  useEffect(() => {
    const fetchCuisines = async () => {
      try {
        const response = await fetch(
          "https://8njsv0vcb8.execute-api.eu-west-2.amazonaws.com/default/amex_whitelabel_fetch_categories",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({}),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch cuisines");
        }

        const data = await response.json();

        // Directly using the API response to set the cuisines list
        const extractedCuisines = data.map((category) => ({
          label: category,
          value: category,
        }));

        setAllCuisinesList(extractedCuisines);
      } catch (error) {
        console.error("Error fetching cuisines:", error);
      }
    };

    fetchCuisines();
  }, []);

  useEffect(() => {
    if (brandData.ads && Array.isArray(brandData.ads)) {
      // Separate ads based on branch_id
      const brandAds = brandData.ads.filter(ad => !ad.branch_id || ad.branch_id === "");
      const branchAds = brandData.ads.filter(ad => ad.branch_id && ad.branch_id !== "");

      setbrandAdsDetails(brandAds);
      setbranchAdsDetails(branchAds);
    }
  }, [brandData.ads]);

  // const handleCuisineChange = (cuisine) => {
  //   setSelectedCuisines((prev) =>
  //     prev.includes(cuisine) ? prev.filter((c) => c !== cuisine) : [...prev, cuisine]
  //   );
  // };

  const pushBrandDetailsToAPI = async (brandDetails) => {
    // if (!selectedBrand) {
    //   alert("Please select a brand before updating.");
    //   return;
    // }

    // Construct the payload with full brand and branch details
    const payload = {
      offer_id: brandDetails.offer_id,
      partnerSerialNo: selectedWhiteLabel.partnerSerialNo,
      whiteLabelData: {
        brandName: brandDetails.brand_name || "na",
        brandMapLogo: brandDetails.brand_map_logo || "na",
        brandLogoUrl: brandDetails.brand_logo_url || "na",
        forMap: brandDetails.for_map || false,
        isGiftCardsAllowed: brandDetails.is_gift_cards_allowed || false,
        brandId: brandDetails.cam_brandId || "na",
        isBrandLive: brandDetails.is_live || false,
        searchName: brandDetails.search_name || "na",
        adsData: brandAdsDetails.map(ad => ({
          adName: ad.ad_name || "na",
          adType: "ads",
          adDimensions: ad.dimensions || "na",
          startDate: ad.start_date || "na",
          endDate: ad.end_date || "na",
          forMaps: ad.maps || false,
          isLive: ad.is_live || false,
          adCopy: ad.copy || "na",
          videoUrl: ad.video_url || "na",
          bookingLink: ad.booking_link || "na",
          category: ad.category || "na",
          region: ad.region || "uk",
          imageUrl: ad.image_url || "na",
          adStylingAttributes: ad.styling_attributes || {},
          special: ad.special || "default"
        }))
      }
    };

    console.log("Final Payload:", JSON.stringify(payload));

    try {
      const response = await fetch(
        // "https://olw5hkqz4c.execute-api.eu-west-1.amazonaws.com/default/amex_test_travx_offers_directory_cams_update_sqs_push",
        "https://0opaesy2d1.execute-api.eu-west-2.amazonaws.com/default/amex_travx_offers_directory_cams_update_sqs_push",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const result = await response.json();
      if (response.ok) {
        // console.log("response", response);
        alert("Brand and branch details updated successfully!");
      } else {
        console.error("Error updating details:", result);
        alert("Failed to update brand and branch details.");
      }
    } catch (error) {
      console.error("API request failed:", error);
      alert("An error occurred while updating the details.");
    }
  };
  const isValidHex = (color) => /^#[0-9A-F]{6}$/i.test(color);

  const pushBranchDetailsToAPI = async () => {
    console.log("Branch Details:", JSON.stringify(brandDetails, null, 2));

    if (!selectedBranch) {
      alert("Please select a branch before updating.");
      return;
    }

    const branch = brandDetails.branches[selectedBranch];
    if (!branch) {
      alert("Selected branch not found.");
      return;
    }

    // console.log("Selected Branch Details:", JSON.stringify(branch, null, 2));

    const payload = {
      offer_id: brandDetails.offer_id,
      partnerSerialNo: selectedWhiteLabel.partnerSerialNo,
      branches: [{
        branch_id: selectedBranch,
        kmid: branch.cam_KMID || "",
        whiteLabelData: {
          branchName: branch.name || "",
          branchCopy: branch.copy || "",
          city: branch.city || "",
          displayAddress: branch.address || "",
          gmap_address: branch.display_address || "",
          bookingLink: branch.booking_link || "",
          bookingButtonTitle: branch.booking_button_text || "",
          bookingButtonColor: branch.booking_button_color || "",
          cuisines_list: Array.isArray(branch.cuisines_list)
            ? branch.cuisines_list.map((e) => (typeof e === "object" ? e.value : e)).join(", ")
            : "", isFeatured: branch.is_featured || false,
          isBranchLive: branch.is_live || false,
          contactNumber: branch.contact_number || "",
          latitude: branch.latitude || "",
          longitude: branch.longitude || "",
          pincode: branch.pincode || "",
          numReviews: branch.num_reviews || "",
          priceRange: branch.price_range || "",
          restaurantRating: branch.restaurant_rating || "",
          adsData: branchAdsDetails.map(ad => ({
            adName: ad.ad_name || "na",
            branchId: ad.branch_id || "na",
            adType: ad.ad_type || "na",
            adDimensions: ad.dimensions || "na",
            startDate: ad.start_date || "na",
            endDate: ad.end_date || "na",
            forMaps: ad.maps || false,
            isLive: ad.is_live || false,
            adCopy: ad.copy || "na",
            videoUrl: ad.video_url || "na",
            bookingLink: ad.booking_link || "na",
            category: ad.category || "na",
            region: ad.region || "uk",
            imageUrl: ad.image_url || "na",
            adStylingAttributes: ad.styling_attributes || {},
            special: ad.special || "default"
          })),
          branchExperience: branch.branch_experience?.map(exp => ({
            title: exp?.title || "",
            name: exp?.name || "",
            copy: exp?.copy || "",
            startDate: exp?.start_date || "",
            endDate: exp?.end_date || "",
            bookingLink: exp?.booking_link || "",
            bookingText: exp?.booking_text || "",
            imageList: exp?.image_list || [],
            stylingAttributes: exp?.styling_attributes
              ? {
                button: {
                  color: isValidHex(exp?.styling_attributes?.button?.color) ? exp?.styling_attributes?.button?.color : "#000000",
                  backgroundColor: exp?.styling_attributes?.button?.backgroundColor || "#000000",
                  text: exp?.styling_attributes?.button?.text || "Test"
                }
              }
              : {}
          })) || [],
          branchAssets: [
            // Transform images
            ...(branch?.creatives
              ?.filter(creative => creative.image && /\.(png|jpg|jpeg|webp)$/i.test(creative.image))
              ?.map(creative => ({
                branchImage: creative.image,
                branchVideo: "",
                branchThumbnail: ""
              })) || []),
            // Transform videos
            ...(branch?.videos
              ?.filter(video => /\.(mp4|webm)$/i.test(video))
              ?.map(video => ({
                branchImage: "",
                branchVideo: video,
                branchThumbnail: ""
              })) || [])
          ]
        },
      }],
    };

    console.log("Final Payload:", JSON.stringify(payload));

    try {
      const response = await fetch(
        // "https://olw5hkqz4c.execute-api.eu-west-1.amazonaws.com/default/amex_test_travx_offers_directory_cams_update_sqs_push",
        "https://0opaesy2d1.execute-api.eu-west-2.amazonaws.com/default/amex_travx_offers_directory_cams_update_sqs_push",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const result = await response.json();
      console.log("API Response Status:", response.status);
      console.log("API Response Body:", JSON.stringify(result, null, 2));

      if (response.ok) {
        console.log("Response:", result);
        alert("Branch details updated successfully!");
      } else {
        console.error("Error updating details:", result);
        alert("Failed to update branch details.");
      }
    } catch (error) {
      console.error("API request failed:", error);
      alert("An error occurred while updating the branch details.");
    }
  };

  AWS.config.update({
    accessKeyId: "AKIAWX4JUDCNNNOZBOUX",
    secretAccessKey: "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",
    region: 'eu-west-2'
  });
  // console.log("AWS Config:", AWS.config.credentials); // Debug AWS credentials
  const ses = new AWS.SES({ apiVersion: '2010-12-01' });

  const sendFailureEmail = async (error) => {
    let errorMessage = `Whitelabel update failed.\n\n`;

    // Extract error details
    if (error instanceof Error) {
      errorMessage += `Message: ${error.message}\n`;
      if (error.code) errorMessage += `Code: ${error.code}\n`;
      if (error.stack) errorMessage += `Stack Trace: ${error.stack}\n`;
    } else if (typeof error === "string") {
      errorMessage += `Error Details: ${error}\n`;
    } else {
      errorMessage += `Unknown Error: ${JSON.stringify(error, null, 2)}\n`;
    }

    console.log("Sending failure email with details:", errorMessage);

    const params = {
      Source: 'dev@krowdit.com',
      Destination: {
        ToAddresses: ['dev@krowdit.com'],
        CcAddresses: ['sid@krowdit.com', 'divyansh@krowdit.com']
      },
      Message: {
        Subject: {
          Data: 'WhiteLabel Update Failed'
        },
        Body: {
          Text: {
            Data: errorMessage
          }
        }
      }
    };
    console.log("SES Email Params:", JSON.stringify(params, null, 2)); // Debug email parameters

    try {
      await ses.sendEmail(params).promise();
      // console.log("Failure email sent successfully. Response:", response);
    } catch (error) {
      console.error("Failed to send failure email:", error);
    }
  };
  // sendFailureEmail("Test error message");

  const updateWhiteLabelData = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let schemaObj = {
          brandName: yup.string().required("Brand name is required"),
        };

        const validationSchema = yup.object(schemaObj);
        let obj = {
          brandName: brandDetails.brand_name || "na",
          brandLogoUrl: brandDetails.brand_logo_url || "na",
          brandMapLogo: brandDetails.brand_map_logo || "na",
          offer_id: brandDetails.offer_id || "na",
          brandId: brandDetails.cam_brandId || "na",
          offerEndDate: brandDetails.offer_end_date,
          offerStartDate: brandDetails.offer_start_date,
          searchName: brandDetails.search_name || "na",
          isGiftCardsAllowed: brandDetails.is_gift_cards_allowed || false,
          isBrandLive: brandDetails.is_live || false,
          forMap: brandDetails.for_map || false,
          adsData: brandAdsDetails.map(ad => ({
            adName: ad.ad_name || "na",
            adType: "ads",
            adDimensions: ad.dimensions || "na",
            startDate: ad.start_date || "na",
            endDate: ad.end_date || "na",
            forMaps: ad.maps || false,
            isLive: ad.is_live || false,
            adCopy: ad.copy || "na",
            videoUrl: ad.video_url || "na",
            bookingLink: ad.booking_link || "na",
            category: ad.category || "na",
            region: ad.region || "uk",
            imageUrl: ad.image_url || "na",
            adStylingAttributes: ad.styling_attributes || {},
            special: ad.special || "default"
          }))
        };

        await validationSchema.validate(obj, { abortEarly: false });

        let reqData = {
          offer_id: brandDetails.offer_id,
          brandId: brandDetails.cam_brandId,
          partnerSerialNo: selectedWhiteLabel.partnerSerialNo || "na",
          whiteLabelData: {
            brandLogoUrl: obj.brandLogoUrl || "na",
            brandMapLogo: obj.brandMapLogo || "na",
            brandName: obj.brandName || "na",
            forMap: obj.forMap || false,
            offerId: obj.offer_id,
            isGiftCardsAllowed: obj.isGiftCardsAllowed || false,
            isBrandLive: obj.isBrandLive || false,
            offerEndDate: obj.offerEndDate,
            offerStartDate: obj.offerStartDate,
            searchName: obj.searchName || "na",
            adsData: obj.adsData,
          }
        };

        setIsLoader(true);
        dispatch(updateBrandWhiteLabelAction(reqData, async (response) => {
          setIsLoader(false);

          if (response.status === 200 && response.data.respCode === 205) {
            showMessage("Brand white-label data updated successfully", "success");
            resolve();
          } else {
            await sendFailureEmail("API Response Error: White-label update failed");
            reject("White-label update failed");
          }
        }));

      } catch (error) {
        console.error("Validation or update failed:", error);
        await sendFailureEmail(`Validation Error: ${error.message}`);
        reject(error);
      }
    });
  };
  const validateExpImageUrl = (url) => {
    const requiredPrefix = "https://travx-bkt.s3.eu-west-2.amazonaws.com/creatives/Experiences/amex/";
    return url.startsWith(requiredPrefix);
  };


  const showMessage = (message, type) => {
    let notification = { ...notificationOpts };
    notification.message = message;
    if (type == "error") {
      dispatch(errorNotificationAction(notification))
    } else {
      dispatch(successNotificationAction(notification))
    }
  }
  // sendFailureEmail("Testing failure email from a manual trigger.")
  // .then(() => console.log("Email sent successfully"))
  // .catch(error => console.error("Email sending failed:", error));


  const updateBranchWhiteLabelData = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let notFoundOfferBranchId = false;
        let count = 0;

        if (notFoundOfferBranchId) {
          showMessage(count + " Branch(s) Offer Id not found for the " + brandDetails.brandName, "error");
          reject("Missing branch offer ID");
          return;
        }
        console.log("heyyyyyyyyyyyyyyyyy", selectedBranch)
        const branch = brandDetails.branches[selectedBranch];

        let schema = yup.object({
          items: yup.array().of(
            yup.object().shape({
              branchName: yup.string().required("Branch name is required"),
              // branchCopy: yup.string().required("Branch copy is required"),
              // displayAddress: yup.string().required("Display address is required"),
              // gmap_address: yup.string().required("Google Maps address is required"),
            })
          ),
        });

        try {
          await schema.validate(branch, { abortEarly: false }).then(
            () => setBranchFieldError({}),
            (e) => {
              console.log(JSON.stringify(e));
              if (e.inner.length > 0) {
                let errorMsg = {};
                e.inner.forEach(res => {
                  let fieldName = res.path.split(".");
                  let indexValue = fieldName[0].replace("items[", "").replace("]", "");

                  if (!errorMsg[indexValue]) {
                    errorMsg[indexValue] = {};
                  }
                  errorMsg[indexValue][fieldName[1]] = res.message;
                });

                setBranchFieldError(errorMsg);
              }
            }
          );
        } catch (e) {
          console.log(e);
        }

        const isValid = await schema.isValid(branch);
        if (!isValid) {
          reject("Validation failed");
          return;
        }

        console.log("herererrererrerrere", branch.creatives)
        let reqData = {
          offer_id: brandDetails.offer_id,
          brandId: brandDetails.cam_brandId,
          partnerSerialNo: selectedWhiteLabel.partnerSerialNo,
          branches: [{
            branch_id: selectedBranch,
            kmid: branch.cam_KMID,
            whiteLabelData: {
              branchName: branch.name || "",
              branchCopy: branch.copy || "",
              displayAddress: branch.address || "",
              gmap_address: branch.display_address || "",
              bookingLink: branch.booking_link || "",
              bookingButtonTitle: branch.booking_button_text || "",
              bookingButtonColor: branch.booking_button_color || "",
              cuisines_list: Array.isArray(branch.cuisines_list)
                ? branch.cuisines_list.map((e) => (typeof e === "object" ? e.value : e)).join(", ")
                : "", contactNumber: branch.contact_number || '',
              isFeatured: branch.is_featured || false,
              isBranchLive: branch.is_live || false,

              city: branch.city || "",
              contactNumber: branch.contact_number || "",
              latitude: branch.latitude || '',
              longitude: branch.longitude || '',
              pincode: branch.pincode || '',
              numReviews: branch.num_reviews || '',
              priceRange: branch.price_range || '',
              restaurantRating: branch.restaurant_rating || '',
              adsData: branchAdsDetails.map(ad => ({
                adName: ad.ad_name || "na",
                branchId: ad.branch_id || "na",
                adType: ad.ad_type || "na",
                adDimensions: ad.dimensions || "na",
                startDate: ad.start_date || "na",
                endDate: ad.end_date || "na",
                forMaps: ad.maps || false,
                isLive: ad.is_live || false,
                adCopy: ad.copy || "na",
                videoUrl: ad.video_url || "na",
                bookingLink: ad.booking_link || "na",
                category: ad.category || "na",
                region: ad.region || "uk",
                imageUrl: ad.image_url || "na",
                adStylingAttributes: ad.styling_attributes || {},
                special: ad.special || "default"
              })),
              branchExperience: branch.branch_experience?.map(exp => ({
                title: exp?.title || "",
                name: exp?.name || "",
                copy: exp?.copy || "",
                startDate: exp?.start_date || "",
                endDate: exp?.end_date || "",
                bookingLink: exp?.booking_link || "",
                bookingText: exp?.booking_text || "",
                imageList: exp?.image_list || [],
                stylingAttributes: exp?.styling_attributes
                  ? {
                    button: {
                      color: isValidHex(exp?.styling_attributes?.button?.color) ? exp?.styling_attributes?.button?.color : "#000000",
                      backgroundColor: exp?.styling_attributes?.button?.backgroundColor || "#000000",
                      text: exp?.styling_attributes?.button?.text || "Test"
                    }
                  }
                  : {}
              })) || [],
              branchAssets: [
                // Transform images
                ...(branch?.creatives
                  ?.filter(creative => creative.image && /\.(png|jpg|jpeg|webp)$/i.test(creative.image))
                  ?.map(creative => ({
                    branchImage: creative.image,
                    branchVideo: "",
                    branchThumbnail: ""
                  })) || []),
                // Transform videos
                ...(branch?.videos
                  ?.filter(video => /\.(mp4|webm)$/i.test(video))
                  ?.map(video => ({
                    branchImage: "",
                    branchVideo: video,
                    branchThumbnail: ""
                  })) || [])
              ]
            }
          }]
        };

        console.log("Final Payloadddddddddddddddddddddddddddd:", JSON.stringify(reqData, null, 2));

        setIsLoader(true);
        dispatch(updateBranchWhiteLabelAction(reqData, async (response) => {
          setIsLoader(false);
          if (response.status === 200 && response.data.respCode === 205) {
            showMessage("Branch details updated successfully", "success");
            resolve();
          } else {
            await sendFailureEmail("Branch update failed.");
            reject("Branch update failed");
          }
        }));
      } catch (error) {
        console.error("Validation or update failed:", error);
        await sendFailureEmail(error.message);
        reject(error);
      }
    });
  };


  const handleFullUpdate = async () => {
    setIsSubmitting(true);

    try {
      await updateWhiteLabelData(); //updates mongo
      await pushBrandDetailsToAPI(brandDetails); //updates dynamo
    } catch (error) {
      console.error("Update failed:", error);
      alert("An error occurred during the update.");
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleBranchUpdate = async () => {
    setIsSubmitting(true);

    try {
      await updateBranchWhiteLabelData();
      await pushBranchDetailsToAPI(brandDetails.branches);
    } catch (error) {
      console.error("Branch update failed:", error);
      alert("An error occurred while updating branch details.");
    } finally {
      setIsSubmitting(false);
    }
  };
  const formatDateForInput = (dateString) => {
    // Convert from "DD-MM-YYYY" to "YYYY-MM-DD" for input type="date"
    if (!dateString) return "";
    const [day, month, year] = dateString.split('-');
    return `${year}-${month}-${day}`;
  };

  const formatDateForStorage = (dateString) => {
    // Convert from "YYYY-MM-DD" to "DD-MM-YYYY" for storage
    if (!dateString) return "";
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };
  // Handle brand selection
  const handleBrandChange = async (brandId) => {
    console.log(brandId)
    const selectedBrandData = allBrandList.find((b) => b.offer_id === brandId);

    setSelectedBrand(brandId);
    // setBrandDetails(selectedBrandData || {});

    // if (!selectedBrandData) {
    //     setPhases([]);
    //     setBrandDetails({});
    //     return;
    // }

    // Extract offer_id
    // const brandId = selectedBrandData.offer_id;
    // if (!brandId) {
    //   console.error("No offer_id found for this brand.");
    //   return;
    // }
    console.log("hEreeeee")
    // Fetch brand details from API
    try {
      const response = await fetch(
        "https://vwz0upsa46.execute-api.eu-west-2.amazonaws.com/default/amex_whitelabel_get_data",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "7ZQ1z7SZwX46jzqPHtPRx2QFfDl5ANrx1dwziCk7",
          },
          body: JSON.stringify({ offer_id: brandId }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch brand details");
      }

      const brandData = await response.json();
      setbrandData(brandData);
      // console.log("datatattaattatatatatattata",brandData)
      const brandDetails = brandData.offer || {};
      setBrandDetails(brandDetails); // Update state with API response
      // Extract and update phases
      setPhases(brandDetails.brandPhase || []);
    } catch (error) {
      console.error("Error fetching brand data:", error);
    }
  };

  const imageContainControls = (row, placeholder, type, index, i_index) => {
    return <>
      <FormControl className='w-100' variant="standard">
        <TextField
          autoComplete='off'
          value={row[type]}
          id={`imagebranchid${type}${index}`}
          name={`imagebranchname${type}${index}`}
          type="text" label={placeholder}
          className={`form-control mb-3`}
          onChange={e => updateImageContainRowValue(e, type, index, i_index)}
        />
      </FormControl>
    </>
  }
  const priceRanges = ['££££', '££ - £££', '££', '£'];
  // const selectedPriceRange = (brandDetails.branches[selectedBranch].price_range || []).map(range => ({ label: range, value: range })); // Correct conversion
  const handlePriceRangeChange = (selected) => {
    const selectedRanges = selected.map(item => item.value);
    branchDateUpdate(selectedRanges, selectedBranch, "price_range");
  };

  const selectedPriceRange = (() => {
    const priceRange = brandDetails?.branches?.[selectedBranch]?.price_range || ""; // Default to empty string

    if (priceRange === "") {
      return []; // Empty array if no selection
    } else {
      return [{ label: priceRange, value: priceRange }]; // Array with one object if there's a selection
    }
  })();
  const updateImageContainRowValue = (e, type, index, i_index) => {
    const { value } = e.target;
    let list = [...selectedBranch];
    let obj = { ...list[index].branchAssets[i_index], [type]: value };
    list[index].branchAssets[i_index] = obj;
    setSelectedBranch(list);
  }

  const validateBrandDetails = () => {
    // console.log("Validating Brand Details...", brandDetails);

    if (!brandDetails.brand_id) {
      console.log("Validation Failed: Missing Offer ID");
      return false;
    }

    if (!brandDetails.brand_name) {
      console.log("Validation Failed: Missing Brand Name");
      return false;
    }

    if (!brandDetails.offer_start_date) {
      console.log("Validation Failed: Missing Offer Start Date");
      return false;
    }

    if (!brandDetails.offer_end_date) {
      console.log("Validation Failed: Missing Offer End Date");
      return false;
    }

    if (!brandDetails.search_name) {
      console.log("Validation Failed: Missing Search Name");
      return false;
    }

    if (brandAdsDetails && brandAdsDetails.length > 0) {
      for (let i = 0; i < brandAdsDetails.length; i++) {
        const ad = brandAdsDetails[i];

        if (!ad.ad_name) {
          console.log(`Validation Failed: Missing Ad Name for Ad ${i + 1}`);
          return false;
        }
        if (!ad.offer_id) {
          console.log(`Validation Failed: Missing Offer ID for Ad ${i + 1}`);
          return false;
        }
        if (!ad.start_date) {
          console.log(`Validation Failed: Missing Start Date for Ad ${i + 1}`);
          return false;
        }
        if (!ad.end_date) {
          console.log(`Validation Failed: Missing End Date for Ad ${i + 1}`);
          return false;
        }
        if (!ad.dimensions) {
          console.log(`Validation Failed: Missing Dimensions for Ad ${i + 1}`);
          return false;
        }
        if (!ad.copy) {
          console.log(`Validation Failed: Missing Ad Copy for Ad ${i + 1}`);
          return false;
        }
        if (!ad.styling_attributes || !ad.styling_attributes.button || !ad.styling_attributes.button.color || !ad.styling_attributes.button.backgroundColor || !ad.styling_attributes.button.text) {
          console.log(`Validation Failed: Missing Styling Attributes for Ad ${i + 1}`);
          return false;
        }
      }
    }
    if (branchAdsDetails && branchAdsDetails.length > 0) {
      for (let i = 0; i < branchAdsDetails.length; i++) {
        const ad = branchAdsDetails[i];

        // Validate branch ad name
        if (!ad.ad_name) {
          console.log(`Validation Failed: Missing Ad Name for Branch Ad ${i + 1}`);
          return false;
        }

        // Validate offer ID
        if (!ad.offer_id) {
          console.log(`Validation Failed: Missing Offer ID for Branch Ad ${i + 1}`);
          return false;
        }

        // Validate start date
        if (!ad.start_date) {
          console.log(`Validation Failed: Missing Start Date for Branch Ad ${i + 1}`);
          return false;
        }

        // Validate end date
        if (!ad.end_date) {
          console.log(`Validation Failed: Missing End Date for Branch Ad ${i + 1}`);
          return false;
        }

        // Validate dimensions
        if (!ad.dimensions) {
          console.log(`Validation Failed: Missing Dimensions for Branch Ad ${i + 1}`);
          return false;
        }

        // Validate ad copy
        if (!ad.copy) {
          console.log(`Validation Failed: Missing Ad Copy for Branch Ad ${i + 1}`);
          return false;
        }

        // Validate styling attributes (nested validation)
        if (!ad.styling_attributes ||
          !ad.styling_attributes.button ||
          !ad.styling_attributes.button.color ||
          !ad.styling_attributes.button.backgroundColor ||
          !ad.styling_attributes.button.text) {
          console.log(`Validation Failed: Missing Styling Attributes for Branch Ad ${i + 1}`);
          return false;
        }

      }
    }
    if (brandDetails.branches && Object.keys(brandDetails.branches).length > 0) {
      for (const branchId in brandDetails.branches) {
        const branch = brandDetails.branches[branchId];
        if (!branch.name) {
          console.log(`Validation Failed: Missing Branch Name for Branch ID ${branchId}`);
          return false;
        }
        if (!branch.copy) {
          console.log(`Validation Failed: Missing Branch Copy for Branch ID ${branchId}`);
          return false;
        }
        if (!branch.city) {
          console.log(`Validation Failed: Missing City for Branch ID ${branchId}`);
          return false;
        }
        if (!branch.address) {
          console.log(`Validation Failed: Missing Display Address for Branch ID ${branchId}`);
          return false;
        }
        if (!branch.display_address) {
          console.log(`Validation Failed: Missing Google Map Address for Branch ID ${branchId}`);
          return false;
        }
        if (!branch.cuisines_list || branch.cuisines_list.length === 0) {
          console.log(`Validation Failed: Missing Cuisines List for Branch ID ${branchId}`);
          return false;
        }
        if (!branch.latitude || isNaN(parseFloat(branch.latitude)) || parseFloat(branch.latitude) < -90 || parseFloat(branch.latitude) > 90) {
          console.log(`Validation Failed: Invalid Latitude for Branch ID ${branchId}`);
          return false;
        }
        if (!branch.longitude || isNaN(parseFloat(branch.longitude)) || parseFloat(branch.longitude) < -180 || parseFloat(branch.longitude) > 180) {
          console.log(`Validation Failed: Invalid Longitude for Branch ID ${branchId}`);
          return false;
        }
        if (!branch.pincode) {
          console.log(`Validation Failed: Invalid Pincode for Branch ID ${branchId}`);
          return false;
        }
        if (!branch.num_reviews) {
          console.log(`Validation Failed: Invalid Number of Reviews for Branch ID ${branchId}`);
          return false;
        }
        if (!branch.restaurant_rating) {
          console.log(`Validation Failed: Invalid Number of Restaurant Rating for Branch ID ${branchId}`);
          return false;
        }
        if (!branch.creatives || branch.creatives.length === 0) {
          console.log(`Validation Failed: No Creative Assets for Branch ID ${branchId}`);
          return false
        }
        if (branch.branch_experience && branch.branch_experience.length > 0) {
          for (let i = 0; i < branch.branch_experience.length; i++) {
            const exp = branch.branch_experience[i];

            if (!exp.title) {
              console.log(`Validation Failed: Missing Title for Experience ${i + 1} in Branch ID ${branchId}`);
              return false;
            }
            if (!exp.name) {
              console.log(`Validation Failed: Missing Name for Experience ${i + 1} in Branch ID ${branchId}`);
              return false;
            }
            if (!exp.copy) {
              console.log(`Validation Failed: Missing Experience Copy for Experience ${i + 1} in Branch ID ${branchId}`);
              return false;
            }
            if (!exp.start_date) {
              console.log(`Validation Failed: Missing Start Date for Experience ${i + 1} in Branch ID ${branchId}`);
              return false;
            }
            if (!exp.end_date) {
              console.log(`Validation Failed: Missing End Date for Experience ${i + 1} in Branch ID ${branchId}`);
              return false;
            }
            if (!exp.booking_link) {
              console.log(`Validation Failed: Missing Booking Link for Experience ${i + 1} in Branch ID ${branchId}`);
              return false;
            }
            if (!exp.booking_text) {
              console.log(`Validation Failed: Missing Booking Text for Experience ${i + 1} in Branch ID ${branchId}`);
              return false;
            }
            if (!exp.image_list || exp.image_list.length === 0) {
              console.log(`Validation Failed: No Image List Assets for Branch ID ${branchId}`);
              return false
            }
          }
        }
      }
    }

    console.log("Validation Passed");
    return true;
  };
  const isUpdateDisabled = isSubmitting || !validateBrandDetails();

  // useEffect(() => {
  //   isUpdateDisabled(); // This will trigger validation whenever state changes
  // }, [brandDetails, brandAdsDetails, showAds]);

  // console.log("Parent Component - brandDetails:", brandDetails);
  // console.log("Parent Component - Selected Branch:", selectedBranch);
  // console.log("Parent Component - Assets in Selected Branch:", brandDetails?.branches?.[selectedBranch]?.assets);
  // console.log("Partner srial number", selectedWhiteLabel.partnerSerialNo);
  // console.log("Brand Details:", JSON.stringify(brandDetails, null, 2));
  // console.log("Brand deatislss hereerrerrere", JSON.stringify(brandAdsDetails));
  // console.log(brandDetails.branches[selectedBranch].price_range || [])
  return (
    <div className="row action-center">
      <div classname="col-md-12" style={{ width: '100%' }}>
        <div className="heading">
          <div className="row">
            <div className="col-md-8 d-flex align-items-center">
              <h2 className="m-0 ">
                <span>
                  <Icon className="heading-icon">pending_actions</Icon>
                  Whitelabel Controls
                </span>
              </h2>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="row col-12">
            <div className="row col-12">
              {/* Select Whitelabel */}
              <div className="col-3">
                <label>
                  Select WhiteLabel
                </label>
                <Select
                  className="action_Selecter"
                  value={selectedWhiteLabel}
                />
              </div>

              {/* Current Phase Dropdown */}
              {/* <div className="col-3">
                                <label>
                                    Current Phase
                                </label>
                                <Select
                                    className="action_Selecter"
                                    options={phases.map((phase) => ({ value: phase, label: phase }))}
                                    value={{ value: selectedPhase, label: selectedPhase }}
                                    onChange={(option) => setSelectedPhase(option.value)}
                                />
                            </div> */}
              <div className="col-3">
                <label>Current Phase</label>
                <Select
                  className="action_Selecter w-full"
                  options={phaseList.map((phase) => ({ value: phase.phaseNumber, label: phase.phaseName || phase.phaseNumber }))}
                  value={selectedPhase ? { value: selectedPhase, label: phaseList.find(p => p.phaseNumber === selectedPhase)?.phaseName || selectedPhase } : null}
                  onChange={(option) => setSelectedPhase(option.value)}
                />
              </div>

              {/* Select Brand Dropdown */}
              <div className="col-3">
                <label>Select Brand</label>
                <Select
                  className="action_Selecter w-full"
                  options={
                    Array.isArray(allBrandList)
                      ? allBrandList.map((brand) => ({
                        value: brand.offer_id, // Use offer_id here for proper identification
                        label: brand.brand_name,
                      }))
                      : []
                  }
                  value={
                    selectedBrand // Make sure you're checking selectedBrand, not selectedBranch
                      ? {
                        value: selectedBrand, // Correct the selected value to offer_id
                        label:
                          Array.isArray(allBrandList)
                            ? allBrandList.find((brand) => brand.offer_id === selectedBrand) // Ensure you're using offer_id here
                              ?.brand_name || "Select Brand"
                            : "Select Brand",
                      }
                      : null
                  }
                  onChange={(option) => handleBrandChange(option.value)} // Updated to use handleBrandChange
                  isDisabled={!Array.isArray(allBrandList) || allBrandList.length === 0}
                />
              </div>


              {/* Clear Button */}
              <div className="col-3 m-t-25">
                <button className="btn btn-sm btn-primary newbrandBtn" onClick={clearData}>
                  Clear
                </button>
              </div>
            </div>
          </div>
          {/* Brand Details Section */}
          {selectedBrand && (

            <div className="col-12 m-l-0">
              <div className="col-12 m-l-0 header-title">Brand Details</div>

              <div className="row col-12 b-box-white m-l-0 ">
                <div className="p-2 col-3 input-group justify-content-left">
                  <label className="block text-sm font-medium text-gray-700">CAM Brand Id :</label><span className="MuiInputLabel-asterisk">&nbsp;*</span>
                  <input type="text" className="row col-12 b-box-white m-l-0" style={{ padding: "1rem" }} value={brandDetails.cam_brandId || ""} disabled />
                </div>
                <div className="p-2 col-3 input-group justify-content-left">
                  <label className="block text-sm font-medium text-gray-700">Offer ID:</label><span className="MuiInputLabel-asterisk">&nbsp;*</span>
                  <input type="text" className="row col-12 b-box-white m-l-0" style={{ padding: "1rem" }} value={brandDetails.brand_id || ""} disabled />
                </div>
                <div className="p-2 col-3 input-group justify-content-left">
                  <label className="block text-sm font-medium text-gray-700">Brand Name :</label><span className="MuiInputLabel-asterisk">&nbsp;*</span>
                  <input type="text" className="row col-12 b-box-white m-l-0" style={{ padding: "1rem" }} value={brandDetails.brand_name || ""} onChange={(e) => {
                    setBrandDetails({ ...brandDetails, brand_name: e.target.value });
                    console.log("Brand Name Changed:", e.target.value);
                  }} />
                </div>
                <div className="p-2 col-3 input-group justify-content-left">
                  <label className="block text-sm font-medium text-gray-700">Brand Logo URL :</label>
                  <input type="text" className="row col-12 b-box-white m-l-0" style={{ padding: "1rem" }} value={brandDetails.brand_logo_url || ""} onChange={(e) => setBrandDetails({ ...brandDetails, brand_logo_url: e.target.value })} />
                </div>
                <div className="p-2 col-3 input-group justify-content-left">
                  <label className="block text-sm font-medium text-gray-700">Brand Map Logo :</label>
                  <input type="text" className="row col-12 b-box-white m-l-0" style={{ padding: "1rem" }} value={brandDetails.brand_map_logo || ""} onChange={(e) => setBrandDetails({ ...brandDetails, brand_map_logo: e.target.value })} />
                </div>
                <div className="p-2 col-3 input-group justify-content-left">
                  <label className="block text-sm font-medium text-gray-700">Offer Start Date :</label><span className="MuiInputLabel-asterisk">&nbsp;*</span>
                  <input
                    type="date"
                    className="row col-12 b-box-white m-l-0"
                    style={{ padding: "1rem" }}
                    value={brandDetails.offer_start_date ? formatDateForInput(brandDetails.offer_start_date) : ""}
                    onChange={(e) => setBrandDetails({ ...brandDetails, offer_start_date: formatDateForStorage(e.target.value) })}
                  />
                </div>
                <div className="p-2 col-3 input-group justify-content-left">
                  <label className="block text-sm font-medium text-gray-700">Offer End Date :</label><span className="MuiInputLabel-asterisk">&nbsp;*</span>
                  <input
                    type="date"
                    className="row col-12 b-box-white m-l-0"
                    style={{ padding: "1rem" }}
                    value={brandDetails.offer_end_date ? formatDateForInput(brandDetails.offer_end_date) : ""}
                    onChange={(e) => setBrandDetails({ ...brandDetails, offer_end_date: formatDateForStorage(e.target.value) })}
                  />
                </div>
                <div className="p-2 col-3 input-group justify-content-left">
                  <label className="block text-sm font-medium text-gray-700">Search Name :</label><span className="MuiInputLabel-asterisk">&nbsp;*</span>
                  <input type="text" className="row col-12 b-box-white m-l-0" style={{ padding: "1rem" }} value={brandDetails.search_name || ""} onChange={(e) => setBrandDetails({ ...brandDetails, search_name: e.target.value })} />
                </div>
                <div className="d-flex flex-row col-3 mt-2 align-items-center min-h">
                  <label className="col-6 pl-2 d-flex align-items-center">
                    <input
                      type="checkbox"
                      className="distributor-checkbox"
                      style={{ padding: "1rem" }}
                      checked={brandDetails.is_gift_cards_allowed || false}
                      onChange={(e) => setBrandDetails({ ...brandDetails, is_gift_cards_allowed: e.target.checked })}
                    />
                    <span className="ml-2">Is Gift Cards Allowed</span>
                  </label>
                </div>
                <div className="d-flex row col-3 m-t-10 b-checkbox-contain min-h">
                  <label className="row col-12 p-l-10">
                    <input
                      type="checkbox"
                      className="b-checkbox"
                      style={{ padding: "1rem" }}
                      checked={brandDetails.is_live || false}
                      onChange={(e) => setBrandDetails({ ...brandDetails, is_live: e.target.checked })}
                    />
                    Brand Live
                  </label>
                </div>
                <div className="d-flex row col-3 m-t-10 b-checkbox-contain min-h">
                  <label className="row col-12 p-l-10">
                    <input
                      type="checkbox"
                      className="b-checkbox"
                      style={{ padding: "1rem" }}
                      checked={brandDetails.for_map || false}
                      onChange={(e) => setBrandDetails({ ...brandDetails, for_map: e.target.checked })}
                    />
                    For Map
                  </label>
                </div>
                {/* Brand Ads Section with Main Dropdown */}
                <div className="col-12 m-l-4">
                  <div className="header-title d-flex justify-content-between align-items-center">
                    Brand Ads
                    <button
                      className="btn btn-secondary"
                      onClick={() => {
                        // console.log("Brand Ads:", brandAdsDetails); // Log brandAdsDetails here
                        setShowAds((prev) => !prev);
                      }}
                      style={{ marginRight: "1rem" }}
                    >
                      {showAds ? "Hide Ads" : "Show Ads"}
                    </button>
                  </div>

                  {showAds && brandAdsDetails && brandAdsDetails.length > 0 ? (
                    brandAdsDetails.map((ad, index) => (
                      <div key={index} className="row col-12 b-box-white m-l-0 p-2">
                        <div className="col-12 d-flex justify-content-between align-items-center" style={{ padding: "10px" }}>
                          <input
                            type="text"
                            value={ad.ad_name}
                            onChange={(e) => {
                              const newAds = [...brandAdsDetails];
                              newAds[index].ad_name = e.target.value;
                              setbrandAdsDetails(newAds);
                            }}
                            className="form-control"
                          />
                          <button
                            className="btn btn-primary"
                            style={{ marginLeft: "40px", width: "9%" }}
                            onClick={() =>
                              setbrandAdsDetails((prev) =>
                                prev.map((item, i) =>
                                  i === index ? { ...item, expanded: !item.expanded } : item
                                )
                              )
                            }
                          >
                            {ad.expanded ? "Hide Details" : "View Details"}
                          </button>
                        </div>
                        {/* Remove Button Here */}
                        <button
                          className="btn btn-danger"
                          style={{ marginLeft: "20px", width: "9%" }}
                          onClick={() => {
                            if (window.confirm("Are you sure you want to remove this ad?")) {
                              const newAds = brandAdsDetails.filter((_, i) => i !== index);
                              setbrandAdsDetails(newAds);
                            }
                          }}

                        >
                          Remove
                        </button>

                        {ad.expanded && (
                          <div className="col-12 mt-2">
                            <div className="row">
                              <div className="col-3" style={{ padding: "10px" }}><strong>Offer Id:</strong> <span className="MuiInputLabel-asterisk">&nbsp;*</span><input type="text" value={ad.offer_id} disabled className="form-control" /></div>
                              <div className="col-3" style={{ padding: "10px" }}>
                                <strong>Ad Name:</strong> <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                                <input
                                  type="text"
                                  value={ad.ad_name || ""}
                                  onChange={(e) => {
                                    setbrandAdsDetails(prevAds =>
                                      prevAds.map((item, i) =>
                                        i === index ? { ...item, ad_name: e.target.value } : item
                                      )
                                    );
                                  }}
                                  className="form-control"
                                />
                              </div>
                              <div className="col-3" style={{ padding: "10px" }}>
                                <strong>Ad Type:</strong>
                                <select
                                  value={"ads"}
                                  disabled
                                  className="form-control"
                                >
                                  <option value="banner">banner</option>
                                  <option value="ads">ads</option>
                                </select>
                              </div>
                              <div className="col-3" style={{ padding: "10px" }}><strong>Booking Link:</strong> <input type="text" value={ad.booking_link} onChange={(e) => { const newAds = [...brandAdsDetails]; newAds[index].booking_link = e.target.value; setbrandAdsDetails(newAds); }} className="form-control" /></div>
                              <div className="col-3" style={{ padding: "10px" }}>
                                <strong>Dimensions:</strong>
                                <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                                <select
                                  value={ad.dimensions || "horizontal"}
                                  onChange={(e) => {
                                    const newAds = [...brandAdsDetails];
                                    newAds[index].dimensions = e.target.value;
                                    setbrandAdsDetails(newAds);
                                  }}
                                  className="form-control"
                                >
                                  <option value="horizontal">horizontal</option>
                                  <option value="vertical">vertical</option>
                                  <option value="square">square</option>
                                </select>
                              </div>
                              <div className="col-3" style={{ padding: "10px" }}><strong>Image Url:</strong> <input type="text" value={ad.image_url} onChange={(e) => { const newAds = [...brandAdsDetails]; newAds[index].image_url = e.target.value; setbrandAdsDetails(newAds); }} className="form-control" /></div>
                              <div className="col-3" style={{ padding: "10px" }}><strong>Video Url:</strong> <input type="text" value={ad.video_url} onChange={(e) => { const newAds = [...brandAdsDetails]; newAds[index].video_url = e.target.value; setbrandAdsDetails(newAds); }} className="form-control" /></div>
                              <div className="col-3" style={{ padding: "10px" }}><strong>Region:</strong> <input type="text" value={ad.region} onChange={(e) => { const newAds = [...brandAdsDetails]; newAds[index].region = e.target.value; setbrandAdsDetails(newAds); }} className="form-control" /></div>
                              <div className="col-3" style={{ padding: "10px" }}><strong>Start Date:</strong> <span className="MuiInputLabel-asterisk">&nbsp;*</span><input type="date" value={ad.start_date} onChange={(e) => { const newAds = [...brandAdsDetails]; newAds[index].start_date = e.target.value; setbrandAdsDetails(newAds); }} className="form-control" /></div>
                              <div className="col-3" style={{ padding: "10px" }}><strong>End Date:</strong> <span className="MuiInputLabel-asterisk">&nbsp;*</span><input type="date" value={ad.end_date} onChange={(e) => { const newAds = [...brandAdsDetails]; newAds[index].end_date = e.target.value; setbrandAdsDetails(newAds); }} className="form-control" /></div>
                              <div className="col-3" style={{ padding: "10px" }}><strong>For Map:</strong> <span className="MuiInputLabel-asterisk">&nbsp;*</span><input type="checkbox" checked={ad.maps} onChange={(e) => { const newAds = [...brandAdsDetails]; newAds[index].maps = e.target.checked; setbrandAdsDetails(newAds); }} /></div>
                              <div className="col-3" style={{ padding: "10px" }}><strong>Is Live:</strong> <span className="MuiInputLabel-asterisk">&nbsp;*</span><input type="checkbox" checked={ad.is_live} onChange={(e) => { const newAds = [...brandAdsDetails]; newAds[index].is_live = e.target.checked; setbrandAdsDetails(newAds); }} /></div>
                              <div className="col-3" style={{ padding: "10px" }}>
                                <strong>Special:</strong>
                                <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                                <select
                                  value={ad.special || "default"}
                                  onChange={(e) => {
                                    const newAds = [...brandAdsDetails];
                                    newAds[index].special = e.target.value;
                                    setbrandAdsDetails(newAds);
                                  }}
                                  className="form-control"
                                >
                                  <option value="default" selected>default</option>
                                  <option value="gold">gold</option>
                                </select>
                              </div>
                              <div className="col-6" style={{ padding: "10px" }}>
                                <strong>Styling Attributes:</strong>
                                <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                                <div className="row">
                                  <div className="col-4">
                                    <label className="block text-sm font-medium text-gray-700">Color</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={ad?.styling_attributes?.button?.color || ""}
                                      onChange={(e) => {
                                        const newAds = [...brandAdsDetails];
                                        newAds[index].styling_attributes = {
                                          button: {
                                            ...newAds[index].styling_attributes?.button,
                                            color: e.target.value,
                                          },
                                        };
                                        setbrandAdsDetails(newAds);
                                      }}
                                    />
                                  </div>

                                  <div className="col-4">
                                    <label className="block text-sm font-medium text-gray-700">Background Color</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={ad?.styling_attributes?.button?.backgroundColor || ""}
                                      onChange={(e) => {
                                        const newAds = [...brandAdsDetails];
                                        newAds[index].styling_attributes = {
                                          button: {
                                            ...newAds[index].styling_attributes?.button,
                                            backgroundColor: e.target.value,
                                          },
                                        };
                                        setbrandAdsDetails(newAds);
                                      }}
                                    />
                                  </div>

                                  <div className="col-4">
                                    <label className="block text-sm font-medium text-gray-700">Text</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={ad?.styling_attributes?.button?.text || ""}
                                      onChange={(e) => {
                                        const newAds = [...brandAdsDetails];
                                        newAds[index].styling_attributes = {
                                          button: {
                                            ...newAds[index].styling_attributes?.button,
                                            text: e.target.value,
                                          },
                                        };
                                        setbrandAdsDetails(newAds);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>


                              <div className="col-12 mt-2" style={{ padding: "10px" }}><strong>Ad Copy:</strong> <span className="MuiInputLabel-asterisk">&nbsp;*</span><textarea value={ad.copy} onChange={(e) => { const newAds = [...brandAdsDetails]; newAds[index].copy = e.target.value; setbrandAdsDetails(newAds); }} className="form-control"></textarea></div>
                              <button
                                className="btn btn-danger"
                                style={{ marginLeft: "20px", width: "9%" }}
                                onClick={() => {
                                  if (window.confirm("Are you sure you want to remove this ad?")) {
                                    const newAds = brandAdsDetails.filter((_, i) => i !== index);
                                    setbrandAdsDetails(newAds);
                                  }
                                }}

                              >
                                Remove Brand Ad
                              </button>
                            </div>
                          </div>

                        )}

                      </div>
                    ))
                  ) : (
                    showAds && <div className="col-12" style={{ padding: "10px" }}>No ads available</div>
                  )}
                </div>
                {/* Add New Ad Button */}
                {showAds && (
                  <button
                    className="btn btn-primary mt-10" style={{ width: "max-content" }}
                    onClick={() => {
                      // Create a new empty ad object
                      const offerId = brandDetails.offer_id;
                      const newAd = {
                        ad_name: "",
                        offer_id: offerId, // This might need to be generated
                        branch_id: "", // Use currently selected branch
                        ad_type: "ads",
                        booking_link: "",
                        dimensions: "horizontal",
                        image_url: "",
                        video_url: "",
                        region: "",
                        start_date: "",
                        end_date: "",
                        maps: false,
                        is_live: false,
                        special: "default",
                        copy: "",
                        expanded: true, // Start expanded for easier editing
                        styling_attributes: {
                          button: {
                            color: "",
                            backgroundColor: "",
                            text: ""
                          }
                        }
                      };

                      // Add the new ad to the branchAdsDetails array
                      setbrandAdsDetails((prevAds) => [...(prevAds || []), newAd]);
                    }}
                  >
                    Add New Ad
                  </button>
                )}


                <div className="p-2 col-12 input-group justify-content-center m-t-5">
                  <button
                    className="btn btn-sm btn-primary newbrandBtn"
                    onClick={handleFullUpdate}
                    disabled={isUpdateDisabled}
                  >
                    {isSubmitting ? "Updating..." : "Update Brand Details and Ads"}
                  </button>
                </div>
              </div>


            </div>
          )}
          {/* Branch Selection */}
          {selectedBrand && (
            <div className="mb-4" style={{ padding: '20px' }}>
              <label className="block text-sm font-medium mb-2">Select Branch</label>
              <Select
                className="action_Selecter"
                value={selectedBranch}
                onChange={(value) => setSelectedBranch(value.value)}
                options={Object.entries(brandDetails.branches || {}).map(([kmid, branch]) => ({
                  value: kmid,
                  label: branch.name,
                }))}
                placeholder={
                  selectedBranch && brandDetails.branches?.[selectedBranch]
                    ? brandDetails.branches[selectedBranch].name
                    : "Select a branch"
                }
              />
              {/* <label className="block text-sm font-medium">Select Branch</label>
              <select
                className="border p-2 rounded w-full"
                value={selectedBranch || ""}
                onChange={(e) => setSelectedBranch(e.target.value)}
              >
                <option value="">Select Branch</option>
                {Object.entries(brandDetails.branches || {}).map(([kmid, branch]) => (
                  <option key={kmid} value={kmid}>
                    {branch.name}
                  </option>
                ))}
              </select> */}
            </div>
          )}
          {selectedBranch && (
            <>
              <div className="col-12 header-title">Branch Details</div>

              {selectedBranch && brandDetails.branches?.[selectedBranch] && (

                <div className="row col-12 b-box-white m-l-0">
                  <div className="p-2 col-3 input-group justify-content-left">
                    <label className="block text-sm font-medium text-gray-700">KMID</label>
                    <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                    <input
                      type="text"
                      className="row col-12 b-box-white m-l-0"
                      value={brandDetails.branches[selectedBranch].cam_KMID || ""}
                      style={{ padding: "1rem" }}
                      disabled
                    />
                  </div>
                  <div className="p-2 col-3 input-group justify-content-left">
                    <label className="block text-sm font-medium text-gray-700">Branch Id</label>
                    <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                    <input
                      type="text"
                      className="row col-12 b-box-white m-l-0"
                      value={selectedBranch}
                      disabled
                      style={{ padding: "1rem" }}
                    />
                  </div>

                  <div className="p-2 col-3 input-group justify-content-left">
                    <label className="block text-sm font-medium text-gray-700 ">Branch Name</label>
                    <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                    <input
                      type="text"
                      className="row col-12 b-box-white m-l-0"
                      value={brandDetails.branches[selectedBranch].name || ""}
                      onChange={(e) => branchDateUpdate(e.target.value, selectedBranch, "name")}
                      style={{ padding: "1rem" }}

                    />
                  </div>
                  <div className="p-2 col-3 input-group justify-content-left">
                    <label className="block text-sm font-medium text-gray-700">Branch Copy</label>
                    <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                    <input
                      type="text"
                      className="row col-12 b-box-white m-l-0"
                      value={brandDetails.branches[selectedBranch].copy || ""}
                      onChange={(e) => branchDateUpdate(e.target.value, selectedBranch, "copy")}
                      style={{ padding: "1rem" }}

                    />
                  </div>
                  <div className="p-2 col-3 input-group justify-content-left">
                    <label className="block text-sm font-medium text-gray-700">City</label>
                    <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                    <input
                      type="text"
                      className="row col-12 b-box-white m-l-0"
                      value={brandDetails.branches[selectedBranch].city || ""}
                      onChange={(e) => branchDateUpdate(e.target.value, selectedBranch, "city")}
                      style={{ padding: "1rem" }}

                    />
                  </div>
                  <div className="p-2 col-3 input-group justify-content-left">
                    <label className="block text-sm font-medium text-gray-700">Display Address</label>
                    <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                    <input
                      type="text"
                      className="row col-12 b-box-white m-l-0"
                      value={brandDetails.branches[selectedBranch].address || ""}
                      onChange={(e) => branchDateUpdate(e.target.value, selectedBranch, "address")}
                      style={{ padding: "1rem" }}

                    />
                  </div>

                  <div className="p-2 col-3 input-group justify-content-left">
                    <label className="block text-sm font-medium text-gray-700">Google Map Address</label>
                    <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                    <input
                      type="text"
                      className="row col-12 b-box-white m-l-0"
                      value={brandDetails.branches[selectedBranch].display_address || ""}
                      onChange={(e) => branchDateUpdate(e.target.value, selectedBranch, "display_address")}
                      style={{ padding: "1rem" }}

                    />
                  </div>
                  <div className="p-2 col-3 input-group justify-content-left">
                    <label className="block text-sm font-medium text-gray-700">Booking Link</label>
                    <input
                      type="text"
                      className={`row col-12 b-box-white m-l-0 ${brandDetails.branches[selectedBranch].booking_link &&
                        !['http://www.', 'https://www.'].some(prefix =>
                          brandDetails.branches[selectedBranch].booking_link.startsWith(prefix)
                        )
                        ? 'border-red-500'
                        : ''
                        }`}
                      style={{ padding: "1rem" }}
                      value={brandDetails.branches[selectedBranch].booking_link || ""}
                      onChange={(e) => {
                        const newLink = e.target.value;
                        branchDateUpdate(newLink, selectedBranch, "booking_link");
                      }}
                    />
                    {brandDetails.branches[selectedBranch].booking_link &&
                      !['http://www.', 'https://www.'].some(prefix =>
                        brandDetails.branches[selectedBranch].booking_link.startsWith(prefix)
                      ) && (
                        <p className="text-red-500 text-sm mt-1">
                          Booking Link must start with "http://www." or "https://www."
                        </p>
                      )}
                  </div>

                  {/* New divs for Booking Link Title and Color */}
                  <div className="p-2 col-3 input-group justify-content-left">
                    <label className="block text-sm font-medium text-gray-700">Booking Link Title</label>
                    <input
                      type="text"
                      className="row col-12 b-box-white m-l-0"
                      style={{ padding: "1rem" }}
                      value={
                        brandDetails.branches[selectedBranch].booking_button_text !== undefined
                          ? brandDetails.branches[selectedBranch].booking_button_text
                          : (brandDetails.branches[selectedBranch].bookingLinkTitle !== undefined
                            ? brandDetails.branches[selectedBranch].bookingLinkTitle
                            : "Book Now")
                      }
                      onChange={(e) => {
                        branchDateUpdate(e.target.value || "", selectedBranch, "booking_button_text");
                      }}
                    />
                  </div>

                  <div className="p-2 col-3 input-group justify-content-left">
                    <label className="block text-sm font-medium text-gray-700">Booking Link Color</label>
                    <input
                      type="text"
                      className="row col-12 b-box-white m-l-0"
                      style={{ padding: "1rem" }}
                      value={
                        brandDetails.branches[selectedBranch].booking_button_color !== undefined
                          ? brandDetails.branches[selectedBranch].booking_button_color
                          : (brandDetails.branches[selectedBranch].bookingLinkColor !== undefined
                            ? brandDetails.branches[selectedBranch].bookingLinkColor
                            : "#016FD0")
                      }
                      onChange={(e) => {
                        branchDateUpdate(e.target.value || "", selectedBranch, "booking_button_color");
                      }}
                    />
                  </div>
                  <div className="p-2 col-3 input-group justify-content-left">
                    <label className="block text-sm font-medium text-gray-700">Contact Number</label>
                    <input
                      type="text"
                      className="row col-12 b-box-white m-l-0"
                      value={brandDetails.branches[selectedBranch].contact_number || ""}
                      onChange={(e) => branchDateUpdate(e.target.value, selectedBranch, "contact_number")}
                      style={{ padding: "1rem" }}

                    />
                  </div>
                  {/* <div className="p-2 col-3 input-group justify-content-left">
                    <label className="block text-sm font-medium text-gray-700">Cuisines List</label>
                    <input
                      type="text"
                      className="row col-12 b-box-white m-l-0"
                      value={selectedCuisines.join(", ") || ""}
                      readOnly
                    />
                  </div> */}
                  <div className="p-2 col-3 input-group justify-content-center dropdown-1-1">
                    <label>
                      Select Cuisines <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                    </label>
                    <MultiSelect
                      className="multi-select"
                      options={allCuisinesList}
                      value={selectedCuisines.map((cuisine) => ({ label: cuisine, value: cuisine }))}
                      onChange={(selected) => branchDateUpdate(selected.map((c) => c.value), selectedBranch, "cuisines_list")}
                      labelledBy="Select Cuisines"
                      required={true}
                      valueRenderer={(selected) =>
                        selected.length > 0 ? "Multiple Cuisines Selected" : "Select Cuisines"
                      }
                    />
                    <FormHelperText className="text-danger">
                      {branchFieldError?.[selectedBranch]?.["cuisines_list"]}
                    </FormHelperText>
                  </div>
                  <div className="p-2 col-3 input-group justify-content-left">
                    <label className="block text-sm font-medium text-gray-700">Latitude</label>
                    <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                    <input
                      type="text"
                      className="row col-12 b-box-white m-l-0"
                      style={{ padding: "1rem" }}
                      value={brandDetails.branches[selectedBranch].latitude || ""}
                      onChange={(e) => {
                        branchDateUpdate(e.target.value, selectedBranch, "latitude");
                      }}
                    />
                  </div>
                  <div className="p-2 col-3 input-group justify-content-left">
                    <label className="block text-sm font-medium text-gray-700">Longitude</label>
                    <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                    <input
                      type="text"
                      className="row col-12 b-box-white m-l-0"
                      style={{ padding: "1rem" }}
                      value={brandDetails.branches[selectedBranch].longitude || ""}
                      onChange={(e) => {
                        branchDateUpdate(e.target.value, selectedBranch, "longitude");
                      }}
                    />
                  </div>

                  <div className="p-2 col-3 input-group justify-content-left">
                    <label className="block text-sm font-medium text-gray-700">No Of Reviews</label>
                    <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                    <input
                      type="text"
                      className="row col-12 b-box-white m-l-0"
                      style={{ padding: "1rem" }}
                      value={brandDetails.branches[selectedBranch].num_reviews || ""}
                      onChange={(e) => {
                        let value = e.target.value;

                        // 1. Allow only digits
                        value = value.replace(/[^0-9]/g, '');

                        // 2. Parse and ensure positive (or 0 if you allow it)
                        let numValue = parseInt(value, 10); // Use parseInt for integers
                        if (isNaN(numValue)) {
                          numValue = ""; // Handle empty input
                          value = "";
                        } else {
                          numValue = Math.max(0, numValue); // Ensure non-negative (0 or greater)
                          value = numValue.toString();
                        }

                        branchDateUpdate(value, selectedBranch, "num_reviews"); // Update with string value
                      }}
                    />
                  </div>
                  <div className="p-2 col-3 input-group justify-content-left">
                    <label className="block text-sm font-medium text-gray-700">Pincode</label>
                    <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                    <input
                      type="text"
                      className="row col-12 b-box-white m-l-0"
                      style={{ padding: "1rem" }}
                      value={brandDetails.branches[selectedBranch].pincode || ""}
                      onChange={(e) => {
                        let value = e.target.value.toUpperCase(); // Convert input to uppercase

                        // Allow only capital letters, numbers, and spaces
                        const validPincodeRegex = /^[A-Z0-9 ]*$/;

                        if (validPincodeRegex.test(value)) {
                          branchDateUpdate(value, selectedBranch, "pincode");
                        }
                      }}
                    />
                  </div>

                  {/* <div className="p-2 col-3 input-group justify-content-left">
                    <label className="block text-sm font-medium text-gray-700">Price Range</label>
                    <input
                      type="text"
                      className="row col-12 b-box-white m-l-0"
                      value={brandDetails.branches[selectedBranch].price_range || ""}
                      onChange={(e) => branchDateUpdate(e.target.value, selectedBranch, "price_range")}

                    />
                  </div> */}



                  <div className="p-2 col-3 input-group justify-content-left">
                    <label className="block text-sm font-medium text-gray-700 ">
                      Price Range <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                    </label>
                    <select
                      className="row col-12 b-box-white m-l-0"
                      style={{ padding: "1rem", background: "white" }}
                      value={selectedPriceRange.length > 0 ? selectedPriceRange[0].value : ""}
                      onChange={(e) => {
                        const newPriceRange = e.target.value;
                        branchDateUpdate(newPriceRange, selectedBranch, "price_range"); // Convert to array
                      }}
                    >
                      <option value="" disabled>Select Price Range</option>
                      {priceRanges.map((range) => (
                        <option key={range} value={range}>
                          {range}
                        </option>
                      ))}
                    </select>
                    <FormHelperText className="text-danger">
                      {branchFieldError?.[selectedBranch]?.["price_range"]}
                    </FormHelperText>
                  </div>

                  <div className="p-2 col-3 input-group justify-content-left">
                    <label className="block text-sm font-medium text-gray-700">Restaurant Rating</label>
                    <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                    <input
                      type="text"  // Keep as text for decimal input
                      className="row col-12 b-box-white m-l-0"
                      style={{ padding: "1rem" }}
                      value={brandDetails.branches[selectedBranch].restaurant_rating || ""}
                      onChange={(e) => {
                        let value = e.target.value.trim();

                        // 1. Allow only numbers and one decimal point
                        if (!/^\d*\.?\d*$/.test(value)) {
                          return; // Prevent invalid input
                        }

                        // 2. Limit to one decimal place
                        const parts = value.split('.');
                        if (parts.length > 2) {
                          value = parts[0] + '.' + parts[1];
                        }

                        // 3. Parse and enforce range between 1 and 5
                        let numValue = parseFloat(value);

                        if (isNaN(numValue) || value === ".") {
                          value = ""; // Allow empty input
                        } else if (numValue < 1) {
                          value = "1"; // Clamp to minimum value
                        } else if (numValue > 5) {
                          value = "5"; // Clamp to maximum value
                        }

                        // 4. Update the state with the validated string value
                        branchDateUpdate(value, selectedBranch, "restaurant_rating");
                      }}
                    />

                  </div>
                  <div className="p-2 col-3 input-group justify-content-left">
                    <label className="block text-sm font-medium text-gray-700">Branch Phases</label>
                    <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                    <input
                      type="text"
                      className="row col-12 b-box-white m-l-0"
                      value={brandDetails.branches[selectedBranch].amex_phase_level || ""}
                      disabled
                      style={{ padding: "1rem" }}

                    />
                  </div>
                  <div className="p-2 col-3 input-group justify-content-left">
                    <label className="block text-sm font-medium text-gray-700"></label>
                  </div>


                  <div className="d-flex flex-column col-3 mt-2 mb-3 b-checkbox-contain min-h ml-0" style={{ flexWrap: 'wrap', justifyContent: 'center' }}>
                    {/* Featured Branch Checkbox */}
                    <label className="d-flex align-items-center col-12 pl-5">
                      <input
                        type="checkbox"
                        className="b-checkbox me-2"
                        style={{ padding: "1rem" }}
                        checked={brandDetails.branches[selectedBranch]?.is_featured || false}
                        onChange={(e) => {
                          const newIsFeatured = e.target.checked;
                          setBrandDetails((prevDetails) => ({
                            ...prevDetails,
                            branches: {
                              ...prevDetails.branches,
                              [selectedBranch]: {
                                ...prevDetails.branches[selectedBranch],
                                is_featured: newIsFeatured,
                              },
                            },
                          }));
                        }}
                      />
                      <span>Featured Branch</span>
                    </label>

                    {/* Branch Live Checkbox */}
                    <label className="d-flex align-items-center col-12 pl-5">
                      <input
                        type="checkbox"
                        className="b-checkbox me-2"
                        style={{ padding: "1rem" }}
                        checked={brandDetails.branches[selectedBranch]?.is_live || false}
                        onChange={(e) => {
                          const newIsLive = e.target.checked;
                          setBrandDetails((prevDetails) => ({
                            ...prevDetails,
                            branches: {
                              ...prevDetails.branches,
                              [selectedBranch]: {
                                ...prevDetails.branches[selectedBranch],
                                is_live: newIsLive,
                              },
                            },
                          }));
                        }}
                      />
                      <span>Branch Live</span>
                    </label>
                  </div>


                </div>
              )}

              <BranchAssets
                handleBranchUpdate={handleBranchUpdate}
                assets={brandDetails?.branches?.[selectedBranch]?.creatives || []}
                videos={brandDetails?.branches?.[selectedBranch]?.videos || []}
                setBrandDetails={setBrandDetails}
                selectedBranch={selectedBranch}
                validateBrandDetails={validateBrandDetails}
              />

              {/* Branch Ads Section with Main Dropdown */}
              <div className="col-12" style={{ padding: "0" }}>
                <div className="header-title d-flex justify-content-between align-items-center">
                  Branch Ads
                  <button
                    className="btn btn-secondary "
                    onClick={() => {
                      console.log("Branch Ads:", branchAdsDetails); // ✅ Console log added here
                      setShowAds((prev) => !prev);
                    }}
                    style={{ marginRight: "1rem" }}
                  >
                    {showAds ? "Hide Ads" : "Show Ads"}
                  </button>

                </div>

                {showAds && branchAdsDetails && branchAdsDetails.length > 0 ? (
                  branchAdsDetails.map((ad, index) => (
                    <div key={index} className="row col-12 b-box-white m-l-0 p-2">
                      <div className="col-12 d-flex justify-content-between align-items-center" style={{ padding: "10px" }}>
                        <input
                          type="text"
                          value={ad.ad_name}
                          onChange={(e) => {
                            const newAds = [...brandAdsDetails];
                            newAds[index].ad_name = e.target.value;
                            setbranchAdsDetails(newAds);
                          }}
                          className="form-control"
                        />
                        <button
                          className="btn btn-primary"
                          style={{ marginLeft: "40px", width: "9%" }}
                          onClick={() =>
                            setbranchAdsDetails((prev) =>
                              prev.map((item, i) =>
                                i === index ? { ...item, expanded: !item.expanded } : item
                              )
                            )
                          }
                        >
                          {ad.expanded ? "Hide Details" : "View Details"}
                        </button>
                      </div>
                      {/* Remove Button Here */}
                      <button
                        className="btn btn-danger"
                        style={{ marginLeft: "20px", width: "9%" }}
                        onClick={() => {
                          if (window.confirm("Are you sure you want to remove this ad?")) {
                            const newAds = branchAdsDetails.filter((_, i) => i !== index);
                            setbranchAdsDetails(newAds);
                          }
                        }}

                      >
                        Remove
                      </button>

                      {ad.expanded && (
                        <div className="col-12 mt-2">
                          <div className="row">
                            <div className="col-3" style={{ padding: "10px" }}><strong>Offer Id:</strong> <span className="MuiInputLabel-asterisk">&nbsp;*</span><input type="text" value={ad.offer_id} disabled className="form-control" /></div>
                            <div className="col-3" style={{ padding: "10px" }}><strong>Branch Id:</strong> <span className="MuiInputLabel-asterisk">&nbsp;*</span><input type="text" value={ad.branch_id} disabled className="form-control" /></div>
                            <div className="col-3" style={{ padding: "10px" }}>
                              <strong>Ad Name:</strong> <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                              <input
                                type="text"
                                value={ad.ad_name || ""}
                                onChange={(e) => {
                                  const newBranchAds = [...branchAdsDetails];
                                  newBranchAds[index].ad_name = e.target.value;
                                  setbranchAdsDetails(newBranchAds);
                                }}
                                className="form-control"
                              />
                            </div>
                            <div className="col-3" style={{ padding: "10px" }}>
                              <strong>Ad Type:</strong>
                              <select
                                value={ad.ad_type || "ads"}
                                onChange={(e) => {
                                  const newAds = [...branchAdsDetails];
                                  newAds[index].ad_type = e.target.value; // Ensure the correct key is updated
                                  setbranchAdsDetails(newAds);
                                }}
                                className="form-control"
                              >
                                <option value="ads">ads</option>
                                <option value="banner">banner</option>
                              </select>
                            </div>
                            <div className="col-3" style={{ padding: "10px" }}><strong>Booking Link:</strong> <input type="text" value={ad.booking_link} onChange={(e) => { const newAds = [...branchAdsDetails]; newAds[index].booking_link = e.target.value; setbranchAdsDetails(newAds); }} className="form-control" /></div>
                            <div className="col-3" style={{ padding: "10px" }}>
                              <strong>Dimensions:</strong>
                              <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                              <select
                                value={ad.dimensions || "horizontal"}
                                onChange={(e) => {
                                  const newAds = [...branchAdsDetails];
                                  newAds[index].dimensions = e.target.value;
                                  setbranchAdsDetails(newAds);
                                }}
                                className="form-control"
                              >
                                <option value="horizontal">horizontal</option>
                                <option value="vertical">vertical</option>
                                <option value="square">square</option>
                              </select>
                            </div>
                            <div className="col-3" style={{ padding: "10px" }}><strong>Image Url:</strong> <input type="text" value={ad.image_url} onChange={(e) => { const newAds = [...branchAdsDetails]; newAds[index].image_url = e.target.value; setbranchAdsDetails(newAds); }} className="form-control" /></div>
                            <div className="col-3" style={{ padding: "10px" }}><strong>Video Url:</strong> <input type="text" value={ad.video_url} onChange={(e) => { const newAds = [...branchAdsDetails]; newAds[index].video_url = e.target.value; setbranchAdsDetails(newAds); }} className="form-control" /></div>
                            <div className="col-3" style={{ padding: "10px" }}><strong>Region:</strong> <input type="text" value={ad.region} onChange={(e) => { const newAds = [...branchAdsDetails]; newAds[index].region = e.target.value; setbranchAdsDetails(newAds); }} className="form-control" /></div>
                            <div className="col-3" style={{ padding: "10px" }}><strong>Start Date:</strong> <span className="MuiInputLabel-asterisk">&nbsp;*</span><input type="date" value={ad.start_date} onChange={(e) => { const newAds = [...branchAdsDetails]; newAds[index].start_date = e.target.value; setbranchAdsDetails(newAds); }} className="form-control" /></div>

                            <div className="col-3" style={{ padding: "10px" }}><strong>End Date:</strong> <span className="MuiInputLabel-asterisk">&nbsp;*</span><input type="date" value={ad.end_date} onChange={(e) => { const newAds = [...branchAdsDetails]; newAds[index].end_date = e.target.value; setbranchAdsDetails(newAds); }} className="form-control" /></div>
                            <div className="col-3" style={{ padding: "10px" }}><strong>For Map:</strong> <span className="MuiInputLabel-asterisk">&nbsp;*</span><input type="checkbox" checked={ad.maps} onChange={(e) => { const newAds = [...branchAdsDetails]; newAds[index].maps = e.target.checked; setbranchAdsDetails(newAds); }} /></div>
                            <div className="col-3" style={{ padding: "10px" }}><strong>Is Live:</strong> <span className="MuiInputLabel-asterisk">&nbsp;*</span><input type="checkbox" checked={ad.is_live} onChange={(e) => { const newAds = [...branchAdsDetails]; newAds[index].is_live = e.target.checked; setbranchAdsDetails(newAds); }} /></div>
                            <div className="col-3" style={{ padding: "10px" }}>
                              <strong>Special:</strong>
                              <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                              <select
                                value={ad.special || "default"}
                                onChange={(e) => {
                                  const newAds = [...branchAdsDetails];
                                  newAds[index].special = e.target.value;
                                  setbranchAdsDetails(newAds);
                                }}
                                className="form-control"
                              >
                                <option value="default" selected>default</option>
                                <option value="gold">gold</option>
                              </select>
                            </div>
                            <div className="col-6" style={{ padding: "10px" }}>
                              <strong>Styling Attributes:</strong>
                              <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                              <div className="row">
                                {/* Color Input */}
                                <div className="col-4">
                                  <label className="block text-sm font-medium text-gray-700">Color</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={ad?.styling_attributes?.button?.color || ""}
                                    onChange={(e) => {
                                      const newAds = [...branchAdsDetails];
                                      newAds[index].styling_attributes = {
                                        ...ad.styling_attributes,
                                        button: {
                                          ...(ad.styling_attributes?.button || {}),
                                          color: e.target.value,
                                        },
                                      };
                                      setbranchAdsDetails(newAds);
                                    }}
                                  />
                                </div>

                                {/* Background Color Input */}
                                <div className="col-4">
                                  <label className="block text-sm font-medium text-gray-700">Background Color</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={ad?.styling_attributes?.button?.backgroundColor || ""}
                                    onChange={(e) => {
                                      const newAds = [...branchAdsDetails];
                                      newAds[index].styling_attributes = {
                                        ...ad.styling_attributes,
                                        button: {
                                          ...(ad.styling_attributes?.button || {}),
                                          backgroundColor: e.target.value,
                                        },
                                      };
                                      setbranchAdsDetails(newAds);
                                    }}
                                  />
                                </div>

                                {/* Text Input */}
                                <div className="col-4">
                                  <label className="block text-sm font-medium text-gray-700">Text</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={ad?.styling_attributes?.button?.text || ""}
                                    onChange={(e) => {
                                      const newAds = [...branchAdsDetails];
                                      newAds[index].styling_attributes = {
                                        ...ad.styling_attributes,
                                        button: {
                                          ...(ad.styling_attributes?.button || {}),
                                          text: e.target.value,
                                        },
                                      };
                                      setbranchAdsDetails(newAds);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>


                            <div className="col-12 mt-2" style={{ padding: "10px" }}><strong>Ad Copy:</strong> <span className="MuiInputLabel-asterisk">&nbsp;*</span><textarea value={ad.copy} onChange={(e) => { const newAds = [...branchAdsDetails]; newAds[index].copy = e.target.value; setbranchAdsDetails(newAds); }} className="form-control"></textarea></div>
                          </div>

                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  showAds && <div className="col-12" style={{ padding: "10px" }}>No ads available</div>
                )}

              </div>
              {showAds && (
                <button
                  className="btn btn-primary " style={{ width: "max-content" }}
                  onClick={() => {
                    // Create a new empty ad object
                    const offerId = brandDetails.offer_id;
                    const newAd = {
                      ad_name: "",
                      offer_id: offerId, // This might need to be generated
                      branch_id: selectedBranch || "", // Use currently selected branch
                      ad_type: "ads",
                      booking_link: "",
                      dimensions: "horizontal",
                      image_url: "",
                      video_url: "",
                      region: "",
                      start_date: "",
                      end_date: "",
                      maps: false,
                      is_live: false,
                      special: "default",
                      copy: "",
                      expanded: true, // Start expanded for easier editing
                      styling_attributes: {
                        button: {
                          color: "",
                          backgroundColor: "",
                          text: ""
                        }
                      }
                    };

                    // Add the new ad to the branchAdsDetails array
                    setbranchAdsDetails((prevAds) => [...(prevAds || []), newAd]);
                  }}
                >
                  Add New Ad
                </button>
              )}


              <div className="col-12 mt-3" style={{ padding: "0" }}>
                <div className="header-title" >Experience Details</div>
                {brandDetails.branches[selectedBranch]?.branch_experience?.length ? (
                  brandDetails.branches[selectedBranch].branch_experience.map((exp, index) => (
                    <div key={index} className="row col-12 b-box-white m-l-0 p-2 border rounded">
                      <div className="p-2 col-3 input-group justify-content-left">
                        <label className="block text-sm font-medium text-gray-700">Title</label>
                        <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                        <input
                          type="text"
                          className="row col-12 b-box-white m-l-0"
                          value={exp?.title || ""}
                          onChange={(e) => branchDateUpdate(e.target.value, selectedBranch, `branch_experience.${index}.title`)}
                          style={{ padding: "1rem" }}
                        />
                      </div>
                      <div className="p-2 col-3 input-group justify-content-left">
                        <label className="block text-sm font-medium text-gray-700">Name</label>
                        <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                        <input
                          type="text"
                          className="row col-12 b-box-white m-l-0"
                          value={exp?.name || ""}
                          onChange={(e) => branchDateUpdate(e.target.value, selectedBranch, `branch_experience.${index}.name`)}
                          style={{ padding: "1rem" }}
                        />
                      </div>
                      <div className="p-2 col-3 input-group justify-content-left">
                        <label className="block text-sm font-medium text-gray-700">Experience Copy</label>
                        <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                        <input
                          type="text"
                          className="row col-12 b-box-white m-l-0"
                          value={exp?.copy || ""}
                          onChange={(e) => branchDateUpdate(e.target.value, selectedBranch, `branch_experience.${index}.copy`)}
                          style={{ padding: "1rem" }}
                        />
                      </div>
                      <div className="p-2 col-3 input-group justify-content-left">
                        <label className="block text-sm font-medium text-gray-700">Start Date</label>
                        <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                        <input
                          type="date"
                          className="row col-12 b-box-white m-l-0"
                          value={exp?.start_date || ""}
                          onChange={(e) => branchDateUpdate(e.target.value, selectedBranch, `branch_experience.${index}.start_date`)}
                          style={{ padding: "1rem" }}
                        />
                      </div>
                      <div className="p-2 col-3 input-group justify-content-left">
                        <label className="block text-sm font-medium text-gray-700">End Date</label>
                        <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                        <input
                          type="date"
                          className="row col-12 b-box-white m-l-0"
                          value={exp?.end_date || ""}
                          onChange={(e) => branchDateUpdate(e.target.value, selectedBranch, `branch_experience.${index}.end_date`)}
                          style={{ padding: "1rem" }}
                        />
                      </div>
                      <div className="p-2 col-3 input-group justify-content-left">
                        <label className="block text-sm font-medium text-gray-700">Booking Link</label>
                        <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                        <input
                          type="text"
                          className="row col-12 b-box-white m-l-0"
                          value={exp?.booking_link || ""}
                          onChange={(e) => branchDateUpdate(e.target.value, selectedBranch, `branch_experience.${index}.booking_link`)}
                          style={{ padding: "1rem" }}
                        />
                      </div>
                      <div className="p-2 col-3 input-group justify-content-left">
                        <label className="block text-sm font-medium text-gray-700">Booking Text</label>
                        <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                        <input
                          type="text"
                          className="row col-12 b-box-white m-l-0"
                          value={exp?.booking_text || ""}
                          onChange={(e) => branchDateUpdate(e.target.value, selectedBranch, `branch_experience.${index}.booking_text`)}
                          style={{ padding: "1rem" }}
                        />
                      </div>

                      {/* <div className="p-2 col-12 input-group justify-content-left">
                        <label className="block text-sm font-medium text-gray-700">Image List</label>
                        <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                        <textarea
                          className="row col-12 b-box-white m-l-0"
                          value={(exp?.image_list || []).join(", ")} // Convert array to a string
                          onChange={(e) => {
                            const imageList = e.target.value.split(",").map((url) => url.trim()); // Convert back to array
                            branchDateUpdate(imageList, selectedBranch, `branch_experience[${index}].image_list`);
                          }}
                          style={{ padding: "1rem" }}
                        />
                      </div> */}

                      <div className="col-6" style={{ padding: "10px" }}>
                        <strong>Styling Attributes:</strong>

                        <div className="row">
                          {/* Color Input */}
                          <div className="col-4">
                            <label className="block text-sm font-medium text-gray-700">Color</label>
                            <input
                              type="text"
                              className="form-control"
                              value={exp?.styling_attributes?.button?.color || ""}
                              onChange={(e) => {
                                const updatedAttributes = {
                                  ...exp.styling_attributes,
                                  button: {
                                    ...(exp.styling_attributes?.button || {}), // Ensure button object exists
                                    color: e.target.value,
                                  },
                                };
                                branchDateUpdate(
                                  updatedAttributes,
                                  selectedBranch,
                                  `branch_experience.${index}.styling_attributes`
                                );
                              }}
                            />
                          </div>

                          {/* Background Color Input */}
                          <div className="col-4">
                            <label className="block text-sm font-medium text-gray-700">Background Color</label>
                            <input
                              type="text"
                              className="form-control"
                              value={exp?.styling_attributes?.button?.backgroundColor || ""}
                              onChange={(e) => {
                                const updatedAttributes = {
                                  ...exp.styling_attributes,
                                  button: {
                                    ...(exp.styling_attributes?.button || {}), // Ensure button object exists
                                    backgroundColor: e.target.value,
                                  },
                                };
                                branchDateUpdate(
                                  updatedAttributes,
                                  selectedBranch,
                                  `branch_experience.${index}.styling_attributes`
                                );
                              }}
                            />
                          </div>

                          {/* Button Text Input */}
                          <div className="col-4">
                            <label className="block text-sm font-medium text-gray-700">Text</label>
                            <input
                              type="text"
                              className="form-control"
                              value={exp?.styling_attributes?.button?.text || ""}
                              onChange={(e) => {
                                const updatedAttributes = {
                                  ...exp.styling_attributes,
                                  button: {
                                    ...(exp.styling_attributes?.button || {}), // Ensure button object exists
                                    text: e.target.value,
                                  },
                                };
                                branchDateUpdate(
                                  updatedAttributes,
                                  selectedBranch,
                                  `branch_experience.${index}.styling_attributes`
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="p-2 col-3 input-group justify-content-left">
                        <label className="block text-sm font-medium text-gray-700">Experience Images</label>
                        <span className="MuiInputLabel-asterisk">&nbsp;*</span>

                        {/* Display editable image URLs */}
                        {exp?.image_list?.length > 0 ? (
                          <div className="image-urls-container">
                            {exp.image_list.map((url, imgIndex) => (
                              <div key={imgIndex} className="image-url-item mb-2">
                                <div className="input-group">
                                  <input
                                    type="text"
                                    value={url}
                                    className={`form-control ${validateExpImageUrl(url) ? '' : 'is-invalid'}`}
                                    onChange={(e) => {
                                      // Create a copy of the image list
                                      const updatedImageList = [...exp.image_list];
                                      // Update the specific image URL
                                      updatedImageList[imgIndex] = e.target.value;
                                      // Update the state with the modified list
                                      branchDateUpdate(updatedImageList, selectedBranch, `branch_experience.${index}.image_list`);
                                    }}
                                  />
                                  {!validateExpImageUrl(url) && (
                                    <div className="invalid-feedback">
                                      URL must begin with "https://travx-bkt.s3.eu-west-2.amazonaws.com/creatives/Experiences/amex"
                                    </div>
                                  )}

                                  <div className="input-group-append">
                                    <button
                                      className="btn btn-danger"
                                      onClick={() => {
                                        // Create a copy of the image list without the item to remove
                                        const updatedImageList = exp.image_list.filter((_, i) => i !== imgIndex);
                                        // Update the state with the modified list
                                        branchDateUpdate(updatedImageList, selectedBranch, `branch_experience.${index}.image_list`);
                                      }}
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                </div>


                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="text-muted small mt-2">No images added. Add an image URL using the field below.</div>
                        )}

                        {/* Input for adding new images */}
                        <div className="mb-2 w-80">
                          <div className="input-group">
                            <input
                              type="text"
                              placeholder="Enter image URL"
                              className="form-control b-box-white"
                              style={{ padding: "0.75rem" }}
                            />
                            <div className="input-group-append">
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  const inputEl = document.querySelector('input[placeholder="Enter image URL"]');
                                  if (inputEl && inputEl.value.trim()) {
                                    const newImage = inputEl.value.trim();
                                    setNewImage(newImage);

                                    if (!validateExpImageUrl(newImage)) {
                                      setErrorMessage(
                                        <>
                                          URL must begin with <br />
                                          <code>https://travx-bkt.s3.eu-west-2.amazonaws.com/creatives/Experiences/amex</code>
                                        </>
                                      );
                                      return;
                                    }

                                    // Check if exp.image_list is an array before adding
                                    const updatedImageList = Array.isArray(exp.image_list) ? [...exp.image_list, newImage] : [newImage];

                                    branchDateUpdate(updatedImageList, selectedBranch, `branch_experience.${index}.image_list`);
                                    setLastAddedImage(newImage);
                                    setErrorMessage("");
                                    inputEl.value = ''; // Clear input field
                                  }
                                }}
                              >
                                +
                              </button>

                            </div>

                          </div>

                        </div>
                        {lastAddedImage && (
                          <span className="text-success ms-2">
                            ✔ Successfully added
                          </span>
                        )}

                        {/* {newImage && !validateExpImageUrl(newImage) && (
                          <div className="invalid-feedback d-block mt-2">
                            <strong>Error:</strong> URL must begin with <br />
                            <code>https://travx-bkt.s3.eu-west-2.amazonaws.com/creatives/Experiences/amex</code>
                          </div>
                        )} */}
                        {errorMessage && (
                          <div className="invalid-feedback d-block mt-2">
                            <strong>Error:</strong> {errorMessage}
                          </div>
                        )}



                      </div>
                      <div className="p-2 col-12 text-right">
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            if (window.confirm("Are you sure you want to delete this experience?")) {
                              console.log("Deleting Experience Index:", index);

                              const updatedExperiences = brandDetails.branches[selectedBranch].branch_experience.filter((_, i) => i !== index);

                              console.log("Updated Experiences List:", updatedExperiences);

                              branchDateUpdate(updatedExperiences, selectedBranch, `branch_experience`);
                            }
                          }}
                        >
                          Delete Experience
                        </button>

                      </div>

                    </div>
                  ))
                ) : (
                  <p className="text-gray-500">No experience details available.</p>
                )}

              </div>

              <div className="p-2 col-12 input-group justify-content-center m-t-5">
                <button
                  className="btn btn-sm btn-primary newbrandBtn"
                  onClick={handleUpdateClick}
                  disabled={isUpdateDisabled}
                >
                  {isSubmitting ? "Updating..." : "Update Branch Details Ads and Experience"}
                </button>

              </div>

              {/* Add New Experience Button */}
              <div className="d-flex mb-3">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    // Create a new empty experience object
                    const newExperience = {
                      title: "",
                      name: "",
                      copy: "",
                      start_date: "",
                      end_date: "",
                      booking_link: "",
                      booking_text: "",
                      image_list: [], // Added image_list array to the new experience
                      styling_attributes: {
                        button: {
                          color: "",
                          backgroundColor: "",
                          text: ""
                        }
                      }
                    };

                    // Add the new experience to the branch_experience array
                    const updatedBranches = { ...brandDetails.branches };
                    if (!updatedBranches[selectedBranch].branch_experience) {
                      updatedBranches[selectedBranch].branch_experience = [];
                    }
                    updatedBranches[selectedBranch].branch_experience.push(newExperience);

                    // Update the state using your existing update function
                    // This assumes you have a function to update the entire brandDetails object
                    // You may need to adjust this based on your state management approach
                    setBrandDetails({
                      ...brandDetails,
                      branches: updatedBranches
                    });
                  }}
                >
                  Add New Experience
                </button>
              </div>






              {/* <div className="border p-4 rounded-lg mt-4 w-full flex flex-col">
                <div className="header-title">Branch Assets</div>
                <div className="w-full flex flex-col mt-2">
                  <h3 className="font-semibold mb-2">Images</h3>
                  <ul className="list-none p-0 m-0 w-full">
                    {assets.filter(asset => /\.(jpg|jpeg|png|gif|webp)$/i.test(asset)).length > 0 ? (
                      assets
                        .filter(asset => /\.(jpg|jpeg|png|gif|webp)$/i.test(asset))
                        .map((asset, index) => (
                          <li key={`img-${index}`} className="w-full mb-2">
                            <input
                              style={{ width: '-webkit-fill-available' }}
                              type="text"
                              value={asset}
                              onChange={(e) => handleAssetChange(index, e.target.value)}
                              className="border rounded px-2 py-1 w-full"
                            />
                          </li>
                        ))
                    ) : (
                      <li className="w-full">
                        <input
                          style={{ width: '-webkit-fill-available' }}
                          type="text"
                          placeholder="Enter image URL"
                          onChange={(e) => setAssets([e.target.value])}
                          className="border rounded px-2 py-1 w-full"
                        />
                      </li>
                    )}
                  </ul>
                </div>
                <div className="p-2 w-full flex justify-end mt-5">
                  <button
                    className="btn btn-sm btn-primary newbrandBtn w-full"
                    onClick={() => pushBrandDetailsToAPI(assets)}
                  >
                    Update
                  </button>
                </div>
              </div>  */}

            </>

          )}

        </div>


      </div>


    </div >
  );
}
