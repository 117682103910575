// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import classNames from 'classnames';
// import Icon from '@material-ui/core/Icon'; // Assuming you are using Material-UI for icons
// import { Card, CardContent, Typography } from '@material-ui/core'; // Material-UI components

// const FetchOffersPage = () => {
//     const [offersData, setOffersData] = useState(null);  // State to hold offers data
//     const [loading, setLoading] = useState(true);  // Loading state
//     const [error, setError] = useState(null);  // Error state
//     const [blurState, setBlurState] = useState(false);  // Optional state to control UI blur effect
//     const 
//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await axios.post('https://sb-api.krowdit.com/FetchAvailableOffers', {
//                     pan: pan,  // Replace with dynamic data if needed
//                 }, {
//                     headers: {
//                         'Content-Type': 'application/json',
//                         'access-token': 'ObWV3CuHZtSF3MxCJO0/9hi2FYjgONdJI0wlIXVE5lo=',  // Replace with a valid token
//                     }
//                 });
//                 setOffersData(response.data);  // Store the fetched data in offersData state
//                 setLoading(false);  // Set loading to false once data is fetched
//             } catch (err) {
//                 console.log("Handle Fetch Data Error " + JSON.stringify(err));
//                 setError('Failed to fetch offers');  // Set error message
//                 setLoading(false);  // Stop loading spinner
//             }
//         };

//         fetchData();
//     }, []);  // Empty dependency array ensures this effect runs only once

//     // Handle loading state
//     if (loading) return <p>Loading...</p>;

//     // Handle error state
//     if (error) return <p>{error}</p>;

//     return (
//         <>
//             {loading &&
//                 <div className="loading">Loading...</div>}
//             <div className={classNames('dashboard-container', { 'blur-ui': blurState })}>
//                 <div className="heading m-3">
//                     <div className="row my-3">
//                         <div className="col-md-5 d-flex align-items-center">
//                             <h2 className="m-0">
//                                 <span>
//                                     <Icon className="heading-icon">card_giftcard</Icon>
//                                 </span>
//                                 {' '}Rewards Information
//                             </h2>
//                         </div>
//                     </div>
//                 </div>

//                 {/* Display the fetched offers data */}
//                 <div className="container mt-4">
//                     {/* PAN and Points Info */}
//                     <Card className="mb-4">
//                         <CardContent>
//                             <Typography variant="h5" component="h2">
//                                 PAN: {offersData.pan}
//                             </Typography>
//                             <Typography color="textSecondary">
//                                 Total Points Earned: {offersData.points_earned}
//                             </Typography>
//                             <Typography color="textSecondary">
//                                Total Points Redeemed: {offersData.points_redeemed}
//                             </Typography>
//                             <Typography color="textSecondary">
//                                 Total Points Remaining: {offersData.total_points}
//                             </Typography>
//                         </CardContent>
//                     </Card>

//                     {/* Available Offers Table */}
//                     <div className="row">
//                         <div className="col-md-12">
//                             <h3>Available Offers</h3>
//                             {offersData.available_offers.length > 0 ? (
//                                 <table className="table table-bordered table-hover">
//                                     <thead className="thead-light">
//                                         <tr>
//                                             <th scope="col">Offer Type</th>
//                                             <th scope="col">Offer Value</th>
//                                             <th scope="col">From Date</th>
//                                             <th scope="col">To Date</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {offersData.available_offers.map((offer, index) => (
//                                             <tr key={index}>
//                                                 <td>{offer.offer_type}</td>
//                                                 <td>{offer.offer_value.toFixed(2)}</td>
//                                                 <td>{new Date(offer.from_date).toLocaleDateString()}</td>
//                                                 <td>{new Date(offer.to_date).toLocaleDateString()}</td>
//                                             </tr>
//                                         ))}
//                                     </tbody>
//                                 </table>
//                             ) : (
//                                 <Typography variant="body1" color="textSecondary">
//                                     No offers available.
//                                 </Typography>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default FetchOffersPage;

import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import axios from 'axios';
import config from '../../config/config';
import classNames from 'classnames';
import Icon from '@material-ui/core/Icon'; // Assuming you are using Material-UI for icons
import { Card, CardContent, Typography, TextField, Button, makeStyles } from '@material-ui/core'; // Material-UI components

const LoyaltyPoints = () => {
    const [offersData, setOffersData] = useState(null);  // State to hold offers data
    const [loading, setLoading] = useState(false);  // Loading state
    const [error, setError] = useState(null);  // Error state
    const [pan, setPan] = useState('');  // State to hold the entered PAN number
    const [blurState, setBlurState] = useState(false);  // Optional state to control UI blur effect

    const useStyles = makeStyles((theme) => ({
        textField: {
            textAlign: 'center',  // Aligns the input content to center
            '& input': {
                textAlign: 'center',  // Center the text inside the input field
            },
        },
        label: {
            textAlign: 'center',  // Center the label
            width: '100%',  // Make sure the label width takes the full space
        }
    }));
    // Function to handle fetching data based on PAN number
    const fetchOffersData = async () => {
        setLoading(true);
        setError(null);  // Reset error state
        setOffersData(null);  // Reset offers data

        try {
           let endpoint=`${config.dashboardurl}${"FetchAvailableOffers"}`
            const response = await axios.post(endpoint, {
                pan,  // Use the dynamically entered PAN number
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'access-token': config.dashboardKey,
                    }
            });
            setOffersData(response.data);  // Store the fetched data in offersData state
        } catch (err) {
            console.log("Handle Fetch Data Error: " + JSON.stringify(err));
            setError('Failed to fetch offers');  // Set error message
        } finally {
            setLoading(false);  // Stop loading spinner
        }
    };

    return (
        <>
            <div className={classNames('dashboard-container', { 'blur-ui': blurState })}>
                <div className="heading m-3">
                    <div className="row my-3">
                        <div className="col-md-5 d-flex align-items-center">
                            <h2 className="m-0">
                                <span>
                                    <Icon className="heading-icon">card_giftcard</Icon>
                                </span>
                                {' '}Rewards Information
                            </h2>
                        </div>
                    </div>
                </div>

                {/* Search Box for PAN */}
                <div className="search-section mt-4">
                    <Grid container spacing={2} alignItems="center">  {/* Container to wrap both TextField and Button */}
                        <Grid item xs={6}>  {/* TextField takes up 9/12 of the space */}
                            <TextField
                                label="Enter PAN"
                                variant="outlined"
                                value={pan}
                                onChange={(e) => setPan(e.target.value)}  // Update PAN state as the user types
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>  {/* Button takes up 3/12 of the space */}
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth  // Button expands to full width of its grid item
                                onClick={fetchOffersData}  // Fetch offers data when button is clicked
                                disabled={loading || !pan}  // Disable if loading or no PAN is entered
                            >
                                {loading ? 'Searching...' : 'Search'}
                            </Button>
                        </Grid>
                    </Grid>
                </div>

                {/* Display loading or error messages */}
                {loading && <p>Loading...</p>}
                {error && <p>{error}</p>}

                {/* Display the fetched offers data */}
                {offersData && (
                    <div className="container mt-4">
                        {/* PAN and Points Info */}
                        <Card className="mb-4">
                            <CardContent>
                                <Typography variant="h5" component="h2">
                                    PAN: {offersData.pan}
                                </Typography>
                                <Typography color="textSecondary">
                                    Total Points Earned: {offersData.points_earned}
                                </Typography>
                                <Typography color="textSecondary">
                                    Total Points Redeemed: {offersData.points_redeemed}
                                </Typography>
                                <Typography color="textSecondary">
                                    Total Points Remaining: {offersData.total_points}
                                </Typography>
                            </CardContent>
                        </Card>

                        {/* Available Offers Table */}
                        <div className="row">
                            <div className="col-md-12">
                                <h3>Rewards History</h3>
                                {offersData.available_offers.length > 0 ? (
                                    <div className="container mt-4">
                                        <table className="table table-bordered table-hover">
                                            <thead className="thead-light">
                                                <tr>
                                                    {/* <th>PAN</th> */}
                                                    <th>KMID</th>
                                                    <th>Points Earned</th>
                                                    <th>Points Redeemed</th>
                                                    <th>Transaction Amount</th>
                                                    <th>Discount Applied</th>
                                                    <th>Transaction Date</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {offersData.available_offers.map((row, index) => (
                                                    <tr key={index}>
                                                        {/* <td>{row.pan}</td> */}
                                                        <td>{row.kmid}</td>
                                                        <td>{row.points_earned}</td>
                                                        <td>{row.points_redeemed}</td>
                                                        <td>{row.txn_amount}</td>
                                                        <td>{row.discount_applied}</td>
                                                        <td>{new Date(row.txn_date).toLocaleDateString()}</td>
                                                        <td>{row.description}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <Typography variant="body1" color="textSecondary">
                                        No History available.
                                    </Typography>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default LoyaltyPoints;
