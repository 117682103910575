import React, { useEffect, useState } from "react";
import Select from "react-select";

export default function PopularSection() {
    const [popularItems, setPopularItems] = useState([])
    const [cuisines, setCuisines] = useState([]);
    const [offersBranches, setOffersBranches] = useState([]);
    const [brands, setBrands] = useState([]);
    const [allBrandList, setAllBrandList] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [selectedPhase, setSelectedPhase] = useState(8);
    const [brandData, setbrandData] = useState({});
    const [brandDetails, setBrandDetails] = useState({});
    const [phases, setPhases] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState("");
    const [isCuisineExpanded, setIsCuisineExpanded] = useState(false);
    const [isOffersExpanded, setIsOffersExpanded] = useState(false);
    const [isBrandsExpanded, setIsBrandsExpanded] = useState(false);

    const [offerGroup, setOfferGroup] = useState("");
    const [brandNames, setBrandNames] = useState({});
    const [branchNames, setBranchNames] = useState({});

    // const [viewType, setViewType] = useState({});
    const [groupNames, setGroupNames] = useState({});
    const [debugData, setDebugData] = useState({});
    const [viewType, setViewType] = useState({});
    const [expandedGroups, setExpandedGroups] = useState([]);
    const [expandedOffers, setExpandedOffers] = useState({});
    const [expandedBrands, setExpandedBrands] = useState({});
    const [showNewCuisineForm, setShowNewCuisineForm] = useState(false);
    const [newCuisineStartDate, setNewCuisineStartDate] = useState('');
    const [newCuisineEndDate, setNewCuisineEndDate] = useState('');
    const [newCuisines, setNewCuisines] = useState(Array(8).fill(''));
    const [showNewOffersForm, setShowNewOffersForm] = useState(false);
    const [newOfferStartDate, setNewOfferStartDate] = useState('');
    const [newOfferEndDate, setNewOfferEndDate] = useState('');
    const [newOffers, setNewOffers] = useState(Array(8).fill(''));
    const [selectedOfferBranches, setSelectedOfferBranches] = useState({}); // Store branches for each offer
    const [showNewBrandForm, setShowNewBrandForm] = useState(false);
    const [newBrandStartDate, setNewBrandStartDate] = useState('');
    const [newBrandEndDate, setNewBrandEndDate] = useState('');
    const [newBrandOffers, setNewBrandOffers] = useState(Array(8).fill(null));
    const [newBrandLogoUrls, setNewBrandLogoUrls] = useState(Array(8).fill(''));
    const [newBrandGroupNames, setNewBrandGroupNames] = useState(Array(8).fill(''));
    const [newBrandViewTypes, setNewBrandViewTypes] = useState(Array(8).fill('brands'));
    const [newBranches, setNewBranches] = useState([]);
    const [existingOffers, setExistingOffers] = useState([]);
    const [newBrands, setNewBrands] = useState([]);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);


    // Add this to your state initializations
    const [newBranchSelections, setNewBranchSelections] = useState(
        Array(newOffers.length).fill(null)
    );


    const cuisineList = [
        "Afternoon Tea", "Al Fresco", "American", "Argentine", "Asian", "Bakery", "Bar", "British", "Brunch", "Burger", "Chinese", "Coffee", "European", "Fine Dining", "French", "Gastropub", "Healthy", "Indian", "International", "Italian", "Japanese", "Mediterranean", "Mexican", "Michelin Starred", "Middle Eastern", "On The Go", "Peruvian", "Pizza", "Pre-Theatre", "Pub", "Quick Bite", "Seafood", "South American", "Spanish", "Steak", "Sushi", "Tasting Menu", "Thai", "Top Hotel Restaurant", "Vegetarian Friendly"
    ];
    // Fetch brand name for an offer_id
    // Update this function to match your API expectations
    const fetchBrandName = async (offerId) => {
        if (!offerId) return;

        try {
            console.log(`Fetching brand and branch names for Offer ID: ${offerId}`);

            const response = await fetch(
                "https://vwz0upsa46.execute-api.eu-west-2.amazonaws.com/default/amex_whitelabel_get_data",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": "7ZQ1z7SZwX46jzqPHtPRx2QFfDl5ANrx1dwziCk7",
                    },
                    body: JSON.stringify({ offer_id: offerId }),
                }
            );

            if (!response.ok) {
                throw new Error(`Failed to fetch data: ${response.status}`);
            }

            const data = await response.json();
            console.log(`Data received for Offer ID ${offerId}:`, data);

            // Extract brand name
            const brandName = data?.offer?.brand_name || "Unknown Brand";

            // Extract branches if available
            const branches = data?.offer?.branches || {};

            // Format branch names
            const branchNames = Object.entries(branches).reduce((acc, [branchId, branchData]) => {
                acc[branchId] = branchData?.name || `Branch ${branchId}`;
                return acc;
            }, {});

            // Update state with brand name
            setBrandNames(prev => ({
                ...prev,
                [offerId]: brandName
            }));

            // Update state with branch names
            setBranchNames(prev => ({
                ...prev,
                [offerId]: branchNames
            }));
            console.log("heyyyyyyy", branchNames)
        } catch (error) {
            console.error(`Error fetching data for Offer ID ${offerId}:`, error);

            // Set error placeholders
            setBrandNames(prev => ({
                ...prev,
                [offerId]: "Error"
            }));

            setBranchNames(prev => ({
                ...prev,
                [offerId]: {}
            }));
        }
    };

    const handleBranchSelection = async (index, offerId, branchId) => {
        if (!offerId || !branchId) return;

        // Ensure branches for this offer are fetched
        if (!branchNames[offerId]) {
            await fetchBrandName(offerId);
        }

        // Update selected branch for this offer
        setSelectedOfferBranches((prev) => ({
            ...prev,
            [offerId]: branchId
        }));

        console.log(`Branch Selected: ${branchId} for Offer ID: ${offerId}`);
    };


    // Add this somewhere to test a specific offer ID
    // useEffect(() => {
    //     // Test with a known offer ID
    //     fetchBrandName("1039"); // Adjust with an actual offer_id from your data
    // }, []);

    // console.log("loggsssssss", fetchBrandName("5305"))
    // 1. Add brandNames to the dependency array
    // useEffect(() => {
    //     console.log("offersBranches data:", offersBranches);

    //     if (offersBranches && offersBranches.length > 0) {
    //         offersBranches.forEach((offerGroup, groupIndex) => {
    //             // console.log(`Processing offer group ${groupIndex}`);

    //             if (offerGroup.offers_branches && offerGroup.offers_branches.length > 0) {
    //                 offerGroup.offers_branches.forEach((offer, offerIndex) => {
    //                     // console.log(`Checking offer ${offerIndex} with ID ${offer.offer_id}`);

    //                     if (offer.offer_id && !brandNames[offer.offer_id]) {
    //                         //   console.log(`Fetching brand name for offer ID: ${offer.offer_id}`);
    //                         fetchBrandName(offer.offer_id);
    //                     }
    //                 });
    //             }
    //         });
    //     }
    // }, [offersBranches, brandNames]);

    // 2. Verify the state is being set correctly by adding a useEffect
    useEffect(() => {
        console.log("brands state updated:", brands);
    }, [brandNames]);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (!selectedPhase) return; // Ensure selectedPhase is set before making the request

        setIsLoader(true); // Indicate loading state
        fetch("https://hba7ukrbsa.execute-api.eu-west-2.amazonaws.com/default/amex_whitelabel_fetch_brand_names", {
            method: "POST",
            headers: {
                "accept": "application/json",
                "content-type": "application/json"
            },
            body: JSON.stringify({
                phase_number: selectedPhase // Send dynamically selected phase number
            })
        })
            .then(response => response.json())
            .then(data => {
                // console.log("API Response:", data);
                setAllBrandList(data); // Store fetched brand details
            })
            .catch(error => console.error("Error fetching brand names:", error))
            .finally(() => setIsLoader(false));
    }, [selectedPhase]);

    // const handleBrandChange = async (brandId) => {
    //     console.log("Brand selected:", brandId);
    //     const selectedBrandData = allBrandList.find((b) => b.offer_id === brandId);
    //     setSelectedBranch(null); // Reset branch selection when brand changes
    //     setSelectedBrand(brandId);

    //     console.log("hEreeeee")
    //     // Fetch brand details from API
    //     try {
    //         const response = await fetch(
    //             "https://1ho4vj1bo2.execute-api.eu-west-1.amazonaws.com/default/amex_test_whitelabel_get_data",
    //             {
    //                 method: "POST",
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     "x-api-key": "Trdcj1tjijaBvu1BI84Yc6NyjJqyFZkmuVfGmuDb",
    //                 },
    //                 body: JSON.stringify({ offer_id: brandId }),
    //             }
    //         );

    //         if (!response.ok) {
    //             throw new Error("Failed to fetch brand details");
    //         }

    //         const brandData = await response.json();
    //         setbrandData(brandData);
    //         // console.log("datatattaattatatatatattata",brandData)
    //         const brandDetails = brandData.offer || {};
    //         setBrandDetails(brandDetails); // Update state with API response
    //         // Extract and update phases
    //         setPhases(brandDetails.brandPhase || []);
    //         console.log("Brand details received:", brandDetails);
    //         console.log("Branches available:", brandDetails.branches);

    //     } catch (error) {
    //         console.error("Error fetching brand data:", error);
    //     }
    // };
    const branchOffers = brandDetails.branch_offers || [];

    useEffect(() => {
        setViewType(prev => {
            const newViewType = { ...prev };
            brands.forEach((brandGroup, groupIndex) => {
                brandGroup.brands.forEach((brand, idx) => {
                    const key = `${groupIndex}-${idx}`;
                    // Only set if not already set from localStorage
                    if (!(key in newViewType)) {
                        // Check if this brand has multiple offers
                        newViewType[key] = brand.offers.length > 1 ? "group" : "brands";
                    }
                });
            });
            return newViewType;
        });
    }, [brands]);


    // Updated to handle view type changes per group index
    const handleViewTypeChange = (rowId, value) => {
        // Create a copy of the current viewType state
        const newViewType = { ...viewType };
        // Update just the specific row's view type
        newViewType[rowId] = value;
        setViewType(newViewType);

        // If switching to group view, reset the group name for this specific row
        if (value === "group") {
            setGroupNames(prev => ({
                ...prev,
                [rowId]: "" // Reset to empty string for this row only
            }));
        }
    };

    // useEffect(() => {
    //     const defaultExpanded = {};
    //     cuisines.forEach((_, index) => {
    //         defaultExpanded[index] = true;
    //     });
    //     setExpandedGroups(defaultExpanded);
    // }, [cuisines]);


    const toggleGroup = (index) => {
        setExpandedGroups((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    // useEffect(() => {
    //     const defaultExpanded = {};
    //     offersBranches.forEach((_, index) => {
    //         defaultExpanded[index] = true;
    //     });
    //     setExpandedOffers(defaultExpanded);
    // }, [offersBranches]);

    const toggleOfferGroup = (index) => {
        setExpandedOffers((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    // useEffect(() => {
    //     const defaultExpanded = {};
    //     brands.forEach((_, index) => {
    //         defaultExpanded[index] = true;
    //     });
    //     setExpandedBrands(defaultExpanded);
    // }, [brands]);

    const toggleBrandGroup = (index) => {
        setExpandedBrands((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    // Updated group name handler
    const handleGroupNameChange = (rowId, value) => {
        setGroupNames(prev => ({
            ...prev,
            [rowId]: value, // Track group name per row
        }));
    };

    const handleAddNewCuisineGroup = () => {
        setShowNewCuisineForm(true);
        setNewCuisineStartDate('');
        setNewCuisineEndDate('');
        setNewCuisines(Array(8).fill(''));
    };

    const processApiData = (apiData) => {
        if (!apiData || !apiData.records) return [];

        return apiData.records
            .filter(record => record.category === "cuisines")
            .map(record => ({
                start_date: record.start_date,
                end_date: record.end_date,
                // Transform the string array to array of objects with cuisine_name property
                cuisines: (record.popular_items || []).map(item => ({ cuisine_name: item }))
            }));
    };


    // Function to handle changes in the new cuisine selections
    const handleNewCuisineChange = (index, value) => {
        const updatedCuisines = [...newCuisines];
        updatedCuisines[index] = value;
        setNewCuisines(updatedCuisines);
    };

    // When updating a cuisine in the existing group
    const handleCuisineChange = (groupIndex, cuisineIndex, newValue) => {
        // Create a deep copy of the cuisines array
        const updatedCuisines = JSON.parse(JSON.stringify(cuisines));

        // Update the specific cuisine
        updatedCuisines[groupIndex].cuisines[cuisineIndex].cuisine_name = newValue;

        // Update state
        setCuisines(updatedCuisines);
    };
    // When saving to API, transform back to the API format
    const handleSaveAllCuisineGroups = async () => {
        // Prepare the API payload with all current cuisines
        const apiPayload = {
            records: cuisines.map(group => ({
                category: "cuisines",
                start_date: group.start_date,
                end_date: group.end_date,
                // Extract just the cuisine_name values and filter out empty ones
                popular_items: group.cuisines
                    .map(c => c.cuisine_name)
                    .filter(name => name && name.trim() !== ''),
                region: "uk"
            }))
        };

        console.log('Saving cuisines payload:', apiPayload); // Add this for debugging

        try {
            // Send data to API
            const response = await fetch('https://srg18jtmyk.execute-api.eu-west-2.amazonaws.com/default/amex_whitelabel_popular_items', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(apiPayload)
            });

            if (!response.ok) {
                throw new Error(`API error: ${response.status}`);
            }

            // Show success message
            alert('All cuisine groups saved successfully!');
        } catch (error) {
            console.error('Error saving cuisine groups:', error);
            alert(`Failed to save cuisine groups: ${error.message}`);
        }
    };

    // Function to save the new cuisine group
    const handleSaveNewCuisineGroup = () => {
        // Validate inputs
        if (!newCuisineStartDate || !newCuisineEndDate) {
            alert('Please enter both start and end dates');
            return;
        }

        // Create new cuisine group object for local state
        const newCuisineGroup = {
            start_date: newCuisineStartDate,
            end_date: newCuisineEndDate,
            cuisines: newCuisines.map(cuisine => ({ cuisine_name: cuisine || '' }))
        };

        // Update local state only - don't send API request yet
        setCuisines(Array.isArray(cuisines) ? [...cuisines, newCuisineGroup] : [newCuisineGroup]);

        // Update expanded groups state - with safety check
        setExpandedGroups(Array.isArray(expandedGroups) ? [...expandedGroups, false] : [false]);

        // Hide the form
        setShowNewCuisineForm(false);

        // Reset form fields
        setNewCuisineStartDate('');
        setNewCuisineEndDate('');
        setNewCuisines(Array(8).fill(''));

        // Show message
        alert('Cuisine group added to the list. Remember to click the "Save Changes" button to update the server!');
    };

    // Function to validate if all required fields in a cuisine group are filled
    const isCuisineGroupValid = (cuisineGroup, index) => {
        // Check if start and end dates exist
        if (!cuisineGroup.start_date || !cuisineGroup.end_date) {
            return false;
        }

        // Check if all cuisines are selected
        for (const cuisine of cuisineGroup.cuisines) {
            if (!cuisine.cuisine_name || cuisine.cuisine_name === "") {
                return false;
            }
        }

        // All validations passed
        return true;
    };

    // useEffect(() => {
    //     const fetchCuisines = async () => {
    //         try {
    //             const response = await fetch('your-api-endpoint');
    //             const data = await response.json();

    //             // Process the data
    //             const processedCuisines = processApiData(data);

    //             // Update cuisines state
    //             setCuisines(processedCuisines);

    //             // Initialize expandedGroups to match the number of cuisine groups
    //             // All groups collapsed by default
    //             setExpandedGroups(Array(processedCuisines.length).fill(false));
    //         } catch (error) {
    //             console.error('Error fetching cuisines:', error);
    //         }
    //     };

    //     fetchCuisines();
    // }, []);

    const handleRemoveCuisineGroup = (indexToRemove) => {
        // Create a copy of current cuisines without the one to be removed
        // Ensure cuisines is an array before filtering
        const updatedCuisines = Array.isArray(cuisines)
            ? cuisines.filter((_, index) => index !== indexToRemove)
            : [];

        // Update local state first
        setCuisines(updatedCuisines);

        // Update expanded groups state with safety check
        if (Array.isArray(expandedGroups)) {
            setExpandedGroups(expandedGroups.filter((_, index) => index !== indexToRemove));
        } else {
            // If expandedGroups isn't an array, initialize it properly
            setExpandedGroups(updatedCuisines.map(() => false));
        }

        // Show success message
        alert('Cuisine group removed from the list. Remember to click the "Save Changes" button to update the server!');
    };


    const handleAddNewOffersGroup = () => {
        console.log("Add New Offers Group button clicked!"); // Debugging log
        setShowNewOffersForm(true);
        setNewOfferStartDate('');
        setNewOfferEndDate('');
        setNewOffers(Array(8).fill(''));
    };




    const handleSaveNewOffersGroup = () => {
        // Validate inputs
        if (!newOfferStartDate || !newOfferEndDate || newOffers.length === 0) {
            alert('Please enter dates and at least one offer');
            return;
        }

        // Extract existing offer IDs from offersBranches
        const existingOffers = offersBranches.flatMap(group =>
            group.offers_branches.map(offer => offer.offer_id)
        );

        console.log(offersBranches);

        // Create new offer group object for local state
        const newOfferGroup = {
            start_date: newOfferStartDate,
            end_date: newOfferEndDate,
            offers_branches: newOffers.map(offerId => {
                const branchEntries = Object.entries(selectedOfferBranches[offerId] || {});
                const branchId = branchEntries.length > 0 ? branchEntries[0][0] : null;
                const branchName = branchId ? branchEntries[0][1]?.name || `Branch ${branchId}` : "No Branch Assigned";

                const offerName =
                    allBrandList.find(brand => brand.offer_id === offerId)?.brand_name || `Offer ${offerId}`;

                return {
                    offer_id: offerId,
                    offer_name: offerName,
                    branch_id: branchId,
                    branch_name: branchName
                };
            })
        };

        console.log("New Offer Group:", newOfferGroup);

        // Update local state only
        // Combine existing and new offers
        setExistingOffers(prevOffers => [...prevOffers, ...newOffers]);

        // Add to offersBranches array - with safety check
        setOffersBranches(Array.isArray(offersBranches) ? [...offersBranches, newOfferGroup] : [newOfferGroup]);

        // Update expanded offers state - with safety check
        setExpandedOffers(Array.isArray(expandedOffers) ? [...expandedOffers, false] : [false]);

        // Hide the form
        setShowNewOffersForm(false);

        // Clear new offers
        setNewOffers([]);

        // Set has unsaved changes flag if you're using it
        if (typeof setHasUnsavedChanges === 'function') {
            setHasUnsavedChanges(true);
        }

        // Show reminder message
        alert('Offers branches group added to your changes. Remember to click the "Save Changes" button to update the server!');
    };

    const handleAddOfferGroup = () => {
        // Validate inputs
        if (!newOfferStartDate || !newOfferEndDate || newOffers.length === 0) {
            alert('Please enter dates and at least one offer');
            return;
        }

        // Extract existing offer IDs from offersBranches
        const existingOffers = offersBranches.flatMap(group =>
            group.offers_branches.map(offer => offer.offer_id)
        );

        const allOffers = [...new Set([...newOffers, ...existingOffers])];

        // Create new offer group object for local state
        const newOfferGroup = {
            start_date: newOfferStartDate,
            end_date: newOfferEndDate,
            offers_branches: newOffers.map(offerId => {
                const branchEntries = Object.entries(selectedOfferBranches[offerId] || {});
                const branchId = branchEntries.length > 0 ? branchEntries[0][0] : null;
                const branchName = branchId ? branchEntries[0][1]?.name || `Branch ${branchId}` : "No Branch Assigned";

                const offerName =
                    allBrandList.find(brand => brand.offer_id === offerId)?.brand_name || `Offer ${offerId}`;

                return {
                    offer_id: offerId,
                    offer_name: offerName,
                    branch_id: branchId,
                    branch_name: branchName
                };
            })
        };

        console.log("New Offer Group (State Only):", newOfferGroup);

        // Add to offersBranches array - with safety check
        setOffersBranches(Array.isArray(offersBranches) ? [...offersBranches, newOfferGroup] : [newOfferGroup]);

        // Update expanded offers state - with safety check
        setExpandedOffers(Array.isArray(expandedOffers) ? [...expandedOffers, false] : [false]);

        // Hide the form
        setShowNewOffersForm(false);

        // Clear new offers
        setNewOffers([]);

        // Set a flag to indicate unsaved changes
        setHasUnsavedChanges(true);
    };

    // Remove an offer group (state only)
    const handleRemoveOfferGroupStateOnly = (groupIndex) => {
        // Confirm removal
        const confirmRemove = window.confirm('Are you sure you want to remove this offers group?');
        if (!confirmRemove) return;

        // Remove the offer group from local state
        setOffersBranches(prev => {
            const updatedOffersBranches = [...prev];
            updatedOffersBranches.splice(groupIndex, 1);
            return updatedOffersBranches;
        });

        // Update expanded offers state
        setExpandedOffers(prev => {
            // Ensure prev is an array, if not, use an empty array
            const prevArray = Array.isArray(prev) ? prev : [];
            const updatedExpandedOffers = [...prevArray];
            updatedExpandedOffers.splice(groupIndex, 1);
            return updatedExpandedOffers;
        });

        // Set a flag to indicate unsaved changes
        setHasUnsavedChanges(true);
        alert('Offers branches group removed from the list. Remember to click the "Save Changes" button to update the server!');

    };

    // 2. Add a "Save All Changes" function that sends everything to the API
    const handleSaveAllChanges = async () => {
        // Prepare API payload with ALL current offer groups
        const apiPayload = {
            records: offersBranches.flatMap(offerGroup => [
                {
                    category: "offers",
                    start_date: offerGroup.start_date,
                    end_date: offerGroup.end_date,
                    popular_items: offerGroup.offers_branches.map(item => item.offer_id),
                    region: "uk"
                },
                {
                    category: "branches",
                    start_date: offerGroup.start_date,
                    end_date: offerGroup.end_date,
                    popular_items: offerGroup.offers_branches.map(item => item.branch_id).filter(Boolean), // Filter out null branch IDs
                    region: "uk"
                }
            ])
        };

        console.log("Save All Changes Payload:", JSON.stringify(apiPayload, null, 2));

        try {
            // Send data to API
            const response = await fetch('https://srg18jtmyk.execute-api.eu-west-2.amazonaws.com/default/amex_whitelabel_popular_items', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(apiPayload)
            });

            if (!response.ok) {
                throw new Error(`API error: ${response.status}`);
            }

            // Reset the unsaved changes flag
            setHasUnsavedChanges(false);

            // Show success message
            alert('All changes saved successfully!');
        } catch (error) {
            console.error('Error saving changes:', error);
            alert(`Failed to save changes: ${error.message}`);
        }
    };


    // Function to validate if all required fields in an offer group are filled
    const isOfferGroupValid = (offerGroup, groupIndex) => {
        // Check if start and end dates exist
        if (!offerGroup.start_date || !offerGroup.end_date) {
            return false;
        }

        // Check each offer in the group
        for (const offer of offerGroup.offers_branches) {
            // Check if brand/offer is selected
            if (!offer.offer_id) {
                return false;
            }

            // Check if branch is selected
            if (!offer.branch_id) {
                return false;
            }
        }

        // All validations passed
        return true;
    };

    const handleRemoveOfferGroup = async (groupIndex) => {
        // Confirm removal
        const confirmRemove = window.confirm('Are you sure you want to remove this offers group?');
        if (!confirmRemove) return;

        // Prepare API payload with ALL OTHER offer groups EXCEPT the one to be removed
        const apiPayload = {
            records: offersBranches
                .filter((_, index) => index !== groupIndex)
                .flatMap(offerGroup => [
                    {
                        category: "offers",
                        start_date: offerGroup.start_date,
                        end_date: offerGroup.end_date,
                        popular_items: offerGroup.offers_branches.map(item => item.offer_id),
                        region: "uk"
                    },
                    {
                        category: "branches",
                        start_date: offerGroup.start_date,
                        end_date: offerGroup.end_date,
                        popular_items: offerGroup.offers_branches.map(item => item.branch_id),
                        region: "uk"
                    }
                ])
        };


        console.log("Detailed Payload for removal:", JSON.stringify(apiPayload, null, 2));

        try {
            // Send data to API
            const response = await fetch('https://srg18jtmyk.execute-api.eu-west-2.amazonaws.com/default/amex_whitelabel_popular_items', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(apiPayload)
            });

            if (!response.ok) {
                throw new Error(`API error: ${response.status}`);
            }

            // Remove the offer group from local state
            setOffersBranches(prev => {
                const updatedOffersBranches = [...prev];
                updatedOffersBranches.splice(groupIndex, 1);
                return updatedOffersBranches;
            });

            // Update expanded offers state
            setExpandedOffers(prev => {
                // Ensure prev is an array, if not, use an empty array
                const prevArray = Array.isArray(prev) ? prev : [];
                const updatedExpandedOffers = [...prevArray];
                updatedExpandedOffers.splice(groupIndex, 1);
                return updatedExpandedOffers;
            });

            // Show success message
            alert('Offers group removed successfully!');
        } catch (error) {
            console.error('Error removing offers group:', error);
            alert(`Failed to remove offers group: ${error.message}`);
        }
    };

    const handleNewOfferChange = async (index, offerId) => {
        if (!offerId) return;

        // Update the selected offer first
        setNewOffers((prev) => {
            const updatedOffers = [...prev];
            updatedOffers[index] = offerId;
            console.log("Updated newOffers:", updatedOffers);
            return updatedOffers;
        });



        // Fetch and store branches for the selected offer
        if (!selectedOfferBranches[offerId]) {
            try {
                console.log(`Fetching branches for Offer ID: ${offerId}`);

                const response = await fetch(
                    "https://vwz0upsa46.execute-api.eu-west-2.amazonaws.com/default/amex_whitelabel_get_data",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "x-api-key": "7ZQ1z7SZwX46jzqPHtPRx2QFfDl5ANrx1dwziCk7",
                        },
                        body: JSON.stringify({ offer_id: offerId }),
                    }
                );

                if (!response.ok) {
                    throw new Error(`Failed to fetch branches: ${response.status}`);
                }

                const data = await response.json();
                const branches = data?.offer?.branches || {};

                console.log(`Setting branches for offer ${offerId}:`, branches); // Debug fetched branches

                setSelectedOfferBranches((prev) => ({
                    ...prev,
                    [offerId]: branches
                }));
            } catch (error) {
                console.error(`Error fetching branches for Offer ID ${offerId}:`, error);
            }
        }
    };

    useEffect(() => {
        console.log("offersBranches state updated:", offersBranches); // Debug state updates
    }, [offersBranches]);




    // Update brand for an offer and fetch its branches
    const handleBrandChange = async (groupIndex, index, offerId) => {
        try {
            // Create a copy of your offers
            const currentOfferGroup = offersBranches[groupIndex];
            const updatedOffers = [...currentOfferGroup.offers_branches];
            // Find the selected brand data
            const selectedBrand = allBrandList.find(brand => brand.offer_id === offerId);
            console.log("Selected brand data:", selectedBrand);

            // Reset offer details for this brand change
            updatedOffers[index] = {
                ...updatedOffers[index],
                offer_id: offerId,
                offer_name: selectedBrand?.brand_name || "Unknown Brand",
                branch_id: null,  // Reset branch when brand changes
                branch_name: null,
                branches: {}, // Reset branches
                branch_offers: [], // Reset offers
            };

            const updatedOffersBranches = [...offersBranches];
            updatedOffersBranches[groupIndex] = {
                ...currentOfferGroup,
                offers_branches: updatedOffers
            };


            setOffersBranches(updatedOffersBranches);

            // Fetch offers for the selected brand
            const offerResponse = await fetch(
                "https://vwz0upsa46.execute-api.eu-west-2.amazonaws.com/default/amex_whitelabel_get_data",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": "7ZQ1z7SZwX46jzqPHtPRx2QFfDl5ANrx1dwziCk7",
                    },
                    body: JSON.stringify({ offer_id: offerId }), // Fetch offers using brand_id
                }
            );

            if (!offerResponse.ok) {
                throw new Error("Failed to fetch offer details");
            }

            const offerData = await offerResponse.json();
            const offersForBrand = offerData.offers || []; // Assuming `offers` array is in response

            // Fetch branches for the selected brand
            console.log("Fetching branches for brand ID:", offerId);
            const branchResponse = await fetch(
                "https://vwz0upsa46.execute-api.eu-west-2.amazonaws.com/default/amex_whitelabel_get_data",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": "7ZQ1z7SZwX46jzqPHtPRx2QFfDl5ANrx1dwziCk7",
                    },
                    body: JSON.stringify({ offer_id: offerId }), // Fetch branches using offer_id
                }
            );

            if (!branchResponse.ok) {
                throw new Error("Failed to fetch branch details");
            }

            const branchData = await branchResponse.json();
            console.log("Branch API response:", branchData);
            const brandDetails = branchData.offer || {};

            console.log("Branch details extracted:", brandDetails);
            console.log("Branches object:", brandDetails.branches);
            // Update state with offers and branches


            const finalUpdatedOffers = [...updatedOffersBranches[groupIndex].offers_branches];
            finalUpdatedOffers[index] = {
                ...finalUpdatedOffers[index],
                branches: brandDetails.branches || {},
            };

            const finalUpdatedOffersBranches = [...updatedOffersBranches];
            finalUpdatedOffersBranches[groupIndex] = {
                ...updatedOffersBranches[groupIndex],
                offers_branches: finalUpdatedOffers
            };

            console.log("Final updated state with branches:", finalUpdatedOffersBranches[groupIndex].offers_branches[index].branches);
            setOffersBranches(finalUpdatedOffersBranches);

            // Clear and update brandNames for this specific offer_id
            setBrandNames(prev => ({
                ...prev,
                [offerId]: selectedBrand?.brand_name || "Unknown Brand"
            }));


        } catch (error) {
            console.error("Error updating brand for offer:", error);
        }
    };


    // Update branch for an offer
    const handleBranchChange = (groupIndex, offerIndex, branchId) => {
        // Create a copy of the offers branches
        const updatedOffersBranches = [...offersBranches];
        const offer = updatedOffersBranches[groupIndex].offers_branches[offerIndex];

        // Get branch name from the branches object
        const branchName = offer.branches[branchId]?.name || "Unknown Branch";

        // Update the specific offer with branch information
        updatedOffersBranches[groupIndex].offers_branches[offerIndex] = {
            ...offer,
            branch_id: branchId,
            branch_name: branchName
        };

        // Update the state
        setOffersBranches(updatedOffersBranches);
    };

    // Function to handle logo URL changes
    const handleLogoUrlChange = (groupIndex, brandIndex, newUrl) => {
        // Create a deep copy of the brands state to avoid direct mutation
        const updatedBrands = [...brands];

        // Update the logo URL for the specific brand in the specific group
        updatedBrands[groupIndex].brands[brandIndex].brand_logo_url = newUrl;

        // Update the state with the new brands array
        setBrands(updatedBrands);

        // Optional: You might want to save changes to backend or localStorage
        // saveChangesToBackend(updatedBrands);
    };

    // Remove an offer from the list
    const removeOffer = (index) => {
        // Create a copy of your offers without the removed offer
        const updatedOffers = offerGroup.offers_branches.filter((_, idx) => idx !== index);

        // Update state with the new offers array
        setOfferGroup(prevState => ({
            ...prevState,
            offers_branches: updatedOffers
        }));
    };

    // 1. Make sure viewType is properly initialized and persisted
    // const [viewType, setViewType] = useState(() => {
    //     // Initialize with default values or from localStorage if you want persistence
    //     const initialViewTypes = {};

    //     // Optionally pre-set some to "group" if needed
    //     brands.forEach((brandGroup, groupIndex) => {
    //         brandGroup.brands.forEach((_, brandIndex) => {
    //             initialViewTypes[`${groupIndex}-${brandIndex}`] = "brand";
    //         });
    //     });

    //     return initialViewTypes;
    // });



    const toggleViewType = (groupIndex, idx) => {
        setViewType(prev => {
            const key = `${groupIndex}-${idx}`;
            const newValue = prev[key] === "group" ? "brands" : "group";

            console.log(`Toggle: ${key} -> ${newValue}`);

            return { ...prev, [key]: newValue };
        });

        // Force UI to persist (without unnecessary re-renders)
        setTimeout(() => {
            setViewType(prev => {
                const key = `${groupIndex}-${idx}`;
                return { ...prev, [key]: "group" }; // Ensure it remains group
            });
        }, 0);
    };


    useEffect(() => {
        const savedViewTypes = {};
        Object.keys(viewType).forEach(key => {
            const savedValue = localStorage.getItem(`viewType-${key}`);
            if (savedValue) savedViewTypes[key] = savedValue;
        });
        setViewType(prev => ({ ...prev, ...savedViewTypes }));
    }, []);


    // Enhanced selection handler with debug
    const handleSelectChange = (groupIndex, idx, option) => {
        console.log("Selection changed:", option);

        // Get the selected brand name
        let selectedBrandName = "";
        const viewTypeKey = `${groupIndex}-${idx}`;
        const isGroupView = viewType[viewTypeKey] === "group";

        if (!isGroupView && option) {
            // For single selection, get the brand name from the selected option
            selectedBrandName = option.label;
        }

        setBrands(prevBrands => {
            const updatedBrands = JSON.parse(JSON.stringify(prevBrands));

            if (isGroupView) {
                const selectedOffers = Array.isArray(option) ? option.map(opt => opt.value) : [];
                updatedBrands[groupIndex].brands[idx] = {
                    ...updatedBrands[groupIndex].brands[idx],
                    offers: selectedOffers,
                };
            } else {
                updatedBrands[groupIndex].brands[idx] = {
                    ...updatedBrands[groupIndex].brands[idx],
                    offers: option ? [option.value] : [],
                    brand_name: selectedBrandName // Update the brand_name property
                };
            }

            console.log("Will update to:", updatedBrands);
            return updatedBrands;
        });

        // Update the group name for non-group view
        if (!isGroupView && option) {
            setGroupNames(prev => ({
                ...prev,
                [`${groupIndex}-${idx}`]: option.label
            }));
        }
    };

    // Handler functions for new brand form
    // Handler for view type change
    // Updated handler for brand/offer selection
    const handleNewBrandOfferChange = (index, option) => {
        const updatedOffers = [...newBrandOffers];
        const isGroupView = newBrandViewTypes[index] === "group";

        if (isGroupView) {
            // For group view, handle multi-select options
            updatedOffers[index] = Array.isArray(option) ? option.map(opt => opt.value) : [];
        } else {
            // For brands view, handle single select
            updatedOffers[index] = option ? option.value : null;
        }

        setNewBrandOffers(updatedOffers);

        // If view type is "brands" and we have a selection, set group name to match brand name
        if (!isGroupView && option) {
            const brandName = allBrandList.find(b => b.offer_id === option.value)?.brand_name || "";
            const updatedGroupNames = [...newBrandGroupNames];
            updatedGroupNames[index] = brandName;
            setNewBrandGroupNames(updatedGroupNames);
        }
    };

    // Updated handler for view type changes
    const handleNewBrandViewTypeChange = (index, value) => {
        const updatedViewTypes = [...newBrandViewTypes];
        const previousViewType = updatedViewTypes[index];
        updatedViewTypes[index] = value;
        setNewBrandViewTypes(updatedViewTypes);

        // Handle conversion between single value and array when switching view types
        const updatedOffers = [...newBrandOffers];
        const currentOffer = updatedOffers[index];

        if (previousViewType === "brands" && value === "group") {
            // Convert single value to array
            updatedOffers[index] = currentOffer ? [currentOffer] : [];
        } else if (previousViewType === "group" && value === "brands") {
            // Convert array to single value (take first item if available)
            updatedOffers[index] = Array.isArray(currentOffer) && currentOffer.length > 0
                ? currentOffer[0]
                : null;

            // Update group name to match the selected brand
            if (updatedOffers[index]) {
                const brandName = allBrandList.find(b => b.offer_id === updatedOffers[index])?.brand_name || "";
                const updatedGroupNames = [...newBrandGroupNames];
                updatedGroupNames[index] = brandName;
                setNewBrandGroupNames(updatedGroupNames);
            }
        }

        setNewBrandOffers(updatedOffers);
    };

    const handleNewBrandLogoUrlChange = (index, value) => {
        const updatedLogoUrls = [...newBrandLogoUrls];
        updatedLogoUrls[index] = value;
        setNewBrandLogoUrls(updatedLogoUrls);
    };

    const handleNewBrandGroupNameChange = (index, value) => {
        const updatedGroupNames = [...newBrandGroupNames];
        updatedGroupNames[index] = value;
        setNewBrandGroupNames(updatedGroupNames);
    };

    // const handleNewBrandViewTypeChange = (index, value) => {
    //     const updatedViewTypes = [...newBrandViewTypes];
    //     updatedViewTypes[index] = value;
    //     setNewBrandViewTypes(updatedViewTypes);
    // };

    // Function to save new brand group
    // Function to save new brand group
    const handleSaveNewBrandGroup = () => {
        // Validate form data
        if (!newBrandStartDate || !newBrandEndDate) {
            console.error("Please select both start and end dates");
            return;
        }

        // Check if at least one brand is selected
        const hasAtLeastOneBrand = newBrandOffers.some(offer => {
            if (Array.isArray(offer)) return offer.length > 0;
            return offer !== null;
        });

        if (!hasAtLeastOneBrand) {
            console.error("Please select at least one brand");
            return;
        }

        // Format dates
        const formattedStartDate = formatDate(newBrandStartDate);
        const formattedEndDate = formatDate(newBrandEndDate);

        // Check if dates are valid
        if (new Date(formattedEndDate) < new Date(formattedStartDate)) {
            console.error("End date cannot be before start date");
            return;
        }

        // Create brand objects for each filled row
        const brandsList = newBrandOffers.map((offer, idx) => {
            if (offer === null || (Array.isArray(offer) && offer.length === 0)) return null;

            const isGroupView = newBrandViewTypes[idx] === "group";
            const offers = isGroupView ? offer : [offer];

            return {
                offers: offers,
                brand_logo_url: newBrandLogoUrls[idx] || "",
                brand_name: isGroupView
                    ? newBrandGroupNames[idx]
                    : allBrandList.find(b => b.offer_id === offer)?.brand_name || ""
            };
        }).filter(brand => brand !== null); // Remove null entries

        // Create the new brand group object
        const newBrandGroup = {
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            brands: brandsList
        };

        // Add the new brand group to the brands array
        setBrands(prevBrands => [...prevBrands, newBrandGroup]);

        // Update the expandedBrands state
        setExpandedBrands(prevState => [...prevState, false]);

        // Reset form fields
        setNewBrandStartDate('');
        setNewBrandEndDate('');
        setNewBrandOffers(Array(8).fill(null));
        setNewBrandLogoUrls(Array(8).fill(''));
        setNewBrandGroupNames(Array(8).fill(''));
        setNewBrandViewTypes(Array(8).fill('brands'));

        // Hide the form
        setShowNewBrandForm(false);

        // Show success message
        console.log("New brand group added successfully");
    };

    const handleBrandsUpdate = async () => {
        try {
            // Prepare the API payload based on the existing brands data
            const apiPayload = {
                records: [
                    // Existing brand groups
                    ...brands.map(brandGroup => ({
                        category: "brands",
                        start_date: brandGroup.start_date,
                        end_date: brandGroup.end_date,
                        region: "uk",
                        popular_items: brandGroup.brands.map(brand => {
                            // Determine offers based on view type
                            const groupIndex = brands.indexOf(brandGroup);
                            const brandIndex = brandGroup.brands.indexOf(brand);
                            const viewTypeKey = `${groupIndex}-${brandIndex}`;

                            const offers = viewType[viewTypeKey] === "group"
                                ? brand.offers
                                : [brand.offers[0]];

                            // Prepare the popular item object
                            const popularItem = {
                                offers: offers,
                                brand_logo_url: brand.brand_logo_url || "",
                                brand_name: brand.brand_name || ""
                            };

                            // Add brand name if it's a group view and a group name is specified
                            const groupName = groupNames[viewTypeKey];
                            if (viewType[viewTypeKey] === "group" && groupName) {
                                popularItem.brand_name = groupName;
                            }

                            return popularItem;
                        })
                    })),

                    // New brand group
                    {
                        category: "brands",
                        start_date: newBrandStartDate,
                        end_date: newBrandEndDate,
                        region: "uk",
                        popular_items: newBrandOffers
                            .filter(offer => offer) // Remove empty entries
                            .map((offer, idx) => {
                                // Prepare the popular item object for new brands
                                const popularItem = {
                                    offers: newBrandViewTypes[idx] === "group" && Array.isArray(offer)
                                        ? offer
                                        : [offer],
                                    brand_logo_url: newBrandLogoUrls[idx] || ""
                                };

                                // Add brand_name based on view type
                                if (newBrandViewTypes[idx] === "group" && newBrandGroupNames[idx]) {
                                    // Use custom group name for group view
                                    popularItem.brand_name = newBrandGroupNames[idx];
                                } else if (newBrandViewTypes[idx] === "brands") {
                                    // Use brand name from allBrandList for single brand view
                                    const brandItem = allBrandList.find(b => b.offer_id === offer);
                                    if (brandItem) {
                                        popularItem.brand_name = brandItem.brand_name;
                                    }
                                }

                                return popularItem;
                            })
                    }
                ]
            };



            console.log("payload", apiPayload)

            // Send the API request
            const response = await fetch('https://srg18jtmyk.execute-api.eu-west-2.amazonaws.com/default/amex_whitelabel_popular_items', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(apiPayload)
            });

            // Check if the response is successful
            if (!response.ok) {
                // Clone the response before reading to avoid "body stream already read" error
                const errorText = await response.clone().text();
                console.error('Response not OK:', {
                    status: response.status,
                    statusText: response.statusText,
                    body: errorText
                });
                throw new Error(`HTTP error! status: ${response.status}, body: ${errorText}`);
            }


            // const result = await response.json();
            // console.log('Update successful:', result);


            // Show success message
            alert('Brands updated successfully!');

            // Optional: Log the result or perform any additional actions
            // console.log('Update successful:', result);

            // setNewBrandStartDate('');
            // setNewBrandEndDate('');
            // setNewBrandOffers(Array(8).fill(null));
            // setNewBrandLogoUrls(Array(8).fill(''));
            // setNewBrandGroupNames(Array(8).fill(''));
            // setNewBrandViewTypes(Array(8).fill('brands'));

            // Optionally close the new brand form
            // setShowNewBrandForm(false);

        } catch (error) {
            // Handle any errors during the update process
            console.error('Error updating brands:', error);
            alert('Failed to update brands. Please try again.');
        }
    };

    // Debug: Log newBrands when it updates
    useEffect(() => {
        console.log("Updated newBrands:", newBrands);
    }, [newBrands]);

    // Function to update state safely
    const addNewBrand = (newBrandData) => {
        setNewBrands((prevBrands) => [...prevBrands, newBrandData]);
    };

    // Function to validate if all required fields in a brand group are filled
    const isBrandGroupValid = (brandGroup, groupIndex) => {
        // Check if start and end dates exist
        if (!brandGroup.start_date || !brandGroup.end_date) {
            return false;
        }

        // Check each brand in the group
        for (let idx = 0; idx < brandGroup.brands.length; idx++) {
            const brand = brandGroup.brands[idx];

            // Check if brand has offers selected
            if (!brand.offers || brand.offers.length === 0) {
                return false;
            }

            // Check if logo URL exists
            if (!brand.brand_logo_url) {
                return false;
            }

            // If view type is "group", check if group name exists
            if (viewType[`${groupIndex}-${idx}`] === "group" &&
                (!groupNames[`${groupIndex}-${idx}`] || groupNames[`${groupIndex}-${idx}`].trim() === "")) {
                return false;
            }
        }

        // All validations passed
        return true;
    };
    // Call function with the latest newBrands state
    // const updateBrands = () => {
    //     handleUpdateBrands(newBrands);
    // };

    // Function to handle removing a brand group
    const handleRemoveBrandGroup = (indexToRemove) => {
        try {
            // Create a copy of current brands without the one to be removed
            const updatedBrands = brands.filter((_, index) => index !== indexToRemove);

            // Update local state
            setBrands(updatedBrands);

            // Safely update expanded brands state
            setExpandedBrands(prevExpandedBrands =>
                Array.isArray(prevExpandedBrands)
                    ? prevExpandedBrands.filter((_, index) => index !== indexToRemove)
                    : []
            );

            // Set flag for unsaved changes
            setHasUnsavedChanges(true);

            // Show notification
            alert('Brand group removed. Remember to click the "Save Changes" button to update the server!');
        } catch (error) {
            console.error('Error removing brand group from state:', error);
            alert('Failed to remove brand group. Please try again.');
        }
    };

    const saveNewBrandToState = () => {
        // Create a new brand object to add to the state
        const newBrandData = {
            start_date: newBrandStartDate,
            end_date: newBrandEndDate,
            brands: newBrandOffers
                .filter(offer => offer) // Remove empty entries
                .map((offer, idx) => {
                    return {
                        brand_logo_url: newBrandLogoUrls[idx] || "",
                        brand_name: newBrandViewTypes[idx] === "group" ?
                            newBrandGroupNames[idx] :
                            allBrandList.find(b => b.offer_id === offer)?.brand_name || "",
                        offers: newBrandViewTypes[idx] === "group" && Array.isArray(offer) ?
                            offer : [offer]
                    };
                })
        };

        // Update the brands state
        setBrands(prev => [...prev, newBrandData]);

        // Hide the form
        setShowNewBrandForm(false);

        // Set flag for unsaved changes
        setHasUnsavedChanges(true);

        // Show notification
        alert('Brand group added. Remember to click the "Save Changes" button to update the server!');
    };

    // Main save function for API request
    const handleSaveChanges = async () => {
        try {
            // Prepare the API payload based on the existing brands data
            const apiPayload = {
                records: brands.map(brandGroup => ({
                    category: "brands",
                    start_date: brandGroup.start_date,
                    end_date: brandGroup.end_date,
                    region: "uk",
                    popular_items: brandGroup.brands.map(brand => {
                        // Determine offers based on view type
                        const groupIndex = brands.indexOf(brandGroup);
                        const brandIndex = brandGroup.brands.indexOf(brand);
                        const viewTypeKey = `${groupIndex}-${brandIndex}`;

                        const offers = viewType[viewTypeKey] === "group"
                            ? brand.offers
                            : [brand.offers[0]];

                        // Prepare the popular item object
                        const popularItem = {
                            offers: offers,
                            brand_logo_url: brand.brand_logo_url || "",
                            brand_name: brand.brand_name || ""
                        };

                        // Add brand name if it's a group view and a group name is specified
                        const groupName = groupNames[viewTypeKey];
                        if (viewType[viewTypeKey] === "group" && groupName) {
                            popularItem.brand_name = groupName;
                        }

                        return popularItem;
                    })
                }))
            };

            console.log("payload", apiPayload);

            // Send the API request
            const response = await fetch('https://srg18jtmyk.execute-api.eu-west-2.amazonaws.com/default/amex_whitelabel_popular_items', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(apiPayload)
            });

            // Check if the response is successful
            if (!response.ok) {
                const errorText = await response.clone().text();
                console.error('Response not OK:', {
                    status: response.status,
                    statusText: response.statusText,
                    body: errorText
                });
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Show success message
            alert('All changes saved successfully!');

            // Reset unsaved changes flag
            setHasUnsavedChanges(false);

        } catch (error) {
            console.error('Error saving brands:', error);
            alert('Failed to save changes. Please try again.');
        }
    };

    // Add a confirmation before leaving page with unsaved changes
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (hasUnsavedChanges) {
                e.preventDefault();
                e.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
                return e.returnValue;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [hasUnsavedChanges]);


    // Helper function to format date
    const formatDate = (dateString) => {
        if (!dateString) return "";

        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${day}-${month}-${year}`; // Format as DD-MM-YYYY
    };

    // useEffect(() => {
    //     console.log("Brand offers changed:", brand.offers);
    // }, [brand.offers]);



    // Helper function to display debug info
    const displayDebug = (groupIndex, brandIndex) => {
        const key = `${groupIndex}-${brandIndex}`;
        return `ViewType: ${viewType[key] || "brand"} | Last: ${JSON.stringify(debugData[`${key}-lastSelection`])}`;
    }



    // Add a new empty offer to the list
    const addNewOffer = () => {
        // Create a copy of your offers
        const updatedOffers = [...offerGroup.offers_branches];

        // Add a new empty offer
        updatedOffers.push({
            offer_id: null,
            offer_name: null,
            branch_id: null,
            branch_name: null,
            branches: {}
        });

        // Update state with the new offers array
        setOfferGroup(prevState => ({
            ...prevState,
            offers_branches: updatedOffers
        }));
    };

    const handleAddNewBrand = () => {
        // Set show new brand form
        setShowNewBrandForm(true);

        // Set flag for unsaved changes
        setHasUnsavedChanges(true);
    };

    // const handleSaveChanges = async () => {
    //     try {
    //         // Prepare the API payload based on the existing brands data
    //         const apiPayload = {
    //             records: [
    //                 // Existing brand groups
    //                 ...brands.map(brandGroup => ({
    //                     category: "brands",
    //                     start_date: brandGroup.start_date,
    //                     end_date: brandGroup.end_date,
    //                     region: "uk",
    //                     popular_items: brandGroup.brands.map(brand => {
    //                         // Determine offers based on view type
    //                         const groupIndex = brands.indexOf(brandGroup);
    //                         const brandIndex = brandGroup.brands.indexOf(brand);
    //                         const viewTypeKey = `${groupIndex}-${brandIndex}`;

    //                         const offers = viewType[viewTypeKey] === "group"
    //                             ? brand.offers
    //                             : [brand.offers[0]];

    //                         // Prepare the popular item object
    //                         const popularItem = {
    //                             offers: offers,
    //                             brand_logo_url: brand.brand_logo_url || "",
    //                             brand_name: brand.brand_name || ""
    //                         };

    //                         // Add brand name if it's a group view and a group name is specified
    //                         const groupName = groupNames[viewTypeKey];
    //                         if (viewType[viewTypeKey] === "group" && groupName) {
    //                             popularItem.brand_name = groupName;
    //                         }

    //                         return popularItem;
    //                     })
    //                 })),

    //                 // New brand group (only include if showNewBrandForm is true)
    //                 ...(showNewBrandForm ? [{
    //                     category: "brands",
    //                     start_date: newBrandStartDate,
    //                     end_date: newBrandEndDate,
    //                     region: "uk",
    //                     popular_items: newBrandOffers
    //                         .filter(offer => offer) // Remove empty entries
    //                         .map((offer, idx) => {
    //                             // Prepare the popular item object for new brands
    //                             const popularItem = {
    //                                 offers: newBrandViewTypes[idx] === "group" && Array.isArray(offer)
    //                                     ? offer
    //                                     : [offer],
    //                                 brand_logo_url: newBrandLogoUrls[idx] || ""
    //                             };

    //                             // Add brand_name based on view type
    //                             if (newBrandViewTypes[idx] === "group" && newBrandGroupNames[idx]) {
    //                                 // Use custom group name for group view
    //                                 popularItem.brand_name = newBrandGroupNames[idx];
    //                             } else if (newBrandViewTypes[idx] === "brands") {
    //                                 // Use brand name from allBrandList for single brand view
    //                                 const brandItem = allBrandList.find(b => b.offer_id === offer);
    //                                 if (brandItem) {
    //                                     popularItem.brand_name = brandItem.brand_name;
    //                                 }
    //                             }

    //                             return popularItem;
    //                         })
    //                 }] : [])
    //             ]
    //         };

    //         console.log("payload", apiPayload);

    //         // Send the API request
    //         const response = await fetch('https://srg18jtmyk.execute-api.eu-west-2.amazonaws.com/default/amex_whitelabel_popular_items', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'x-api-key': 'K9JyoXXS7063DiBIT9QCk1kmYkzPih2z213BPWka'
    //             },
    //             body: JSON.stringify(apiPayload)
    //         });

    //         // Check if the response is successful
    //         if (!response.ok) {
    //             const errorText = await response.clone().text();
    //             console.error('Response not OK:', {
    //                 status: response.status,
    //                 statusText: response.statusText,
    //                 body: errorText
    //             });
    //             throw new Error(`HTTP error! status: ${response.status}, body: ${errorText}`);
    //         }

    //         // Show success message
    //         alert('All brand changes saved successfully!');

    //         // Reset unsaved changes flag
    //         setHasUnsavedChanges(false);

    //         // Reset new brand form if needed
    //         if (showNewBrandForm) {
    //             setNewBrandStartDate('');
    //             setNewBrandEndDate('');
    //             setNewBrandOffers(Array(8).fill(null));
    //             setNewBrandLogoUrls(Array(8).fill(''));
    //             setNewBrandGroupNames(Array(8).fill(''));
    //             setNewBrandViewTypes(Array(8).fill('brands'));
    //             setShowNewBrandForm(false);
    //         }

    //     } catch (error) {
    //         console.error('Error saving brands:', error);
    //         alert('Failed to save brand changes. Please try again.');
    //     }
    // };

    // Add a confirmation before leaving page with unsaved changes
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (hasUnsavedChanges) {
                e.preventDefault();
                e.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
                return e.returnValue;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [hasUnsavedChanges]);

    const isFormValid = () => {
        // Check if start date is filled
        if (!newCuisineStartDate) return false;

        // Check if end date is filled
        if (!newCuisineEndDate) return false;

        // Check if all 8 cuisine selections are filled
        for (let i = 0; i < 8; i++) {
            if (!newCuisines[i]) return false;
        }

        return true;
    };

    // Save all offers (to be used with a save button if needed)
    const saveOffers = async () => {
        try {
            // Filter out any incomplete offers
            const completedOffers = offerGroup.offers_branches.filter(
                offer => offer.offer_id && offer.branch_id
            );

            // Prepare data for API
            const saveData = {
                ...offerGroup,
                offers_branches: completedOffers
            };

            // Call your API to save the data
            const response = await fetch("YOUR_SAVE_API_ENDPOINT", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(saveData),
            });

            if (!response.ok) {
                throw new Error("Failed to save offers");
            }

            // Handle successful save
            console.log("Offers saved successfully");

        } catch (error) {
            console.error("Error saving offers:", error);
        }
    };

    // Add this function to check if all cuisine fields are filled
    const isAllCuisineFieldsFilled = () => {
        // Check each cuisine group
        for (const group of cuisines) {
            // Check if all cuisine dropdowns have values
            for (const cuisine of group.cuisines) {
                if (!cuisine.cuisine_name || cuisine.cuisine_name === "") {
                    return false;
                }
            }

            // If you have start_date and end_date fields that need to be required
            if (!group.start_date || !group.end_date) {
                return false;
            }
        }

        // If you have a new form visible, check that too
        if (showNewCuisineForm) {
            // Check all fields in the new form (implement based on your new form structure)
            // Return false if any required field is empty
        }

        // All checks passed
        return true;
    };

    async function fetchData() {
        try {
            const response = await fetch("https://r5l3kw30d8.execute-api.eu-west-2.amazonaws.com/default/amex_whitelabel_fetch_popular_items");
            const data = await response.json();
            setPopularItems(data);
            if (data.cuisines) {
                setCuisines(data.cuisines);
            }
            if (data.offers_branches) {
                setOffersBranches(data.offers_branches);
            }
            if (data.offers_branches) {
                setBrands(data.brands);
            }
            console.log("dattatatatattata", data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    // console.log(offersBranches)
    return (
        <div className="card">
            <div className="card-header d-flex align-items-center">
                <h2 className="m-0">
                    <span>
                        <i className="material-icons heading-icon">pending_actions</i>
                        Popular Items
                    </span>
                </h2>
            </div>
            <div className="card-body">
                <div className="card-header" id="cuisineHeading">
                    <h3 className="mb-0 d-flex justify-content-between align-items-center">
                        <button
                            className="btn btn-link"
                            type="button"
                            onClick={() => setIsCuisineExpanded(!isCuisineExpanded)}
                            aria-expanded={isCuisineExpanded}
                            aria-controls="cuisineSection"
                        >
                            Cuisines {isCuisineExpanded ? '▼' : '►'}
                        </button>
                        <div className="ms-auto">
                            <button
                                className="btn btn-primary me-2 mr-2"
                                onClick={() => handleSaveAllCuisineGroups()}
                                style={{ width: "150px" }}
                                disabled={cuisines.length === 0}
                            >
                                <i className="fa fa-save mr-2"></i> Save Changes
                            </button>
                            <button
                                className="btn btn-success"
                                onClick={() => handleAddNewCuisineGroup()}
                                style={{ justifyContent: "end", width: "200px" }}
                                // disabled={showNewCuisineForm}
                            >
                                <i className="fa fa-plus mr-2"></i> Add New Cuisine Group
                            </button>
                        </div>

                    </h3>
                </div>
                <div className={`collapse ${isCuisineExpanded ? 'show' : ''}`} id="cuisineSection">
                    {cuisines.map((cuisineGroup, index) => (
                        <div className="row mb-3" key={index}>
                            <div className="col-12">
                                <button
                                    className="btn btn-light w-100 text-start d-flex justify-content-between align-items-center p-3 rounded-lg shadow-sm"
                                    onClick={() => toggleGroup(index)}
                                >
                                    <div className="d-flex align-items-center" style={{ width: "30%" }}>
                                        <span>{expandedGroups[index] ? "▼" : "►"}</span>
                                        <strong style={{ marginLeft: "5px" }}>Cuisine Group {index + 1}</strong>
                                    </div>

                                    <div className="text-center" style={{ width: "40%" }}>
                                        <span className="">
                                            Start Date: {cuisineGroup.start_date} - End Date: {cuisineGroup.end_date}
                                        </span>
                                    </div>

                                    <div style={{ width: "30%" }} className="d-flex justify-content-end align-items-center">
                                        <button
                                            className="btn btn-outline-danger btn-sm d-flex align-items-center me-2"
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent triggering the parent button click
                                                if (window.confirm('Are you sure you want to remove this cuisine group?')) {
                                                    handleRemoveCuisineGroup(index);
                                                }
                                            }}
                                            title="Remove this cuisine group"
                                            style={{
                                                padding: '0.25rem 0.5rem',
                                                fontSize: '1rem',
                                                width: "25%"
                                            }}
                                        >
                                            <i className="fa fa-trash mr-3"></i>Remove
                                        </button>
                                        <span className="badge bg-secondary text-light ml-5">{expandedGroups[index] ? "Collapse" : "Expand"}</span>
                                    </div>
                                </button>

                                <div className={`mt-2 ${expandedGroups[index] ? "" : "d-none"}`}>
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>Popular Cuisines</h4>
                                            <div className="row">
                                                {cuisineGroup.cuisines.slice(0, 4).map((cuisine, dropdownIdx) => (
                                                    <div className="col-md-3" key={dropdownIdx}>
                                                        <label><strong>Cuisine {dropdownIdx + 1}</strong></label>
                                                        <select
                                                            className="form-control mb-2"
                                                            value={cuisine.cuisine_name || ""}
                                                            onChange={(e) => handleCuisineChange(index, dropdownIdx, e.target.value)}
                                                        >
                                                            <option value="">Select Cuisine</option>
                                                            {cuisineList.map((cuisineOption, idx) => (
                                                                <option
                                                                    key={idx}
                                                                    value={cuisineOption}
                                                                >
                                                                    {cuisineOption}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                ))}

                                                {cuisineGroup.cuisines.slice(4, 8).map((cuisine, dropdownIdx) => (
                                                    <div className="col-md-3" key={dropdownIdx}>
                                                        <label><strong>Cuisine {dropdownIdx + 5}</strong></label>
                                                        <select
                                                            className="form-control mb-2"
                                                            value={cuisine.cuisine_name || ""}
                                                            onChange={(e) => handleCuisineChange(index, dropdownIdx + 4, e.target.value)}
                                                        >
                                                            <option value="">Select Cuisine</option>
                                                            {cuisineList.map((cuisineOption, idx) => (
                                                                <option
                                                                    key={idx}
                                                                    value={cuisineOption}
                                                                >
                                                                    {cuisineOption}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                ))}

                                            </div>
                                            <div>
                                                {/* <button
                                                    className="btn btn-success mt-4"
                                                    onClick={handleSaveNewCuisineGroup}
                                                >
                                                    Save Cuisine Group
                                                </button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    {/* Add New Cuisine Form - initially hidden */}
                    {showNewCuisineForm && (

                        <div className="card p-3 mt-3 mb-4">
                            <div className="card-header text-black">
                                <h4 className="mb-0">Add New Cuisine Group</h4>
                            </div>
                            <div className="card-body">
                                <div className="row mb-3">
                                    <div className="col-md-3">
                                        <label><strong>Start Date</strong></label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={newCuisineStartDate}
                                            onChange={(e) => setNewCuisineStartDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <label><strong>End Date</strong></label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={newCuisineEndDate}
                                            onChange={(e) => setNewCuisineEndDate(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <h5>Popular Cuisines</h5>
                                <div className="row">
                                    {[...Array(4)].map((_, idx) => (
                                        <div className="col-md-3" key={idx}>
                                            <label><strong>Cuisine {idx + 1}</strong></label>
                                            <select
                                                className="form-control mb-2"
                                                value={newCuisines[idx] || ""}
                                                onChange={(e) => handleNewCuisineChange(idx, e.target.value)}
                                            >
                                                <option value="">Select Cuisine</option>
                                                {cuisineList.map((cuisine, cIdx) => (
                                                    <option key={cIdx} value={cuisine}>{cuisine}</option>
                                                ))}
                                            </select>
                                        </div>
                                    ))}
                                </div>
                                <div className="row">
                                    {[...Array(4)].map((_, idx) => (
                                        <div className="col-md-3" key={idx}>
                                            <label><strong>Cuisine {idx + 5}</strong></label>
                                            <select
                                                className="form-control mb-2"
                                                value={newCuisines[idx + 4] || ""}
                                                onChange={(e) => handleNewCuisineChange(idx + 4, e.target.value)}
                                            >
                                                <option value="">Select Cuisine</option>
                                                {cuisineList.map((cuisine, cIdx) => (
                                                    <option key={cIdx} value={cuisine}>{cuisine}</option>
                                                ))}
                                            </select>
                                        </div>
                                    ))}
                                </div>

                                <div className="row mt-3">
                                    <div className="col-12 d-flex justify-content-end">
                                        <button
                                            className="btn btn-danger me-2"
                                            onClick={() => setShowNewCuisineForm(false)}
                                        >
                                            Cancel
                                        </button>
                                        <div></div>
                                        <button
                                            className="btn btn-success ml-4"
                                            onClick={handleSaveNewCuisineGroup}
                                            disabled={!isFormValid()}
                                        >
                                            Save Cuisine Group
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>


                    )}
                </div>


                <div>
                    <div className="card-header" id="offersHeading">
                        <h3 className="mb-0 d-flex justify-content-between align-items-center">
                            <button
                                className="btn btn-link"
                                type="button"
                                onClick={() => setIsOffersExpanded(!isOffersExpanded)}
                                aria-expanded={isOffersExpanded}
                                aria-controls="offersSection"
                            >
                                Offers Branches {isOffersExpanded ? '▼' : '►'}
                            </button>
                            <div className="ms-auto">
                                <button
                                    className="btn btn-primary me-2 mr-2"
                                    onClick={handleSaveAllChanges}
                                    style={{ width: "150px" }}
                                    disabled={cuisines.length === 0}
                                >
                                    <i className="fa fa-save mr-2"></i> Save Changes
                                </button>
                                <button
                                    className="btn btn-success"
                                    onClick={() => handleAddNewOffersGroup()}
                                    style={{ justifyContent: "end", width: "200px" }}
                                >
                                    <i className="fa fa-plus mr-2"></i> Add New Offers Group
                                </button>
                            </div>
                        </h3>
                    </div>

                    <div className={`collapse ${isOffersExpanded ? 'show' : ''}`} id="offersSection">
                        {offersBranches.map((offerGroup, groupIndex) => (
                            <div className="row mb-3" key={groupIndex}>
                                <div className="col-12">
                                    <button
                                        className="btn btn-light w-100 text-start d-flex justify-content-between align-items-center p-3 rounded-lg shadow-sm"
                                        onClick={() => toggleOfferGroup(groupIndex)}
                                    >
                                        <div className="d-flex align-items-center" style={{ width: "30%" }}>
                                            <span>{expandedOffers[groupIndex] ? "▼" : "►"}</span>
                                            <strong style={{ marginLeft: "5px" }}>Offers Group {groupIndex + 1}</strong>
                                        </div>

                                        <div className="text-center" style={{ width: "40%" }}>
                                            <span>Start Date: {offerGroup.start_date} - End Date: {offerGroup.end_date}</span>
                                        </div>

                                        <div style={{ width: "30%" }} className="d-flex justify-content-end align-items-center">

                                            <button
                                                className="btn btn-outline-danger btn-sm d-flex align-items-center me-2"
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent toggle of offer group
                                                    handleRemoveOfferGroupStateOnly(groupIndex);
                                                }}
                                                title="Remove Offers Group"
                                                style={{
                                                    padding: '0.25rem 0.5rem',
                                                    fontSize: '1rem',
                                                    width: "25%"
                                                }}
                                            >
                                                <i className="fa fa-trash mr-3"></i>Remove
                                            </button>
                                            <span className="badge bg-secondary text-light ml-5">{expandedOffers[groupIndex] ? "Collapse" : "Expand"}</span>
                                        </div>
                                    </button>

                                    <div className={`mt-2 ${expandedOffers[groupIndex] ? "" : "d-none"}`}>
                                        <div className="row">
                                            <div className="col-12">
                                                <h4>Offers</h4>
                                                {offerGroup.offers_branches.slice(0, 8).map((offer, idx) => (
                                                    <div className="row mb-3" key={idx}>
                                                        <div className="col-md-5">
                                                            <label>Brand {idx + 1}</label>
                                                            <Select
                                                                className="action_Selecter w-full"
                                                                options={allBrandList?.map((brand) => ({
                                                                    value: brand.offer_id,
                                                                    label: brand.brand_name,
                                                                })) || []}
                                                                value={
                                                                    offer.offer_id
                                                                        ? { value: offer.offer_id, label: offer.offer_name }
                                                                        : null
                                                                }
                                                                onChange={(option) => handleBrandChange(groupIndex, idx, option.value)}
                                                                placeholder="Select Brand"
                                                            />
                                                            <small className="text-muted d-block mt-1">Offer ID: {offer.offer_id || "N/A"}</small>
                                                        </div>

                                                        <div className="col-md-5">
                                                            <label>Branch {idx + 1}</label>
                                                            <Select
                                                                className="action_Selecter w-full"
                                                                options={
                                                                    offer.branches
                                                                        ? Object.entries(offer.branches).map(([branchId, branchData]) => ({
                                                                            value: branchId,
                                                                            label: branchData.name || `Branch ${branchId}`,
                                                                        }))
                                                                        : []
                                                                }
                                                                value={
                                                                    offer.branch_id
                                                                        ? { value: offer.branch_id, label: offer.branch_name }
                                                                        : null
                                                                }
                                                                onChange={(option) => handleBranchChange(groupIndex, idx, option.value)}
                                                                placeholder="Select Branch"
                                                            />
                                                            <small className="text-muted d-block mt-1">Branch ID: {offer.branch_id || "N/A"}</small>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className="d-flex justify-content-start">
                                                    {/* <button
                                                        className="btn btn-success mt-4"
                                                        onClick={handleSaveNewOffersGroup}
                                                        disabled={!isOfferGroupValid(offerGroup, groupIndex)}

                                                    >
                                                        Save Offers Group
                                                    </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {showNewOffersForm && (
                            <div className="card p-3 mt-3">
                                <div className="card-header text-black">
                                    <h4 className="mb-0">Add New Offers Group</h4>
                                </div>
                                <div className="card-body">
                                    {/* Date Inputs in Single Row */}
                                    <div className="row mb-3">
                                        <div className="col-md-3">
                                            <label>Start Date</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={newOfferStartDate}
                                                onChange={(e) => setNewOfferStartDate(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <label>End Date</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={newOfferEndDate}
                                                onChange={(e) => setNewOfferEndDate(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    {/* Offers Selection */}
                                    <div className="row">
                                        {newOffers.map((offer, idx) => (
                                            <div className="col-md-12 mb-3" key={idx}>
                                                <div className="row align-items-end">
                                                    <div className="col-md-6">
                                                        <label>Offer {idx + 1}</label>
                                                        <Select
                                                            className="action_Selecter w-full"
                                                            options={allBrandList.map((brand) => ({
                                                                value: brand.offer_id,
                                                                label: brand.brand_name,
                                                            }))}
                                                            value={
                                                                offer
                                                                    ? {
                                                                        value: offer,
                                                                        label: allBrandList.find((brand) => brand.offer_id === offer)?.brand_name || "Unknown Brand"
                                                                    }
                                                                    : null
                                                            }
                                                            onChange={(option) => handleNewOfferChange(idx, option.value)}
                                                            placeholder="Select Offer"
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label>Branch {idx + 1}</label>
                                                        <Select
                                                            className="action_Selecter w-full"
                                                            options={offer && selectedOfferBranches[offer]
                                                                ? Object.entries(selectedOfferBranches[offer]).map(([branchId, branchData]) => ({
                                                                    value: branchId,
                                                                    label: branchData.name || `Branch ${branchId}`
                                                                }))
                                                                : []
                                                            }
                                                            value={newBranchSelections[idx]
                                                                ? {
                                                                    value: newBranchSelections[idx],
                                                                    label: selectedOfferBranches[offer]?.[newBranchSelections[idx]]?.name || `Branch ${newBranchSelections[idx]}`
                                                                }
                                                                : null
                                                            }
                                                            onChange={(option) => {
                                                                // Create or update a separate array to track branch selections
                                                                const updatedBranchSelections = [...newBranchSelections];
                                                                updatedBranchSelections[idx] = option.value;
                                                                setNewBranchSelections(updatedBranchSelections);

                                                                // Existing branch selection handler
                                                                handleBranchSelection(idx, option.value);
                                                            }}
                                                            isDisabled={!offer || !selectedOfferBranches[offer]}
                                                            placeholder="Select Branch"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    {/* Save & Cancel Buttons */}
                                    <div className="d-flex justify-content-end">
                                        <button
                                            className="btn btn-danger me-2"
                                            onClick={() => setShowNewOffersForm(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="btn btn-success ml-4"
                                            onClick={handleSaveNewOffersGroup}
                                            disabled={
                                                !newOfferStartDate ||
                                                !newOfferEndDate ||
                                                // Check if all offer slots have been filled
                                                !newOffers.every(offer => offer) ||
                                                // Check if all offers have a selected branch
                                                !newBranchSelections.every(branchSelection => branchSelection)
                                            }
                                        >
                                            Save Offers Group
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>


                <div className="card-header" id="cuisineHeading">
                    <h3 className="mb-0 d-flex justify-content-between align-items-center">
                        <button
                            className="btn btn-link"
                            type="button"
                            onClick={() => setIsBrandsExpanded(!isBrandsExpanded)}
                            aria-expanded={isBrandsExpanded}
                            aria-controls="brandsSection"
                        >
                            Brands {isBrandsExpanded ? '▼' : '►'}
                        </button>
                        {/* Add New Brand Button */}
                        <div className="ms-auto">
                            <button
                                className="btn btn-primary me-2 mr-2"
                                onClick={() => handleSaveChanges()}
                                style={{ width: "150px" }}
                                disabled={cuisines.length === 0}
                            >
                                <i className="fa fa-save mr-2"></i> Save Changes
                            </button>

                            <button
                                className="btn btn-success"
                                onClick={() => handleAddNewBrand()}
                                style={{ justifyContent: "end", width: "200px" }}
                            >
                                <i className="fa fa-plus mr-2"></i> Add New Brand Group
                            </button>
                        </div>
                    </h3>
                </div>
                <div className={`collapse ${isBrandsExpanded ? 'show' : ''}`} id="brandsSection">
                    {brands.map((brandGroup, groupIndex) => (
                        <div className="row mb-3" key={groupIndex}>
                            <div className="col-12">
                                <button
                                    className="btn btn-light w-100 text-start d-flex justify-content-between align-items-center p-3 rounded-lg shadow-sm"
                                    onClick={() => toggleBrandGroup(groupIndex)}
                                >
                                    <div className="d-flex align-items-center" style={{ width: "30%" }}>
                                        <span className="me-2">{expandedBrands[groupIndex] ? "▼" : "►"}</span>
                                        <strong style={{ marginLeft: "5px" }}>Brand Group {groupIndex + 1}</strong>
                                    </div>

                                    <div className="text-center" style={{ width: "40%" }}>
                                        <span className="">
                                            Start Date: {brandGroup.start_date} - End Date: {brandGroup.end_date}
                                        </span>
                                    </div>

                                    <div style={{ width: "30%" }} className="d-flex justify-content-end align-items-center">
                                        <button
                                            className="btn btn-outline-danger btn-sm d-flex align-items-center me-2"
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent triggering the parent button click
                                                if (window.confirm('Are you sure you want to remove this brand group?')) {
                                                    handleRemoveBrandGroup(groupIndex);
                                                }
                                            }}
                                            title="Remove this brand group"
                                            style={{
                                                padding: '0.25rem 0.5rem',
                                                fontSize: '1rem',
                                                width: "25%"
                                            }}
                                        >
                                            <i className="fa fa-trash mr-3"></i>Remove
                                        </button>
                                        <span className="badge bg-secondary text-light ml-5">{expandedBrands[groupIndex] ? "Collapse" : "Expand"}</span>
                                    </div>
                                </button>
                                {/* Remove button */}

                                <div className={`mt-2 ${expandedBrands[groupIndex] ? "" : "d-none"}`}>
                                    {/* <div className="row">
                                        <div className="col-md-4">
                                            <p><strong>Start Date:</strong> {brandGroup.start_date}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <p><strong>End Date:</strong> {brandGroup.end_date}</p>
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>Brands</h4>
                                            {brandGroup.brands.slice(0, 8).map((brand, idx) => (

                                                <div className="row mb-4" key={idx}>
                                                    <div className="col-md-3">
                                                        <label>Brand {idx + 1}</label>
                                                        {/* <button
                                                    type="button"
                                                    className={`btn btn-sm ${viewType[`${groupIndex}-${idx}`] === "group" ? "btn-primary" : "btn-outline-primary"}`}
                                                    onClick={() => toggleViewType(groupIndex, idx)}
                                                >
                                                    {viewType[`${groupIndex}-${idx}`] === "group" ? "Group View" : "Single View"}
                                                </button> */}
                                                        <Select
                                                            key={`select-${groupIndex}-${idx}`}
                                                            className="action_Selecter w-full"
                                                            options={
                                                                Array.isArray(allBrandList)
                                                                    ? allBrandList.map((brandItem) => ({
                                                                        value: brandItem.offer_id,
                                                                        label: brandItem.brand_name,
                                                                    }))
                                                                    : []
                                                            }
                                                            value={
                                                                viewType[`${groupIndex}-${idx}`] === "group"
                                                                    ? brand.offers.map(offerId => {
                                                                        const matchingBrand = allBrandList.find(b => b.offer_id === offerId);
                                                                        return {
                                                                            value: offerId,
                                                                            label: matchingBrand ? matchingBrand.brand_name : `Offer ID: ${offerId}`
                                                                        };
                                                                    })
                                                                    : brand.offers.length > 0
                                                                        ? (() => {
                                                                            const matchingBrand = allBrandList.find(b => b.offer_id === brand.offers[0]);
                                                                            return {
                                                                                value: brand.offers[0],
                                                                                label: matchingBrand ? matchingBrand.brand_name : `Offer ID: ${brand.offers[0]}`
                                                                            };
                                                                        })()
                                                                        : null
                                                            }
                                                            onChange={(option) => {
                                                                console.log("Raw onChange option:", option);
                                                                handleSelectChange(groupIndex, idx, option);
                                                            }}
                                                            placeholder="Select Brand"
                                                            isMulti={viewType[`${groupIndex}-${idx}`] === "group"}
                                                            isClearable={true}
                                                        />
                                                        {/* <small className="text-muted d-block mt-1">Brand Category: {brand.offer_category || "N/A"}</small> */}
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label>Logo URL Brand {idx + 1}</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={brand.brand_logo_url || ""}
                                                            onChange={(e) => handleLogoUrlChange(groupIndex, idx, e.target.value)}
                                                            placeholder="Enter logo URL"
                                                        />
                                                        {/* <small className="text-muted d-block mt-1">Logo Status: {brand.brand_logo_url ? "Set" : "Not set"}</small> */}
                                                    </div>
                                                    {/* Group Name Input Field */}
                                                    <div className="col-md-3">
                                                        <label><strong>Group Name:</strong></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={(brand && brand.brand_name) || (viewType[`${groupIndex}-${idx}`] === "brands" ? brand.brand_name : (groupNames[`${groupIndex}-${idx}`] || ""))}
                                                            onChange={(e) => handleGroupNameChange(`${groupIndex}-${idx}`, e.target.value)}
                                                            placeholder="Enter group name"
                                                            disabled={viewType[`${groupIndex}-${idx}`] === "brands"} // Disable input when in brand mode
                                                        />
                                                    </div>
                                                    {/* View Type dropdown for each row */}
                                                    <div className="col-md-3">
                                                        <label><strong>View Type:</strong></label>
                                                        <select
                                                            className="form-control action-selector"
                                                            value={viewType[`${groupIndex}-${idx}`] || "brands"} // Default to "brands"
                                                            onChange={(e) => handleViewTypeChange(`${groupIndex}-${idx}`, e.target.value)}
                                                        >
                                                            <option value="brands">Brands</option>
                                                            <option value="group">Group</option>
                                                        </select>
                                                    </div>

                                                </div>
                                            ))}
                                            <div className="d-flex justify-content-start">
                                                {/* <button
                                                    className="btn btn-success mt-2"
                                                    onClick={handleBrandsUpdate}
                                                    disabled={!isBrandGroupValid(brandGroup, groupIndex)}
                                                >
                                                    Save Brand Group
                                                </button> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    {/* Add New Brand Form */}
                    {showNewBrandForm && (
                        <div className="card p-3 mt-3 mb-4">
                            <div className="card-header text-black">
                                <h4 className="mb-0">Add New Brand Group</h4>
                            </div>
                            <div className="card-body">
                                {/* Date Inputs in Single Row */}
                                <div className="row mb-3">
                                    <div className="col-md-3">
                                        <label>Start Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={newBrandStartDate}
                                            onChange={(e) => setNewBrandStartDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <label>End Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={newBrandEndDate}
                                            onChange={(e) => setNewBrandEndDate(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <h4>Brands</h4>
                                        {/* Map through 8 rows for brands */}
                                        {Array.from({ length: 8 }).map((_, idx) => (
                                            <div className="row mb-4" key={idx}>
                                                <div className="col-md-3">
                                                    <label>Brand {idx + 1}</label>
                                                    <Select
                                                        className="action_Selecter w-full"
                                                        options={Array.isArray(allBrandList)
                                                            ? allBrandList.map((brandItem) => ({
                                                                value: brandItem.offer_id,
                                                                label: brandItem.brand_name,
                                                            }))
                                                            : []
                                                        }
                                                        value={
                                                            newBrandViewTypes[idx] === "group"
                                                                ? newBrandOffers[idx]
                                                                    ? Array.isArray(newBrandOffers[idx])
                                                                        ? newBrandOffers[idx].map(offerId => ({
                                                                            value: offerId,
                                                                            label: allBrandList.find(b => b.offer_id === offerId)?.brand_name || `Offer ID: ${offerId}`
                                                                        }))
                                                                        : [{
                                                                            value: newBrandOffers[idx],
                                                                            label: allBrandList.find(b => b.offer_id === newBrandOffers[idx])?.brand_name || `Offer ID: ${newBrandOffers[idx]}`
                                                                        }]
                                                                    : []
                                                                : newBrandOffers[idx]
                                                                    ? {
                                                                        value: newBrandOffers[idx],
                                                                        label: allBrandList.find(b => b.offer_id === newBrandOffers[idx])?.brand_name || `Offer ID: ${newBrandOffers[idx]}`
                                                                    }
                                                                    : null
                                                        }
                                                        onChange={(option) => handleNewBrandOfferChange(idx, option)}
                                                        placeholder="Select Brand"
                                                        isMulti={newBrandViewTypes[idx] === "group"}
                                                        isClearable={true}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <label>Logo URL Brand {idx + 1}</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={newBrandLogoUrls[idx] || ""}
                                                        onChange={(e) => handleNewBrandLogoUrlChange(idx, e.target.value)}
                                                        placeholder="Enter logo URL"
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <label><strong>Group Name:</strong></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={newBrandViewTypes[idx] === "brands"
                                                            ? (allBrandList.find(b => b.offer_id === newBrandOffers[idx])?.brand_name || "")
                                                            : newBrandGroupNames[idx] || ""}
                                                        onChange={(e) => handleNewBrandGroupNameChange(idx, e.target.value)}
                                                        placeholder="Enter group name"
                                                        disabled={newBrandViewTypes[idx] === "brands"}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <label><strong>View Type:</strong></label>
                                                    <select
                                                        className="form-control action-selector"
                                                        value={newBrandViewTypes[idx] || "brands"}
                                                        onChange={(e) => handleNewBrandViewTypeChange(idx, e.target.value)}
                                                    >
                                                        <option value="brands">Brands</option>
                                                        <option value="group">Group</option>
                                                    </select>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/* Save & Cancel Buttons */}
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-danger me-2" onClick={() => setShowNewBrandForm(false)}>Cancel</button>
                                    <button
                                        className="btn btn-success ml-4"
                                        onClick={saveNewBrandToState}
                                        disabled={!newBrandStartDate || !newBrandEndDate ||
                                            newBrandOffers.slice(0, 8).some((offer, idx) =>
                                                !offer ||
                                                (newBrandViewTypes[idx] === "group" && !newBrandGroupNames[idx]) ||
                                                !newBrandLogoUrls[idx]
                                            )}
                                    >
                                        Save Brand Group
                                    </button>
                                </div>
                            </div>

                        </div>
                    )}

                </div>

            </div>
        </div >
    );
}
