import { useState, useEffect } from "react";

const BranchAssets = ({ handleBranchUpdate, assets = [], videos = [], setBrandDetails, selectedBranch, validateBrandDetails }) => {
    const [imageAssets, setImageAssets] = useState([]);
    const [videoAssets, setVideoAssets] = useState([]);
    const [newImage, setNewImage] = useState("");
    const [newVideo, setNewVideo] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const isUpdateDisabled = isSubmitting || !validateBrandDetails();
    const [lastAddedAsset, setLastAddedAsset] = useState(null);

    const normalizedAssets = assets.map(asset =>
        typeof asset === 'string'
            ? { image: asset, video: '' }
            : asset
    );

    const validateBranchAssetUrl = (imageUrl) => {
        // Handle both string and object inputs
        const url = typeof imageUrl === 'object' ? imageUrl.image : imageUrl;

        if (!url || typeof url !== "string") {
            console.log("Invalid imageUrl:", imageUrl); // Debugging log
            return false;
        }

        const requiredPrefix = "https://travx-bkt.s3.eu-west-2.amazonaws.com/creatives/Tiles/amex";
        const isValid = url.startsWith(requiredPrefix);

        console.log(`Validating URL: ${url}, Result: ${isValid}`); // Debugging log
        return isValid;
    };


    const updateAssets = (updatedAssets, updatedVideos) => {
        setBrandDetails(prev => ({
            ...prev,
            branches: {
                ...prev.branches,
                [selectedBranch]: {
                    ...prev.branches[selectedBranch],
                    creatives: updatedAssets,
                    videos: updatedVideos
                }
            }
        }));
    };


    useEffect(() => {
        if (lastAddedAsset) {
            const timer = setTimeout(() => setLastAddedAsset(null), 3000); // Remove tick after 3 seconds
            return () => clearTimeout(timer); // Cleanup on component unmount or re-run
        }
    }, [lastAddedAsset]);


    const handleAssetChange = (index, key, value) => {
        console.log("Updating Asset:", index, key, value);

        // If updating an image, validate before updating state
        if (key === "image" && !validateBranchAssetUrl(value)) {
            console.warn("Invalid image URL, change rejected:", value);
            return; // Stop the update if invalid
        }
        setBrandDetails(prev => {
            const updatedAssets = [...(prev.branches[selectedBranch]?.creatives || [])];
            updatedAssets[index] = { ...updatedAssets[index], [key]: value }; // Shallow copy and update key
            return {
                ...prev,
                branches: {
                    ...prev.branches,
                    [selectedBranch]: {
                        ...prev.branches[selectedBranch],
                        creatives: updatedAssets
                    }
                }
            };
        });
    };

    useEffect(() => {
        if (Array.isArray(assets) && assets.length > 0) {
            const isNormalized = assets.every(asset => typeof asset === "object");
            if (!isNormalized) {
                console.log("Normalizing assets...");
                const normalizedAssets = assets.map(asset =>
                    typeof asset === "string" ? { image: asset, video: "" } : asset
                );

                setBrandDetails(prev => ({
                    ...prev,
                    branches: {
                        ...prev.branches,
                        [selectedBranch]: {
                            ...prev.branches[selectedBranch],
                            creatives: normalizedAssets
                        }
                    }
                }));
            }
        }
    }, [assets, selectedBranch, setBrandDetails]);


    return (
        <div className="branch-assets-container">
            <div className="assets-section">
                <div className="assets-columns">
                    {/* Branch Images Section */}
                    <div className="assets-column">
                        <div className="section-header">
                            <h5>Branch Images</h5>
                        </div>
                        {normalizedAssets
                            .filter(asset => asset.image && !asset.video)
                            .map((asset, index) => (
                                <div key={`image-${index}`} className="asset-row">
                                    <input
                                        type="text"
                                        value={asset.image || ""}
                                        className={`asset-input ${validateBranchAssetUrl(asset) ? '' : 'is-invalid'}`}
                                        onChange={(e) => {
                                            handleAssetChange(
                                                normalizedAssets.findIndex(a => a === asset),
                                                'image',
                                                e.target.value
                                            );
                                        }}
                                        placeholder={!asset.image ? "No image URL" : ""}
                                    />
                                    {!validateBranchAssetUrl(asset) && (
                                        <div className="invalid-feedback">
                                            Image URL must begin with "https://travx-bkt.s3.eu-west-2.amazonaws.com/creatives/Tiles/amex"
                                        </div>
                                    )}
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => {
                                            const updatedAssets = normalizedAssets.filter(a => a !== asset);
                                            updateAssets(updatedAssets, videos);
                                        }}
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            ))}

                        <div className="add-new-asset">
                            <input
                                type="text"
                                className="asset-input"
                                placeholder="New Image URL"
                                value={newImage}
                                onChange={(e) => setNewImage(e.target.value)}
                            />
                            <button
                                className="add-btn btn-primary"
                                onClick={() => {
                                    if (newImage) {
                                        if (!validateBranchAssetUrl({ image: newImage })) {
                                            console.warn("Invalid Image URL! Asset not added:", newImage);
                                            return;
                                        }
                                        const newAsset = { image: newImage, video: '' };
                                        updateAssets([...normalizedAssets, newAsset], videos);
                                        setNewImage("");
                                        setLastAddedAsset(newAsset);
                                    }
                                }}
                                disabled={!newImage}
                            >
                                +
                            </button>
                        </div>
                    </div>

                    {/* Branch Videos Section */}
                    <div className="assets-column">
                        <div className="section-header">
                            <h5>Branch Videos</h5>
                        </div>
                        {videos.map((video, index) => (
                            <div key={`video-${index}`} className="asset-row">
                                <input
                                    type="text"
                                    className={`asset-input ${validateBranchAssetUrl(video) ? '' : 'is-invalid'}`}
                                    value={video || ""}
                                    onChange={(e) => {
                                        const newVideos = [...videos];
                                        newVideos[index] = e.target.value;
                                        updateAssets(normalizedAssets, newVideos);
                                    }}
                                    placeholder={!video ? "No video URL" : ""}
                                />
                                {!validateBranchAssetUrl(video) && (
                                    <div className="invalid-feedback">
                                        Video URL must begin with "https://travx-bkt.s3.eu-west-2.amazonaws.com/creatives/Tiles/amex"
                                    </div>
                                )}
                                <button
                                    className="btn btn-danger"
                                    onClick={() => {
                                        const updatedVideos = videos.filter((_, i) => i !== index);
                                        updateAssets(normalizedAssets, updatedVideos);
                                    }}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        ))}

                        <div className="add-new-asset">
                            <input
                                type="text"
                                className="asset-input"
                                placeholder="New Video URL"
                                value={newVideo}
                                onChange={(e) => setNewVideo(e.target.value)}
                            />
                            <button
                                className="add-btn btn-primary"
                                onClick={() => {
                                    if (newVideo) {
                                        if (!validateBranchAssetUrl(newVideo)) {
                                            console.warn("Invalid Video URL! Asset not added:", newVideo);
                                            return;
                                        }
                                        updateAssets(normalizedAssets, [...videos, newVideo]);
                                        setNewVideo("");
                                        setLastAddedAsset(newVideo);
                                    }
                                }}
                                disabled={!newVideo}
                            >
                                +
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <style jsx>{`
        .branch-assets-container {
            border: 1px solid #ccc;
            padding: 10px;
            border-radius: 5px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
        .branch-assets-header {
            font-size: 14px;
            font-weight: bold;
            padding-bottom: 5px;
        }
        .assets-section {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        .assets-columns {
            display: flex;
            flex-direction: row;
            gap: 30px;
            width: 50%;
        }
        .assets-column {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        .asset-row {
            display: flex;
            align-items: center;    
        }
        .section-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 5px;
            font-weight: bold;
        }
        .add-new-asset {
            display: flex;
            gap: 10px;
            align-items: center;
            width: 100%;
        }
        .asset-input {
            width: 100%;
            border: none;
            border-bottom: 1px solid #ccc;
            outline: none;
            font-size: 14px;
            padding: 3px;
        }
        .remove-btn {
            background: red;
            color: white;
            border: none;
            cursor: pointer;
            padding: 5px;
            border-radius: 3px;
        }
        .add-btn {
            background: grey;
            color: white;
            border: none;
            cursor: pointer;
            padding: 5px;
            border-radius: 3px;
            width: min-content;
        }
        .is-invalid {
            border-bottom-color: red;
        }
        .invalid-feedback {
            color: red;
            font-size: 12px;
            margin-left: 10px;
        }
    `}
            </style>
        </div>
    );
};

export default BranchAssets;
