import React from 'react';
import cx from 'classnames';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { setMobileNavVisibility } from '../../redux/reducers/layout';
import { withRouter } from 'react-router-dom';

import Header from './Header'
import SideBar from '../../components/SideBar/SideBar.component'
import UserProfile from '../UserProfile'
import CurrentCampaign from '../CurrentCampaign'
import FutureCampaign from '../FutureCampaign'
import PastCampaign from '../PastCampaign'
// import Branches from '../Branches'
import Employees from '../Employees'
import Home from '../Home'
import CampaignOverviewPage from '../CampaignOverviewPage'
import CreateCampaign from '../CreateCampaign'
import ReportPage from '../ReportPage'
import PreviewPage from '../PreviewPage'
import Notifications from 'react-notification-system-redux';
import Brands from '../Brands'
import Users from '../Users';
import BrandAndBranchMain from '../NewAddBrandAndBranch/BrandAndBranchMain'
// import EmailTemplates from '../EmailTemplates'
// import Roles from '../Roles';
import Merchants from '../Merchants';
import GroupBrands from '../GroupBrands';
import Activities from '../Activities';
import Distributors from '../Distributors';
import CampaignTemplate from '../CampaignTemplate';
// import { BankDetail, BrandDetail } from '../EntityDetail'
import CampaignOverviewManager from '../CampaignOverviewManager'
import CampaignOverviewEdit from '../CampaignOverviewEdit'
import Settings from '../Settings'
import config from './../../config/config'
import { getEntityDetails } from '../../redux/actions';
import Dashboard from '../Home/dashboard';
import BrandsForBank from '../BrandsForBank';
import OnboardingActivities from '../Onboarding Activities';
import BrandActivity from '../BrandActivities/brandActivity';
import IngestionLogs from '../IngestionLogs';
import BrandActionCenter from '../ActionCenter/BrandActionCenter';
import PublisherActionCenter from '../ActionCenter/PublisherActionCenter';
import CreateTicket from '../CreateTicket/CreateTicket';
import SignupUsers from '../SignupUsers';
import WhitelabelControls from '../ActionCenter/WhitelabelControls';
import UpdatedWhitelabel from '../ActionCenter/UpdatedWhitelabel';
import PopularSection from '../ActionCenter/PopularSection';
import ExperienceForm from '../ActionCenter/Experience';
import DashboardV2 from '../Home/dashboardV2';
import FetchOffersPage from '../rewards/fetch-offers';
import LoyaltyPointsPage from '../rewards/loyalty-points';

const LayoutWithSidebar = ({
  mobileNavVisibility,
  hideMobileMenu,
  history,
  match,
  user,
  notifications,
  sidebarNavVisibility,
  getEntityDetails
}) => {
  history.listen((location) => {
    if (mobileNavVisibility === true) {
      hideMobileMenu(); // listens to make sure if mobile menu is being displayed to hide it
    }
  });

  if (!user.entity) {
    getEntityDetails()
  }

  let publisherURL = "";
  if(user.entityType === 'bank' && sessionStorage.getItem("publisherURL")) {
    let purl = sessionStorage.getItem("publisherURL");
    publisherURL = purl.split("user/")[1];
    sessionStorage.removeItem("publisherURL");
  }

  return (
    <div className={cx({
      'nav-open': mobileNavVisibility === true,
      'sidebar-close': sidebarNavVisibility === false
    })}>
      <div >
        {<div className="close-layer"></div>}
        <SideBar />
        <div className="main-panel" style={{ height: '100vh' }}>
          <div className="container-fluid">
            <Header />
            {
              user.entityType === 'systemAdmin'
                ? (
                  <Switch>
                    <Route exact path={`${match.url}/brands/list`} component={Brands} />
                    <Route exact path={`${match.url}/campaign-overview/edit/:id`} component={CampaignOverviewEdit} />
                    <Route exact path={`${match.url}/campaign-overview`} component={CampaignOverviewManager} />
                    <Route exact path={`${match.url}/settings/profile`} component={UserProfile} />
                    <Route exact path={`${match.url}/campaigns/future`} component={FutureCampaign} />
                    <Route exact path={`${match.url}/campaigns/past`} component={PastCampaign} />
                    <Route exact path={`${match.url}/campaigns/current`} component={CurrentCampaign} />
                    <Route path={`${match.url}/campaigns/reports/:id`} component={ReportPage} />
                    <Route path={`${match.url}/campaigns/edit`} component={CreateCampaign} />
                    <Route exact path={`${match.url}/campaigns/preview/:id`} component={PreviewPage} />
                    <Route path={`${match.url}/campaigns/overview`} component={CampaignOverviewPage} />
                    <Route path={`${match.url}/campaigns/reports/:id`} component={ReportPage} />
                    <Redirect exact path="/user" to='/user/banks/list' />
                  </Switch>
                ) : ''
            }
            {
              user.entityType === 'bank'
                ? (
                  <Switch>
                    {/* <Route exact path={`${match.url}/settings/bank`} component={BankDetail} /> */}
                    {/* <Route exact path={`${match.url}/brands/list`} component={Brands} /> */}
                    <Route exact path={`${match.url}/list/brands`} component={Brands} />
                    <Route exact path={`${match.url}/edit/brand/:id/:brandname/:brand_id`} component={BrandAndBranchMain} />
                    <Route exact path={`${match.url}/publisheractioncenter`} component={PublisherActionCenter} /> 
                    <Route exact path={`${match.url}/campaigns/approval`} component={BrandsForBank} />
                    <Route exact path={`${match.url}/campaigns/past`} component={PastCampaign} />
                    <Route exact path={`${match.url}/campaigns/current`} component={CurrentCampaign} />
                    <Route exact path={`${match.url}/campaigns/future`} component={FutureCampaign} />


                    <Route path={`${match.url}/settings/profile`} component={UserProfile} />
                    <Route path={`${match.url}/campaigns/overview`} component={CampaignOverviewPage} />
                    <Route path={`${match.url}/campaigns/reports/:id`} component={ReportPage} />
                    <Route path={`${match.url}/dashboard`} component={Dashboard} />

                    <Route path={`${match.url}/campaigns/overview`} component={CampaignOverviewPage} />
                    <Route path={`${match.url}/campaigns/reports/:id`} component={ReportPage} />

                    {publisherURL ? 
                    <Redirect exact path="/user" to={`${match.url}/${publisherURL}`} /> :
                    <Redirect exact path="/user" to={`${match.url}/dashboard`} />
                    }
                    
                    {/* <Redirect exact path="/user" to='/user/list/brands' /> */}
                  </Switch>
                ) : ''
            }
            {
              /*
                  <Route exact path={`${match.url}/settings/brand`} component={BrandDetail} />
                  <Route path={`${match.url}/brand/users`} component={BrandUsers} />
                  <Route path={`${match.url}/branches`} component={Branches} /> 
                  <Route exact path={`${match.url}/campaigns/create`} component={CreateCampaign} />
                  <Route exact path={`${match.url}/campaigns/future`} component={FutureCampaign} /> 
                  <Route exact path={`${match.url}/campaigns/past`} component={PastCampaign} />
                  <Route exact path={`${match.url}/campaigns/current`} component={CurrentCampaign} />
                  <Route path={`${match.url}/campaigns/edit`} component={CreateCampaign} /> 
                  <Route path={`${match.url}/campaigns/draft`} component={DraftCampaign} />
                  <Route path={`${match.url}/settings/profile`} component={UserProfile} />
                  <Route path={`${match.url}/campaigns/reports/:id`} component={ReportPage} />
                  <Redirect exact path="/" to='/user/dashboard'/>
                  <Route path={`${match.url}/dashboard`} component={Home} />

              */
              user.entityType === 'superAdmin'
                ? (
                  <Switch>
                    <Route exact path={`${match.url}/list/employees`} component={Employees} />
                    <Route exact path={`${match.url}/experience`} component={ExperienceForm} />
                    <Route exact path={`${match.url}/list/users`} component={Users} />
                    <Route exact path={`${match.url}/list/branches`} component={Merchants} />
                    <Route exact path={`${match.url}/list/groupbrand`} component={GroupBrands} />
                    <Route exact path={`${match.url}/list/brands`} component={Brands} />
                    <Route exact path={`${match.url}/brandactioncenter`} component={BrandActionCenter} />
                    <Route exact path={`${match.url}/create/brand`} component={BrandAndBranchMain} />
                    <Route exact path={`${match.url}/edit/brand/:id/:brandname/:brand_id`} component={BrandAndBranchMain} />
                    <Route exact path={`${match.url}/createticket`} component={CreateTicket} />
                    {/* <Route exact path={`${match.url}/list/roles`} component={Roles} /> */}
                    <Route exact path={`${match.url}/list/brandactivity`} component={BrandActivity} />
                    <Route exact path={`${match.url}/list/activities`} component={Activities} />
                    <Route exact path={`${match.url}/list/onboardactivities`} component={OnboardingActivities} />
                    <Route exact path={`${match.url}/list/ingestionLogs`} component={IngestionLogs} />
                    <Route exact path={`${match.url}/list/signupUsers`} component={SignupUsers} />
                    <Route path={`${match.url}/campaigns/edit`} component={CreateCampaign} />

                    <Route exact path={`${match.url}/list/campaigntemplates`} component={CampaignTemplate} />

                    {/* <Route exact path={`${match.url}/list/emailtemplates`} component={EmailTemplates} /> */}
                    <Route path={`${match.url}/settings/profile`} component={UserProfile} />
                    <Route path={`${match.url}/settings/settings`} component={Settings} />
                    <Route exact path={`${match.url}/campaigns/future`} component={FutureCampaign} />
                    {/* <Route exact path={`${match.url}/settings/brand`} component={BrandDetail} /> */}
                    {/* <Route path={`${match.url}/settings/branches`} component={Branches} /> */}
                    <Route path={`${match.url}/dashboard`} component={Dashboard} />
                    <Route path={`${match.url}/dashboard-v2`} component={DashboardV2} />
                    <Route path={`${match.url}/rewards/loyalty-points`} component={LoyaltyPointsPage} />
                    <Route path={`${match.url}/rewards/fetch-offers`} component={FetchOffersPage} />

                    <Route exact path={`${match.url}/campaigns/past`} component={PastCampaign} />
                    <Route exact path={`${match.url}/campaigns/current`} component={CurrentCampaign} />
                    <Route path={`${match.url}/campaigns/reports/:id`} component={ReportPage} />
                    <Route path={`${match.url}/campaigns/overview`} component={CampaignOverviewPage} />
                    <Route path={`${match.url}/campaigns/reports/:id`} component={ReportPage} />
                    <Redirect exact path="/user" to='/user/dashboard-v2' />
                    <Route exact path={`${match.url}/list/distributors`} component={Distributors} />
                  </Switch>
                ) : ''
            }
            {
              user.entityType === 'salesAdmin'
                ? (
                  <Switch>
                    <Route exact path={`${match.url}/list/users`} component={Users} />
                    <Route exact path={`${match.url}/list/branches`} component={Merchants} />
                    <Route exact path={`${match.url}/list/groupbrand`} component={GroupBrands} />
                    <Route exact path={`${match.url}/list/brands`} component={Brands} />
                    <Route exact path={`${match.url}/create/brand`} component={BrandAndBranchMain} />
                    <Route exact path={`${match.url}/edit/brand/:id/:brandname/:brand_id`} component={BrandAndBranchMain} />
                    <Route exact path={`${match.url}/brandactioncenter`} component={BrandActionCenter} />
                    <Route exact path={`${match.url}/publisheractioncenter`} component={PublisherActionCenter} />  
                    <Route exact path={`${match.url}/createticket`} component={CreateTicket} />
                    <Route exact path={`${match.url}/list/brandactivity`} component={BrandActivity} />
                    <Route exact path={`${match.url}/list/activities`} component={Activities} />
                    <Route exact path={`${match.url}/list/onboardactivities`} component={OnboardingActivities} />
                    <Route exact path={`${match.url}/list/ingestionLogs`} component={IngestionLogs} />
                    <Route exact path={`${match.url}/list/signupUsers`} component={SignupUsers} />
                    <Route path={`${match.url}/campaigns/edit`} component={CreateCampaign} /> 
                    <Route path={`${match.url}/settings/profile`} component={UserProfile} />
                    <Route path={`${match.url}/settings/settings`} component={Settings} />
                    <Route exact path={`${match.url}/campaigns/future`} component={FutureCampaign} /> 
                    <Route path={`${match.url}/dashboard`} component={Dashboard} />
                    <Route path={`${match.url}/dashboard-v2`} component={DashboardV2} />
                    <Route exact path={`${match.url}/campaigns/past`} component={PastCampaign} />
                    <Route exact path={`${match.url}/campaigns/current`} component={CurrentCampaign} />
                    <Route path={`${match.url}/campaigns/reports/:id`} component={ReportPage} />
                    <Route path={`${match.url}/campaigns/overview`} component={CampaignOverviewPage} />
                    <Route path={`${match.url}/campaigns/reports/:id`} component={ReportPage} />
                    <Redirect exact path="/user" to='/user/dashboard-v2' />
                    <Route exact path={`${match.url}/list/distributors`} component={Distributors} />
                  </Switch>

                ) : ''
            }
            {
              user.entityType === 'opsAdmin'
                ? (
                  <Switch>
                    <Route exact path={`${match.url}/list/users`} component={Users} />
                    <Route exact path={`${match.url}/list/branches`} component={Merchants} />
                    <Route exact path={`${match.url}/list/groupbrand`} component={GroupBrands} />
                    <Route exact path={`${match.url}/list/brands`} component={Brands} />
                    <Route exact path={`${match.url}/create/brand`} component={BrandAndBranchMain} />
                    <Route exact path={`${match.url}/edit/brand/:id/:brandname/:brand_id`} component={BrandAndBranchMain} /> 
                    <Route exact path={`${match.url}/brandactioncenter`} component={BrandActionCenter} /> 
                    <Route exact path={`${match.url}/publisheractioncenter`} component={PublisherActionCenter} /> 
                    <Route exact path={`${match.url}/createticket`} component={CreateTicket} />
                    <Route exact path={`${match.url}/whitelabelControls`} component={WhitelabelControls} /> 
                    <Route exact path={`${match.url}/UpdatedWhitelabel`} component={UpdatedWhitelabel} />
                    <Route exact path={`${match.url}/PopularSection`} component={PopularSection} /> 
                    <Route exact path={`${match.url}/list/brandactivity`} component={BrandActivity} />
                    <Route exact path={`${match.url}/list/activities`} component={Activities} />
                    <Route exact path={`${match.url}/list/onboardactivities`} component={OnboardingActivities} />
                    <Route exact path={`${match.url}/list/ingestionLogs`} component={IngestionLogs} />
                    <Route exact path={`${match.url}/list/signupUsers`} component={SignupUsers} />
                    <Route path={`${match.url}/campaigns/edit`} component={CreateCampaign} /> 
                    <Route path={`${match.url}/settings/profile`} component={UserProfile} />
                    <Route path={`${match.url}/settings/settings`} component={Settings} />
                    <Route exact path={`${match.url}/campaigns/future`} component={FutureCampaign} /> 
                    <Route path={`${match.url}/dashboard`} component={Dashboard} />
                    <Route path={`${match.url}/dashboard-v2`} component={DashboardV2} />
                    <Route exact path={`${match.url}/campaigns/past`} component={PastCampaign} />
                    <Route exact path={`${match.url}/campaigns/current`} component={CurrentCampaign} />
                    <Route path={`${match.url}/campaigns/reports/:id`} component={ReportPage} />
                    <Route path={`${match.url}/campaigns/overview`} component={CampaignOverviewPage} />
                    <Route path={`${match.url}/campaigns/reports/:id`} component={ReportPage} />
                    <Redirect exact path="/user" to='/user/dashboard-v2' />
                    <Route exact path={`${match.url}/list/distributors`} component={Distributors} />
                  </Switch>
                ) : ''
            }
            {
              user.entityType === 'cardprovider'
                ? (
                  <Switch>
                    <Route exact path={`${match.url}/campaigns/past`} component={PastCampaign} />
                    <Route exact path={`${match.url}/campaigns/current`} component={CurrentCampaign} />
                    <Route path={`${match.url}/settings/profile`} component={UserProfile} />
                    <Route path={`${match.url}/campaigns/overview`} component={CampaignOverviewPage} />
                    <Route path={`${match.url}/campaigns/reports/:id`} component={ReportPage} />
                    <Route path={`${match.url}/dashboard`} component={Home} />
                    <Route path={`${match.url}/campaigns/overview`} component={CampaignOverviewPage} />
                    <Route path={`${match.url}/campaigns/reports/:id`} component={ReportPage} />
                    <Redirect exact path="/" to='/user/dashboard' />
                  </Switch>
                ) : ''
            }
          </div>
          {/* <Footer /> */}
        </div>
        <Notifications
          notifications={notifications}
        />
      </div>
    </div>
  )
}


export default withRouter(
  connect(
    state => ({
      user: state.user,
      mobileNavVisibility: state.Layout.mobileNavVisibility,
      sidebarNavVisibility: state.Layout.sidebarNavVisibility,
      notifications: state.notifications
    }),
    (dispatch) => ({
      hideMobileMenu: () => dispatch(setMobileNavVisibility(false)),
      getEntityDetails: () => dispatch(getEntityDetails(config.entityType))
    })
  )(LayoutWithSidebar)
)
