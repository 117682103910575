import React, { useEffect, useState } from 'react';
import Icon from '@material-ui/core/Icon';
import { getCuisines, getWhitelabels, getWhitelabelsData } from '../../services/newBrandBranch/checkBrandExistApiCall';
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { getAllBrandListAction, getMerchantByBrandIdAction } from '../../services/newBrandBranch/checkBrandExistApiCall';
import * as yup from 'yup';
import MultiSelect from "react-multi-select-component";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { updateBrandWhiteLabelAction } from '../../services/newBrandBranch/createBrandApiCall';
import { updateBranchWhiteLabelAction } from '../../services/newBrandBranch/createBranchApiCall';
import {
    success as successNotificationAction,
    error as errorNotificationAction,
} from 'react-notification-system-redux';
import { notificationOpts } from '../../config';
import config from '../../config/config';
import { dashboardSelectors } from '../../redux/dashboard';

export default function WhitelabelControls() {

    const dispatch = useDispatch();
    const [isLoader, setIsLoader] = useState(false);
    const [whitelabels, setWhitelabels] = useState([]);
    const [selectedWhiteLabel, setSelectedWhiteLabel] = useState({});
    const [selectedPhase, setSelectedPhase] = useState({});
    const [phaseList, setPhaseList] = useState([]);
    const [allBrandList, setAllBrandList] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState({});
    const [brandDetails, setBrandDetails] = useState({});
    const [brandFieldError, setBrandFieldError] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [branchList, setBranchList] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState([]);
    const [branchFieldError, setBranchFieldError] = useState({});
    const [allCuisinesList, setAllCuisinesList] = useState([]);
    const [phasesAvailable, setPhasesAvailable] = useState([])
    const whitelabelData = useSelector(dashboardSelectors.getOfferTypes)
    const [branchStrings, setBranchStrings] = useState({
        "selectSomeItems": "Select Branch",
        "allItemsAreSelected": "All Branch are selected.",
        "selectAll": "All Branch",
        "search": "Search",
        "clearSearch": "Clear Search"
    });

    const [overrideStrings, setOverrideStrings] = useState({
        "selectSomeItems": "Select Cuisines",
        "allItemsAreSelected": "All Cuisines are selected.",
        "selectAll": "All Cuisines",
        "search": "Search",
        "clearSearch": "Clear Search"
    });
    // useEffect(() => {
    //     getWhitelabelsData(e => {
    //         console.log("Whitelabel data    :  " + JSON.stringify(e))
    //         const phasesAvailable = [];

    //         if (e.phases) {
    //             e.phases.forEach(element => {
    //                 element.label = element.phaseNumber;
    //                 element.value = element.phaseNumber;
    //                 if (new Date() >= new Date(element.phaseStartDate) && new Date() <= new Date(element.phaseEndDate)) {
    //                     setSelectedPhase(element);
    //                     phasesAvailable.push(element.value);
    //                 }
    //             });
    //             setPhaseList(e.phases);
    //         }
    //         setSelectedWhiteLabel(e);
    //         setPhasesAvailable(phasesAvailable);
    //         setWhitelabels(e);
    //         getBrandData();
    //     })
    // }, [])
    useEffect(async () => {
        dispatch(getWhitelabels(response => {
            console.log("Response   ?:   1" + JSON.stringify(response))
            if (response) {
                response.forEach(e => {
                    if (e.partnerSerialNo == "KPSNAM01") {
                        if (e.phases) {
                            setPhaseList(e.phases);
                        }
                        setSelectedWhiteLabel(e);
                    }
                });
            }
            setWhitelabels(response);
        }));
        dispatch(getCuisines(response => {
            setAllCuisinesList(response);
        }));
    }, []);
    const getBrandData = (async (phasesAvailable) => {
        console.log("getBrandData   :    " + JSON.stringify(phasesAvailable))
        console.log("Get WHite Labels info: 2", JSON.stringify(phaseList));
        dispatch(getAllBrandListAction(response => {
            if (response.length) {
                let list = [];
                response.forEach(e1 => {
                    if (e1.whitelabel && Object.keys(e1.whitelabel).length > 0) {
                        let isOffline = true;

                        Object.keys(e1.whitelabel).forEach(e2 => {
                            const brandPhase = e1.whitelabel[e2]?.brandPhase || [];
                            console.log("Checking phases for whitelabel Brand Name :", JSON.stringify(e1.label));
                            console.log("Checking phases for whitelabel:", brandPhase);
                            console.log("Available phases:", phasesAvailable);

                            // Check if any phase in `phasesAvailable` exists in `brandPhase`
                            console.log("Brand Action Center:  Get WHite Labels info  :   " + JSON.stringify(phasesAvailable));
                            if (phasesAvailable.some(phase => brandPhase.includes(phase))) {
                                console.log("Brand Action Center: Match found for phase:", JSON.stringify(brandPhase));
                                isOffline = false;
                            } else {
                                console.log("Brand Action Center: No match for phasesAvailable in brandPhase.");
                                isOffline = true;
                            }
                        });

                        e1.disabled = isOffline;
                        list.push(e1);

                    }
                });
                setAllBrandList(list);
            }
        }));
    })
    useEffect(() => {
        const fetchData = async () => {
            getWhitelabelsData(response => {
                console.log("Response   ?:   " + JSON.stringify(response))
                const phasesAvailable = [];
                if (response) {
                    response.forEach(e => {
                        console.log("Response   Serial No?:  E  " + JSON.stringify(e))
                        if (e.partnerSerialNo === "KPSNAM01") {
                            console.log("Response   Serial No?: No  " + JSON.stringify(e))
                            if (e.phases) {
                                console.log("Response   Serial No?:  Phase  " + JSON.stringify(e.phases))
                                e.phases.forEach(element => {
                                    element.label = element.phaseNumber;
                                    element.value = element.phaseNumber;
                                    if (new Date() >= new Date(element.phaseStartDate) && new Date() <= new Date(element.phaseEndDate)) {
                                        setSelectedPhase(element);
                                        phasesAvailable.push(element.value);
                                        console.log("Get WHite Labels info: Phases ", JSON.stringify(phasesAvailable));
                                        setPhasesAvailable(phasesAvailable);
                                    }
                                });
                                setPhaseList(e.phases);
                            }
                        }
                    });
                    // setPhasesAvailable(phasesAvailable);
                    console.log("Get WHite Labels info: 1", JSON.stringify(phasesAvailable));
                    console.log("Get WHite Labels info: 2", JSON.stringify(phaseList));
                }
                console.log("Get WHite Labels info: 3", JSON.stringify(phasesAvailable));
                setWhitelabels(response);
                getBrandData(phasesAvailable);
            })
        };
        fetchData();
    }, [phaseList.length]);



    // // Function to fetch brand data
    // const getBrandData = async () => {
    //     dispatch(getAllBrandListAction(response => {
    //         if (response.length) {
    //             let list = [];
    //             response.forEach(e1 => {
    //                 if (e1.whitelabel && Object.keys(e1.whitelabel).length > 0) {
    //                     let isOffline = true;

    //                     Object.keys(e1.whitelabel).forEach(e2 => {
    //                         const brandPhase = e1.whitelabel[e2]?.brandPhase || [];
    //                         console.log("Checking phases for whitelabel Brand Name:", JSON.stringify(e1.label));
    //                         console.log("Checking phases for whitelabel:", brandPhase);
    //                         console.log("Available phases:", phasesAvailable);

    //                         // Check if any phase in `phasesAvailable` exists in `brandPhase`
    //                         console.log("Brand Action Center: Get WHite Labels info:", JSON.stringify(phasesAvailable));
    //                         if (phasesAvailable.some(phase => brandPhase.includes(phase))) {
    //                             console.log("Brand Action Center: Match found for phase:", JSON.stringify(brandPhase));
    //                             isOffline = false;
    //                         } else {
    //                             console.log("Brand Action Center: No match for phasesAvailable in brandPhase.");
    //                             isOffline = true;
    //                         }
    //                     });

    //                     e1.disabled = isOffline;
    //                     list.push(e1);
    //                 }
    //             });
    //             setAllBrandList(list);
    //         }
    //     }));
    // };



    const onChangeWhiteLabel = (e) => {
        if (e.phases) {
            e.phases.forEach(element => {
                element.label = element.phaseNumber;
                element.value = element.phaseNumber;
                if (new Date() >= new Date(element.phaseStartDate) && new Date() <= new Date(element.phaseEndDate)) {
                    setSelectedPhase(element);
                }
            });
            setPhaseList(e.phases);
        }
        setSelectedWhiteLabel(e)
    };

    const onChangeBrand = (e) => {
        setBrandDetails({});
        setSelectedBranch([]);
        if (e.whitelabel && e.whitelabel[selectedWhiteLabel.partnerSerialNo]) {
            let data = e.whitelabel[selectedWhiteLabel.partnerSerialNo];
            Object.keys(data).forEach(e2 => {
                e[e2] = data[e2];
            });
        }
        setBrandDetails(e);
        setSelectedBrand(e);
        getMerchantByBrandId(e.value);
    }

    const inputField = (row, placeholder, type, controlType, required, disabled) => {

        let helperText = "";
        if (brandFieldError && brandFieldError[type]) {
            helperText = brandFieldError[type];
        }

        return <>
            <FormControl className='w-100' variant="standard">

                {controlType == "textbox" &&
                    <TextField
                        value={row[type]}
                        id={`brandid${type}`}
                        name={`brandname${type}`}
                        type="text" label={placeholder}
                        required={required}
                        disabled={disabled}
                        error={helperText}
                        className={`form-control mb-3`}
                        onChange={e => handleInputChange(e, type)}
                    />
                }

                {/* {controlType == "dropdown" && type == "offerCategory" &&
                    <>
                        <label>{placeholder}{required && <span className='MuiInputLabel-asterisk'>&nbsp;*</span>}</label>
                        <select
                            name="offerCategory"
                            className="form-control"
                            value={row.offerCategory}
                            onChange={(e) => {
                                handleInputChange(e, type);
                            }}>
                            <option value="">{placeholder}</option>
                            <option value={"dining"} >Dining</option>
                        </select>
                    </>
                } */}

                {/* {controlType == "dropdown" && type == "forMap" &&
                    <>
                        <label>{placeholder}{required && <span className='MuiInputLabel-asterisk'>&nbsp;*</span>}</label>
                        <select
                            name="forMap"
                            className="form-control"
                            value={row.forMap}
                            required={required}
                            onChange={(e) => {
                                handleInputChange(e, type);
                            }}>
                            <option value="">{placeholder}</option>
                            <option value={"Yes"} >Yes</option>
                            <option value={"No"} >No</option>
                        </select>
                    </>
                } */}

                <FormHelperText className='text-danger'>
                    {helperText}
                </FormHelperText>

            </FormControl>
        </>
    }

    const handleInputChange = (e, type) => {
        const { value, checked } = e.target;
        let data1 = brandDetails;
        if (type == "isGiftCardsAllowed" || type == "forMap" || type == "isBrandLive") {
            data1 = { ...data1, [type]: checked };
        } else {
            data1 = { ...data1, [type]: value };
        }
        setBrandDetails(data1);
        
    };

    const updateWhiteLabelData = async () => {

        setIsSubmitting(true);
        let schemaObj = {
            brandName: yup.string().required("Brand name is required"),
            brandLogoUrl: yup.string().required("Brand Logo URL is required"),
            brandMapLogo: yup.string().required("Brand Map Logo is required"),
            // forMap: yup.string().required("Wet Venues is required"),
            // offerCategory: yup.string().required("Offer Category is required"),
        };

        const validationSchema = yup.object(schemaObj);
        let obj = {
            brandName: brandDetails.brandName,
            brandLogoUrl: brandDetails.brandLogoUrl,
            brandMapLogo: brandDetails.brandMapLogo,
            forMap: brandDetails.forMap,
            offerId: brandDetails.offerId || "",
            isGiftCardsAllowed: brandDetails.isGiftCardsAllowed,
            isBrandLive: brandDetails.isBrandLive,
            // offerCategory: brandDetails.offerCategory
        }

        validationSchema.validate(obj, { abortEarly: false }).then(res => {
            setBrandFieldError({})
        }, (e) => {
            console.log(JSON.stringify(e));
            if (e.inner.length > 0) {
                let errorMsg = {}
                e.inner.forEach(res => {
                    let fieldName = res.path;
                    let indexValue = fieldName;
                    ["items", "[", "]"].forEach(res => {
                        indexValue = indexValue.replace(res, '')
                    })
                    if (errorMsg[indexValue]) {
                        errorMsg[indexValue] = res.message;
                    } else {
                        errorMsg[indexValue] = res.message
                    }
                })
                setBrandFieldError(errorMsg)
                setIsSubmitting(false);
            }
        });

        await validationSchema.isValid(obj).then(async (res) => {
            if (res) {
                let reqData = {
                    offer_id: selectedBrand.value,
                    partnerSerialNo: selectedWhiteLabel.partnerSerialNo,
                    whiteLabelData: {
                        "brandLogoUrl": obj.brandLogoUrl,
                        "brandMapLogo": obj.brandMapLogo,
                        "brandName": obj.brandName,
                        "forMap": obj.forMap,
                        "offerId": obj.offerId,
                        "isGiftCardsAllowed": obj.isGiftCardsAllowed,
                        "isBrandLive": obj.isBrandLive,
                        // "offerCategory": obj.offerCategory,
                        // "offerEndDate": obj.offerEndDate,
                        // "offerStartDate": obj.offerStartDate
                    }
                };
                setIsLoader(true);
                dispatch(updateBrandWhiteLabelAction(reqData, response => {
                    if (response.status == 200 && response.data.respCode == 205) {
                        setIsLoader(false);
                        showMessage("Updated successfully", "success");
                    }
                }));

            }
        })

    }

    const showMessage = (message, type) => {
        let notification = { ...notificationOpts };
        notification.message = message;
        if (type == "error") {
            dispatch(errorNotificationAction(notification))
        } else {
            dispatch(successNotificationAction(notification))
        }
    }

    // const getMerchantByBrandId = async (brandId) => {
    //     console.log("Get Merchant By Brand Id    :   " + brandId)
    //     dispatch(getMerchantByBrandIdAction(brandId, response => {
    //         console.log("Get Merchant By Brand Id    :   " + JSON.stringify(response))
    //         if (response.length) {
    //             let list = [];
    //             response.forEach(e1 => {
    //                 if (e1.whitelabel && Object.keys(e1.whitelabel).length > 0) {
    //                     let isOffline = true;
    //                     Object.keys(e1.whitelabel).forEach(e2 => {
    //                         if (e1.whitelabel[e2] && e1.whitelabel[e2].isBranchLive) {
    //                             isOffline = false;
    //                         }
    //                     });
    //                     e1.disabled = isOffline;
    //                     list.push(e1);
    //                 }
    //             });
    //             setBranchList(response);
    //         }
    //     }));
    // }
    const getMerchantByBrandId = async (brandId) => {
        console.log("Get Merchant By Brand Id    :   " + brandId);

        dispatch(getMerchantByBrandIdAction(brandId, (response) => {
            console.log("Get Merchant By Brand Id Response: ", response);

            if (Array.isArray(response) && response.length) {
                let list = [];

                response.forEach((e1) => {
                    if (e1.whitelabel && Object.keys(e1.whitelabel).length > 0) {
                        let isOffline = true;

                        Object.keys(e1.whitelabel).forEach((e2) => {
                            const whitelabelEntry = e1.whitelabel[e2];

                            // Handle branchAssets formatting
                            if (whitelabelEntry.branchAssets && Array.isArray(whitelabelEntry.branchAssets)) {
                                whitelabelEntry.branchAssets.forEach((asset) => {
                                    if (asset.branchImage && typeof asset.branchImage === "string") {
                                        asset.branchImage = asset.branchImage.split(/,\s*/);
                                    }
                                });
                            }

                            // Handle Experience Formatting
                            if (whitelabelEntry.branchExperience) {
                                whitelabelEntry.branchExperience.expAssets =
                                    whitelabelEntry.branchExperience.expAssets || [];
                            }

                            if (whitelabelEntry && whitelabelEntry.isBranchLive) {
                                isOffline = false;
                            }
                        });

                        e1.disabled = isOffline;
                        list.push(e1);
                    }
                });

                setBranchList(list); // Use the filtered and formatted list
                console.log("Formatted List for UI:", JSON.stringify(list));
            } else {
                console.error("Invalid or empty response:", response);
            }
        }));

    };



    const branchRenderer = (selected, _options) => {
        if (selected.length != _options.length) {
            return selected.length == 1 ? selected.label : selected.length == 2 ? `${selected[0].label},${selected[1].label}` : selected.length > 1 ? "Multiple Branch selected" : "Select Branch"
        }
    };


    const customValueRenderer = (selected, _options) => {
        if (selected.length != _options.length) {
            return selected.length == 1 ? selected.label : selected.length == 2 ? `${selected[0].label},${selected[1].label}` : selected.length > 1 ? "Multiple cuisine selected" : "Select Cuisine"
        }
    };

    const branchInputField = (row, placeholder, type, index, controlType, required, disabled) => {
        console.log("branchInputField :  " + `brandname${type}${index}`)
        console.log("branchInputField :  2  " + JSON.stringify(row) + "  :  " + placeholder + "  :  " + type + "  :  " + index + "  :  " + controlType + "  :  " + required + "  :  " + disabled)

        let helperText = "";
        if (branchFieldError && branchFieldError[index] && branchFieldError[index][type]) {
            helperText = branchFieldError[index][type];
        }

        return <>
            <FormControl className='w-100' variant="standard">

                {controlType == "textbox" &&
                    <TextField
                        value={row[type]}
                        id={`brandid${type}${index}`}
                        name={`brandname${type}${index}`}
                        type="text" label={placeholder}
                        required={required}
                        disabled={disabled}
                        error={helperText}
                        className={`form-control mb-3`}
                        onChange={e => handleBranchInputChange(e, type, index)}
                    />
                }
                <FormHelperText className='text-danger'>
                    {helperText}
                </FormHelperText>

            </FormControl>
        </>
    }

    const handleBranchInputChange = (e, type, index) => {
        const { value, checked } = e.target;
        const data1 = [...selectedBranch];
        let data2 = data1[index];
        if (type == "isFeatured" || type == "isBranchExperience" || type == "isBranchLive") {
            data2 = { ...data2, [type]: checked };
        } else {
            data2 = { ...data2, [type]: value };
        }
        data1[index] = data2;
        setSelectedBranch(data1);
    };

    const branchDateField = (row, placeholder, type, index, required) => {

        let helperText = "";
        if (branchFieldError && branchFieldError[index] && branchFieldError[index][type]) {
            helperText = branchFieldError[index][type];
        }

        return <>
            <div className="form-group pl-0 mb-0 w-100">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker label={placeholder}
                        value={row[type] ? new Date(row[type]) : ""}
                        slotProps={{ textField: { required: required } }}
                        onChange={(newValue) => branchDateUpdate(newValue, index, type)}
                        minDate={row.minStartDate ? new Date(row.minStartDate) : ""}
                        maxDate={row.maxEndDate ? new Date(row.maxEndDate) : ""}
                        format="dd-MM-yyyy" />
                </LocalizationProvider>
                <FormHelperText className='text-danger'>
                    {helperText}
                </FormHelperText>
            </div>
        </>
    }

    const branchDateUpdate = (value, index, type) => {
        const data1 = [...selectedBranch];
        let data2 = { ...data1[index], [type]: value };
        data1[index] = data2;
        setSelectedBranch(data1);
    };

    const updateExpDateValue = (value, index, type) => {
        let list = [...selectedBranch];
        let obj = { ...list[index].branchExperience, [type]: value };
        list[index].branchExperience = obj;
        setSelectedBranch(list);
    }

    // const onSelectedBranch = (value ) => {
    //     console.log("Branch Selected Details are ", JSON.stringify(value)); // Debug selected value
    //     value.forEach(element => {
    //         if (element.whitelabel && element.whitelabel[selectedWhiteLabel.partnerSerialNo] && !element.isAssigned) {
    //             let data = element.whitelabel[selectedWhiteLabel.partnerSerialNo];

    //             if (data.cuisines_list) {
    //                 let splitData = data.cuisines_list.split(",");
    //                 data.cuisines_list = [];
    //                 splitData.forEach(e2 => {
    //                     e2 = e2.replace(/^\s+|\s+$/g, '');
    //                     data.cuisines_list.push({ label: e2, value: e2 });
    //                 });
    //             } else {
    //                 data.cuisines_list = [];
    //             }

    //             Object.keys(data).forEach(e2 => {
    //                 element[e2] = data[e2];
    //             });
    //             element.isAssigned = true;

    //             if (!element.branchAssets) {
    //                 element.branchAssets = [
    //                     { branchImage: "", branchVideo: "" }
    //                 ]
    //             }

    //             // if (!element.branchExperience) {
    //             //     element.branchExperience = {
    //             //         expStartDate: "",
    //             //         expEndDate: "",
    //             //         expName: "",
    //             //         expCopy: "",
    //             //         expAssets: [
    //             //             { "branchExpImage": "", "branchExpThumbnail": "", "branchExpVideo": "" }
    //             //         ]
    //             //     }
    //             //     element.isBranchExperience = false;
    //             // } else {
    //             //     element.branchExperience.expStartDate = element.branchExperience.expStartDate ? new Date(element.branchExperience.expStartDate) : "";
    //             //     element.branchExperience.expEndDate = element.branchExperience.expEndDate ? new Date(element.branchExperience.expEndDate) : "";
    //             //     element.isBranchExperience = true;
    //             // }

    //             // element.branchStartDate = new Date(element.branchStartDate);
    //             // element.branchEndDate = new Date(element.branchEndDate);
    //             // element.minStartDate = new Date(brandDetails.offerStartDate);
    //             // element.maxEndDate = new Date(brandDetails.offerEndDate);
    //         }
    //     });
    //     setSelectedBranch(value);
    // }

    // const onSelectedBranch = (value) => {
    //     console.log("Selected Branch Values: ", JSON.stringify(value));

    //     // Map selected values to full branch details
    //     const selectedDetails = value.map((selected) => {
    //         const fullBranch = branchList.find((branch) => branch.kmid === selected.value);
    //         return fullBranch ? { ...fullBranch, ...selected } : selected; // Merge selected and full branch details
    //     });

    //     console.log("Selected Branch Details: ", JSON.stringify(selectedDetails));

    //     // Update the selectedBranch state with the full branch details
    //     setSelectedBranch(selectedDetails);
    // };



    const onSelectedBranch = (value) => {
        console.log("Selected Branch Details: ", JSON.stringify(value));

        const updatedBranches = value.map((selected) => {
            const branch = branchList.find((b) => b.kmid === selected.value);
            if (branch && branch.whitelabel && !branch.isAssigned) {
                const whitelabelData = branch.whitelabel[selectedWhiteLabel.partnerSerialNo];

                if (whitelabelData) {
                    // Process cuisines list
                    if (whitelabelData.cuisines_list) {
                        const cuisines = whitelabelData.cuisines_list.split(",").map((cuisine) => ({
                            label: cuisine.trim(),
                            value: cuisine.trim()
                        }));
                        whitelabelData.cuisines_list = cuisines;
                    } else {
                        whitelabelData.cuisines_list = [];
                    }

                    // Add missing fields
                    if (!branch.branchAssets) {
                        branch.branchAssets = [{ branchImage: "", branchVideo: "" }];
                    }

                    branch.isAssigned = true;
                }

                return { ...branch, ...whitelabelData };
            }

            return branch;
        });

        setSelectedBranch(updatedBranches);
    };



    const imageContainControls = (row, placeholder, type, index, i_index) => {
        return <>
            <FormControl className='w-100' variant="standard">
                <TextField
                    autoComplete='off'
                    value={row[type]}
                    id={`imagebranchid${type}${index}`}
                    name={`imagebranchname${type}${index}`}
                    type="text" label={placeholder}
                    className={`form-control mb-3`}
                    onChange={e => updateImageContainRowValue(e, type, index, i_index)}
                />
            </FormControl>
        </>
    }

    const updateImageContainRowValue = (e, type, index, i_index) => {
        const { value } = e.target;
        let list = [...selectedBranch];
        let obj = { ...list[index].branchAssets[i_index], [type]: value };
        list[index].branchAssets[i_index] = obj;
        setSelectedBranch(list);
    }

    const addImageContainRow = (index) => {
        let list = [...selectedBranch];
        list[index].branchAssets.push({ branchImage: "", branchVideo: "" });
        setSelectedBranch(list);
    }

    const removeImageContainRow = (index, image_index) => {
        let list = [...selectedBranch];
        list[index].branchAssets.splice(image_index, 1);
        setSelectedBranch(list);
    }

    const expControls = (row, placeholder, type, index, exp_index) => {
        return <>
            <FormControl className='w-100' variant="standard">
                <TextField
                    autoComplete='off'
                    value={row[type]}
                    id={`expid${type}${index}${exp_index}`}
                    name={`expname${type}${index}${exp_index}`}
                    type="text" label={placeholder}
                    className={`form-control mb-3`}
                    onChange={e => updateExpControlsValue(e, type, index, exp_index)}
                />
            </FormControl>
        </>
    }

    const updateExpControlsValue = (e, type, index, exp_index) => {
        const { value } = e.target;
        let list = [...selectedBranch];
        if (exp_index === "") {
            let obj = { ...list[index].branchExperience, [type]: value };
            list[index].branchExperience = obj;
        } else {
            let obj = { ...list[index].branchExperience.expAssets[exp_index], [type]: value };
            list[index].branchExperience.expAssets[exp_index] = obj;
        }
        setSelectedBranch(list);
    }

    const addExpRow = (index) => {
        let list = [...selectedBranch];
        list[index].branchExperience.expAssets.push({
            "branchExpImage": "", "branchExpThumbnail": "", "branchExpVideo": ""
        });
        setSelectedBranch(list);
    }

    const removeExpRow = (index, exp_index) => {
        let list = [...selectedBranch];
        list[index].branchExperience.expAssets.splice(exp_index, 1);
        setSelectedBranch(list);
    };

    const formattedBranchList = branchList.map((branch) => ({
        label: branch.branchName,
        value: branch.kmid,
        // ...branch // Include all branch properties
    }));


    const updateBranchWhiteLabelData = async () => {

        let notFoundOfferBranchId = false;
        let count = 0;
        // selectedBranch.forEach(element => {
        //     console.log("selectedBranch    :    "+JSON.stringify(element))
        //     if (!element.offerBranchId) {
        //         notFoundOfferBranchId = true;
        //         count = count + 1;
        //     }
        // });

        if (notFoundOfferBranchId) {
            showMessage(count + " Branch(s) Offer Id not found for the " + brandDetails.brandName, "error");
            return;
        }

        let schema = yup.object({
            items: yup.array().of(
                yup.object().shape({
                    branchName: yup.string().required("Branch name is required"),
                    branchCopy: yup.string().required("Branch copy is required"),
                    displayAddress: yup.string().required("Display address is required"),
                    gmap_address: yup.string().required("Google Maps address is required"),
                    // branchStartDate: yup.date().typeError('Branch Start Date is required').required("Branch Start Date is required"),
                    // branchEndDate: yup.date().typeError('Branch End Date is required').required("Branch End Date is required"),
                    // bookingLink: yup.string().required("Booking Link is required"),
                    cuisines_list: yup.array().required(`Please select cuisines list`),
                })
            )
        });

        let finalArr = []
        selectedBranch.forEach(async (res) => {
            let obj = {
                branchName: res.branchName,
                branchCopy: res.branchCopy,
                displayAddress: res.displayAddress,
                gmap_address: res.gmap_address,
                // branchStartDate: res.branchStartDate,
                // branchEndDate: res.branchEndDate,
                bookingLink: res.bookingLink,
                cuisines_list: res.cuisines_list
            }
            finalArr.push(obj);
        })

        try {
            schema.validate({ items: finalArr }, { abortEarly: false }).then(res => {
                setBranchFieldError({})
            }, (e) => {
                console.log(JSON.stringify(e));
                if (e.inner.length > 0) {
                    let errorMsg = {}
                    e.inner.forEach(res => {
                        let fieldName = res.path.split(".");
                        let indexValue = fieldName[0];
                        ["items", "[", "]"].forEach(res => {
                            indexValue = indexValue.replace(res, '')
                        })

                        if (errorMsg[indexValue]) {
                            if (!errorMsg[indexValue][fieldName[1]]) {
                                errorMsg[indexValue][fieldName[1]] = res.message
                            }
                        } else {
                            errorMsg[indexValue] = {}
                            errorMsg[indexValue][fieldName[1]] = res.message
                        }

                    })
                    setBranchFieldError(errorMsg);
                }
            });

        } catch (e) {
            console.log(e)
        }

        let notFoundkBranchName = false;
        selectedBranch.forEach(e7 => {
            if (e7.branchAssets) {
                let flag = false;
                for (let index = 0; index < e7.branchAssets.length; index++) {
                    const e9 = e7.branchAssets[index];
                    if (e9.branchImage) {
                        flag = true;
                        break;
                    }
                }
                if (!flag) notFoundkBranchName = true;
            }
        });

        if (notFoundkBranchName) {
            showMessage("Please add the branch image for the branch", "error");
            return;
        }

        await schema.isValid({ items: finalArr }).then(async (res) => {
            if (res) {
                let reqData = {
                    brandId: selectedBrand.value,
                    partnerSerialNo: selectedWhiteLabel.partnerSerialNo,
                    branches: []
                };

                selectedBranch.forEach(element => {

                    let c_list = "";
                    if (element.cuisines_list) {
                        c_list = [];
                        element.cuisines_list.forEach(e2 => {
                            c_list.push(e2.value);
                        });
                        c_list = c_list.join(", ");
                    }

                    reqData.branches.push({
                        kmid: element.kmid,
                        whiteLabelData: {
                            "branchName": element.branchName,
                            "branchCopy": element.branchCopy,
                            "displayAddress": element.displayAddress,
                            "gmap_address": element.gmap_address,
                            // "branchStartDate": element.branchStartDate,
                            // "branchEndDate": element.branchEndDate,
                            "bookingLink": element.bookingLink,
                            "cuisines_list": c_list,
                            "isFeatured": element.isFeatured,
                            "isBranchLive": element.isBranchLive,
                            "branchAssets": element.branchAssets,
                            // "branchExperience": element.branchExperience,
                            "offerBranchId": element.offerBranchId?element.offerBranchId:"",
                        }
                    })
                });
                setIsLoader(true);
                dispatch(updateBranchWhiteLabelAction(reqData, response => {
                    if (response.status == 200 && response.data.respCode == 205) {
                        setIsLoader(false);
                        showMessage("Updated successfully", "success");
                    }
                }));

            } else {
                // setisValidate(true)
            }
        })

    }

    const clearData = () => {
        setSelectedWhiteLabel({});
        setSelectedPhase({});
        setPhaseList([]);
        setSelectedBrand({});
        setBrandDetails({});
        setBrandFieldError({});
        setBranchList([]);
        setSelectedBranch([]);
        setBranchFieldError({});
    }

    const prepareExperiencePayload = (branchExperience) => {
        return {
            branch_experience: {
                M: {
                    name: {
                        S: branchExperience.expName || "",
                    },
                    copy: {
                        S: branchExperience.expCopy || "",
                    },
                    start_date: {
                        S: branchExperience.expStartDate || "",
                    },
                    end_date: {
                        S: branchExperience.expEndDate || "",
                    },
                    booking_link: {
                        S: branchExperience.bookingLink || "",
                    },
                    image_list: {
                        L: (branchExperience.expImageList || []).map((image) => ({
                            S: image,
                        })),
                    },
                    image_thumbnail_list: {
                        L: (branchExperience.expThumbnailList || []).map((thumbnail) => ({
                            S: thumbnail,
                        })),
                    },
                    video_list: {
                        L: (branchExperience.expVideoList || []).map((video) => ({
                            S: video,
                        })),
                    },
                },
            },
        };
    };
    const updateArrayFieldValue = (e, index, field, subIndex, subField) => {
        const value = e.target.value;
        const updatedBranchList = [...selectedBranch];
        updatedBranchList[index].whitelabel.KPSNAM01.branchExperience[field][subIndex][subField] = value;
        setSelectedBranch(updatedBranchList);
    };

    const removeArrayFieldValue = (index, field, fieldIndex) => {
        const updatedBranchList = [...selectedBranch];
        if (updatedBranchList[index].whitelabel.KPSNAM01.branchExperience[field]) {
            updatedBranchList[index].whitelabel.KPSNAM01.branchExperience[field].splice(fieldIndex, 1);
        }
        setSelectedBranch(updatedBranchList);
    };


    const addArrayFieldValue = (index, field) => {
        const updatedBranchList = [...selectedBranch];

        // Ensure branchExperience exists
        if (!updatedBranchList[index].whitelabel?.KPSNAM01?.branchExperience) {
            updatedBranchList[index].whitelabel.KPSNAM01.branchExperience = {};
        }

        // Ensure the specific field (image_list, image_thumbnail_list, etc.) exists
        if (!updatedBranchList[index].whitelabel.KPSNAM01.branchExperience[field]) {
            updatedBranchList[index].whitelabel.KPSNAM01.branchExperience[field] = [];
        }

        // Add a new object to the field array
        updatedBranchList[index].whitelabel.KPSNAM01.branchExperience[field].push({ S: "" });

        // Update the state with the modified branch list
        setSelectedBranch(updatedBranchList);
    };

    console.log("logsssssssssssssssssssssssss", brandDetails)



    return (
        <>
            <div>
                <div className="row action-center">
                    <div className="col-md-12">
                        <div className="heading">
                            <div className="row">
                                <div className="col-md-8 d-flex align-items-center">
                                    <h2 className="m-0 ">
                                        <span>
                                            <Icon className="heading-icon">pending_actions</Icon>
                                            Whitelabel Controls
                                        </span>
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="card">

                            <div className="row col-12">

                                <div className="row col-12">

                                    <div className="col-3">
                                        <label> Select WhiteLabel </label>
                                        <Select
                                            options={whitelabels}
                                            onChange={(e) => { onChangeWhiteLabel(e) }}
                                            value={selectedWhiteLabel}
                                            className='action_Selecter' />
                                    </div>

                                    {/* <div className="col-3">
                                        <label> Current Phase </label>
                                        <Select
                                            options={phaseList}
                                            isDisabled={true}
                                            value={selectedPhase}
                                            className='action_Selecter' />
                                    </div> */}

                                    <div className="col-3">
                                        <label> Current Phase </label>
                                        <Select
                                            options={phaseList}
                                            isDisabled={true}
                                            isMulti // Enables multi-select functionality
                                            value={phaseList.filter(option => phasesAvailable.includes(option.value))} // Filter selected values
                                            className='action_Selecter'
                                        />
                                    </div>


                                    <div className="col-3">
                                        <label> Select Brand </label>
                                        <Select
                                            options={allBrandList}
                                            isDisabled={(!selectedWhiteLabel || !selectedWhiteLabel.value) ? true : false}
                                            onChange={(e) => { onChangeBrand(e) }}
                                            value={selectedBrand}
                                            isOptionDisabled={(option) => option.disabled}
                                            className='action_Selecter' />
                                    </div>

                                    <div className="col-3 m-t-25">
                                        <button className="btn btn-sm btn-primary newbrandBtn" onClick={() => clearData()}>
                                            Clear  </button>
                                    </div>

                                </div>

                                {selectedBrand && selectedBrand.value ?
                                    <>
                                        <div className="col-12 header-title">
                                            Brand Detail
                                        </div>
                                        <div className="row col-12 b-box-white m-l-0">

                                            <div className="p-2 col-3 input-group justify-content-center">
                                                {inputField(brandDetails, 'Brand Id', 'value', "textbox", true, true)}
                                            </div>
                                            <div className="p-2 col-3 input-group justify-content-center">
                                                {inputField(brandDetails, 'Brand Name', 'brandName', "textbox", true, false)}
                                            </div>

                                            <div className="p-2 col-3 input-group justify-content-center">
                                                {inputField(brandDetails, 'Brand Logo Url', 'brandLogoUrl', "textbox", true, false)}
                                            </div>

                                            <div className="p-2 col-3 input-group justify-content-center">
                                                {inputField(brandDetails, 'Brand Map Logo', 'brandMapLogo', "textbox", true, false)}
                                            </div>

                                            <div className="p-2 row col-3 min-h">
                                                <div className="col-12 p-l-10">
                                                    Is Gift Cards Allowed
                                                </div>
                                                <div className="row col-12 m-l--5">
                                                    <input type='checkbox' name="isGiftCardsAllowed"
                                                        defaultChecked={brandDetails.isGiftCardsAllowed} className="distributor-checkbox"
                                                        id="isGiftCardsAllowed"
                                                        onChange={(e) => {
                                                            handleInputChange(e, "isGiftCardsAllowed");
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            {/* <div className="p-2 col-3 input-group justify-content-center">
                                                {inputField(brandDetails, 'Select Offer Category', 'offerCategory', "dropdown", true, false)}
                                            </div> */}

                                            {/* <div className="p-2 col-3 input-group justify-content-center">
                                                {inputField(brandDetails, 'Select Wet Venues', 'forMap', "dropdown", true, false)}
                                            </div> */}

                                            <div className="d-flex row col-3 m-t-10 b-checkbox-contain min-h">
                                                <div className="col-12 p-l-10">
                                                    Wet Venues
                                                </div>
                                                <input type='checkbox' name="forMap"
                                                    defaultChecked={brandDetails.forMap} className="b-checkbox"
                                                    id="forMap"
                                                    onChange={(e) => {
                                                        handleInputChange(e, "forMap");
                                                    }} />
                                            </div>

                                            <div className="d-flex row col-3 m-t-10 b-checkbox-contain min-h">
                                                <div className="col-12 p-l-10">
                                                    Brand Live
                                                </div>
                                                <input type='checkbox' name="isBrandLive"
                                                    defaultChecked={brandDetails.isBrandLive} className="b-checkbox"
                                                    id="isBrandLive"
                                                    onChange={(e) => {
                                                        handleInputChange(e, "isBrandLive");
                                                    }} />
                                            </div>

                                            <div className="p-2 col-12 input-group justify-content-end">
                                                <button className="btn btn-sm btn-primary newbrandBtn"
                                                    // disabled={true}
                                                    onClick={() => updateWhiteLabelData()}>  Update  </button>
                                            </div>

                                        </div>
                                    </>
                                    : null}

                                {selectedBrand && selectedBrand.value ?
                                    <>
                                        <div className="col-12 header-title">
                                            Branch Detail
                                        </div>

                                        <div className="row col-12 b-box-white m-l-0">
                                            <div className="p-2 col-3 input-group justify-content-center dropdown-1-1">
                                                <label>Select Branch</label>
                                                <MultiSelect
                                                    className="multi-select"
                                                    options={formattedBranchList} // Use the formatted branch list
                                                    value={selectedBranch.map((branch) => ({
                                                        label: branch.branchName,
                                                        value: branch,
                                                    }))}
                                                    onChange={(value) => onSelectedBranch(value)}
                                                    labelledBy="Select Branch"
                                                    required={true}
                                                    overrideStrings={branchStrings}
                                                    valueRenderer={branchRenderer}
                                                />
                                            </div>
                                        </div>
                                        {
                                            selectedBranch && selectedBranch.length > 0 ? (
                                                selectedBranch.map((b_row, index) => (
                                                    <div key={b_row.value} className="row col-12 b-box-white m-l-0 m-t-10">
                                                        {/* KMID Field */}
                                                        <div className="col-3">
                                                            {branchInputField(b_row, "KMID", "value", index, "textbox", true, true)}
                                                        </div>

                                                        {/* Branch Name Field */}
                                                        <div className="col-3">
                                                            {branchInputField(b_row, "Branch Name", "branchName", index, "textbox", true, false)}
                                                        </div>

                                                        {/* Branch Copy Field */}
                                                        <div className="col-3">
                                                            {branchInputField(b_row, "Branch Copy", "branchCopy", index, "textbox", true, false)}
                                                        </div>

                                                        {/* Display Address */}
                                                        <div className="col-3">
                                                            {branchInputField(b_row, "Display Address", "displayAddress", index, "textbox", true, false)}
                                                        </div>

                                                        {/* Google Maps Address */}
                                                        <div className="col-3 m-t-10">
                                                            {branchInputField(b_row, "Google Maps Address", "gmap_address", index, "textbox", true, false)}
                                                        </div>

                                                        {/* Booking Link */}
                                                        <div className="col-3 m-t-10">
                                                            {branchInputField(b_row, "Booking Link", "bookingLink", index, "textbox", false, false)}
                                                        </div>

                                                        {/* MultiSelect for Cuisines */}
                                                        <div className="p-2 col-3 input-group justify-content-center dropdown-1-1">
                                                            <label>
                                                                Select Cuisines <span className="MuiInputLabel-asterisk">&nbsp;*</span>
                                                            </label>
                                                            <MultiSelect
                                                                className="multi-select"
                                                                options={allCuisinesList}
                                                                value={b_row.cuisines_list || []}
                                                                onChange={(value) => branchDateUpdate(value, index, "cuisines_list")}
                                                                labelledBy={"Select Cuisines"}
                                                                required={true}
                                                                overrideStrings={overrideStrings}
                                                                valueRenderer={customValueRenderer}
                                                            />
                                                            <FormHelperText className="text-danger">
                                                                {branchFieldError && branchFieldError[index]?.["cuisines_list"]}
                                                            </FormHelperText>
                                                        </div>

                                                        {/* Is Featured Checkbox */}
                                                        <div className="col-3" style={{ alignContent: 'flex-end',display:'flex',flexWrap:'nowrap',justifyContent:'space-evenly',alignContent: 'center', alignItems: 'center',flexDirection:'row'}}>
                                                            <input
                                                                type="checkbox"
                                                                name="isFeatured"
                                                                defaultChecked={b_row.isFeatured}
                                                                onChange={(e) => handleBranchInputChange(e, "isFeatured", index)}
                                                            />
                                                            Is Featured
                                                        </div>

                                                        {/* Branch Live Checkbox */}
                                                        <div className="col-3" style={{ alignContent: 'flex-end',display:'flex',flexWrap:'wrap',justifyContent:'space-evenly' }}>
                                                            <input
                                                                type="checkbox"
                                                                name="isBranchLive"
                                                                defaultChecked={b_row.isBranchLive}
                                                                onChange={(e) => handleBranchInputChange(e, "isBranchLive", index)}
                                                            />
                                                            Branch Live
                                                        </div>

                                                        {/* Branch Assets */}
                                                        <div className="row col-12 branch-contain-list p-r-0 m-r-0" style={{ border: 'none', paddingRight: '0px', marginLeft: 'auto' }}>
                                                            {b_row.whitelabel?.KPSNAM01?.branchAssets?.map((asset, assetIndex) => (
                                                                <div key={assetIndex} className="row col-12">
                                                                    <div className="col-3">
                                                                        {imageContainControls(asset, "Branch Image", "branchImage", index, assetIndex)}
                                                                    </div>
                                                                    <div className="col-3">
                                                                        {imageContainControls(asset, "Branch Video", "branchVideo", index, assetIndex)}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>

                                                        {/* Branch Experience */}
                                                        {/* <div className="col-12 header-title" style={{ border: 'none' }}>
                                                            Branch Experience
                                                        </div> */}

                                                        {/* Image List */}
                                                        {/* <div className="col-12 m-b-10">
                                                            <label>Image List</label>
                                                            {(!b_row.whitelabel?.KPSNAM01?.branchExperience?.image_list ||
                                                                b_row.whitelabel.KPSNAM01.branchExperience.image_list.length === 0) && (
                                                                    <div className="d-flex align-items-center m-b-10">
                                                                        <TextField
                                                                            label="Image 1"
                                                                            value=""
                                                                            onChange={(e) => addArrayFieldValue(index, "image_list", e.target.value)}
                                                                            className="flex-grow-1 mr-2"
                                                                        />
                                                                        <Icon
                                                                            className="text-success"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => addArrayFieldValue(index, "image_list")}
                                                                        >
                                                                            add_circle
                                                                        </Icon>
                                                                    </div>
                                                                )}

                                                            {b_row.whitelabel?.KPSNAM01?.branchExperience?.image_list?.map((image, imgIndex) => (
                                                                <div key={imgIndex} className="d-flex align-items-center m-b-10">
                                                                    <TextField
                                                                        label={`Image ${imgIndex + 1}`}
                                                                        value={image.S || ""}
                                                                        onChange={(e) => updateArrayFieldValue(e, index, "image_list", imgIndex, "S")}
                                                                        className="flex-grow-1 mr-2"
                                                                    />
                                                                    <div className="d-flex">
                                                                        <Icon
                                                                            className="text-danger"
                                                                            style={{ cursor: "pointer", marginRight: "5px" }}
                                                                            onClick={() => removeArrayFieldValue(index, "image_list", imgIndex)}
                                                                        >
                                                                            remove_circle
                                                                        </Icon>
                                                                        {imgIndex === b_row.whitelabel?.KPSNAM01?.branchExperience?.image_list?.length - 1 && (
                                                                            <Icon
                                                                                className="text-success"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() => addArrayFieldValue(index, "image_list")}
                                                                            >
                                                                                add_circle
                                                                            </Icon>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div> */}

                                                        {/* Image Thumbnail List */}
                                                        {/* <div className="col-12 m-b-10">
                                                            <label>Image Thumbnail List</label>
                                                            {(!b_row.whitelabel?.KPSNAM01?.branchExperience?.image_thumbnail_list ||
                                                                b_row.whitelabel.KPSNAM01.branchExperience.image_thumbnail_list.length === 0) && (
                                                                    <div className="d-flex align-items-center m-b-10">
                                                                        <TextField
                                                                            label="Thumbnail 1"
                                                                            value=""
                                                                            onChange={(e) => addArrayFieldValue(index, "image_thumbnail_list", e.target.value)}
                                                                            className="flex-grow-1 mr-2"
                                                                        />
                                                                        <Icon
                                                                            className="text-success"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => addArrayFieldValue(index, "image_thumbnail_list")}
                                                                        >
                                                                            add_circle
                                                                        </Icon>
                                                                    </div>
                                                                )}

                                                            {b_row.whitelabel?.KPSNAM01?.branchExperience?.image_thumbnail_list?.map((thumb, thumbIndex) => (
                                                                <div key={thumbIndex} className="d-flex align-items-center m-b-10">
                                                                    <TextField
                                                                        label={`Thumbnail ${thumbIndex + 1}`}
                                                                        value={thumb.S || ""}
                                                                        onChange={(e) => updateArrayFieldValue(e, index, "image_thumbnail_list", thumbIndex, "S")}
                                                                        className="flex-grow-1 mr-2"
                                                                    />
                                                                    <div className="d-flex">
                                                                        <Icon
                                                                            className="text-danger"
                                                                            style={{ cursor: "pointer", marginRight: "5px" }}
                                                                            onClick={() => removeArrayFieldValue(index, "image_thumbnail_list", thumbIndex)}
                                                                        >
                                                                            remove_circle
                                                                        </Icon>
                                                                        {thumbIndex === b_row.whitelabel?.KPSNAM01?.branchExperience?.image_thumbnail_list?.length - 1 && (
                                                                            <Icon
                                                                                className="text-success"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() => addArrayFieldValue(index, "image_thumbnail_list")}
                                                                            >
                                                                                add_circle
                                                                            </Icon>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div> */}

                                                        {/* Video List */}
                                                        {/* <div className="col-12 m-b-10">
                                                            <label>Video List</label>
                                                            {(!b_row.whitelabel?.KPSNAM01?.branchExperience?.video_list ||
                                                                b_row.whitelabel.KPSNAM01.branchExperience.video_list.length === 0) && (
                                                                    <div className="d-flex align-items-center m-b-10">
                                                                        <TextField
                                                                            label="Video 1"
                                                                            value=""
                                                                            onChange={(e) => addArrayFieldValue(index, "video_list", e.target.value)}
                                                                            className="flex-grow-1 mr-2"
                                                                        />
                                                                        <Icon
                                                                            className="text-success"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => addArrayFieldValue(index, "video_list")}
                                                                        >
                                                                            add_circle
                                                                        </Icon>
                                                                    </div>
                                                                )}

                                                            {b_row.whitelabel?.KPSNAM01?.branchExperience?.video_list?.map((video, videoIndex) => (
                                                                <div key={videoIndex} className="d-flex align-items-center m-b-10">
                                                                    <TextField
                                                                        label={`Video ${videoIndex + 1}`}
                                                                        value={video.S || ""}
                                                                        onChange={(e) => updateArrayFieldValue(e, index, "video_list", videoIndex, "S")}
                                                                        className="flex-grow-1 mr-2"
                                                                    />
                                                                    <div className="d-flex">
                                                                        <Icon
                                                                            className="text-danger"
                                                                            style={{ cursor: "pointer", marginRight: "5px" }}
                                                                            onClick={() => removeArrayFieldValue(index, "video_list", videoIndex)}
                                                                        >
                                                                            remove_circle
                                                                        </Icon>
                                                                        {videoIndex === b_row.whitelabel?.KPSNAM01?.branchExperience?.video_list?.length - 1 && (
                                                                            <Icon
                                                                                className="text-success"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() => addArrayFieldValue(index, "video_list")}
                                                                            >
                                                                                add_circle
                                                                            </Icon>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>  */}
                                                    </div>
                                                ))
                                            ) : (
                                                <div>No branches have been selected.</div>
                                            )
                                        }




                                        {selectedBranch && selectedBranch.length > 0 ?
                                            <div className="p-2 col-12 input-group justify-content-end m-t-5">
                                                <button className="btn btn-sm btn-primary newbrandBtn"
                                                    // disabled={true}
                                                    onClick={() => updateBranchWhiteLabelData()}>  Update  </button>
                                            </div>
                                            : ""}

                                    </>
                                    : null}
                            </div>

                        </div>
                    </div>

                </div>

                {isLoader && <div className="loading">Loading...</div>}


            </div>

        </>
        //     <>
        //     <div>
        //         <div className="row action-center">
        //             <div className="col-md-12">
        //                 <div className="heading">
        //                     <div className="row">
        //                         <div className="col-md-8 d-flex align-items-center">
        //                             <h2 className="m-0">
        //                                 <span>
        //                                     <Icon className="heading-icon">pending_actions</Icon>
        //                                     Whitelabel Controls
        //                                 </span>
        //                             </h2>
        //                         </div>
        //                     </div>
        //                 </div>
        //                 <div className="card">
        //                     <div className="row col-12">
        //                         <div className="row col-12">
        //                             <div className="col-3">
        //                                 <label>Select WhiteLabel</label>
        //                                 <Select
        //                                     options={whitelabels}
        //                                     onChange={(e) => onChangeWhiteLabel(e)}
        //                                     value={selectedWhiteLabel}
        //                                     className="action_Selecter"
        //                                 />
        //                             </div>
        //                             <div className="col-3">
        //                                 <label>Current Phase</label>
        //                                 <Select
        //                                     options={phaseList}
        //                                     isDisabled={true}
        //                                     isMulti
        //                                     value={phaseList.filter((option) => phasesAvailable.includes(option.value))}
        //                                     className="action_Selecter"
        //                                 />
        //                             </div>
        //                             <div className="col-3">
        //                                 <label>Select Brand</label>
        //                                 <Select
        //                                     options={allBrandList}
        //                                     isDisabled={!selectedWhiteLabel || !selectedWhiteLabel.value}
        //                                     onChange={(e) => onChangeBrand(e)}
        //                                     value={selectedBrand}
        //                                     isOptionDisabled={(option) => option.disabled}
        //                                     className="action_Selecter"
        //                                 />
        //                             </div>
        //                             <div className="col-3 m-t-25">
        //                                 <button className="btn btn-sm btn-primary newbrandBtn" onClick={clearData}>
        //                                     Clear
        //                                 </button>
        //                             </div>
        //                         </div>

        //                         {selectedBrand && selectedBrand.value && (
        //                             <>
        //                                 <div className="col-12 header-title">Brand Detail</div>
        //                                 <div className="row col-12 b-box-white m-l-0">
        //                                     <div className="p-2 col-3 input-group justify-content-center">
        //                                         {inputField(brandDetails, "Brand Id", "value", "textbox", true, true)}
        //                                     </div>
        //                                     <div className="p-2 col-3 input-group justify-content-center">
        //                                         {inputField(brandDetails, "Brand Name", "brandName", "textbox", true, false)}
        //                                     </div>
        //                                     <div className="p-2 col-3 input-group justify-content-center">
        //                                         {inputField(brandDetails, "Brand Logo Url", "brandLogoUrl", "textbox", true, false)}
        //                                     </div>
        //                                     <div className="p-2 col-3 input-group justify-content-center">
        //                                         {inputField(brandDetails, "Brand Map Logo", "brandMapLogo", "textbox", true, false)}
        //                                     </div>
        //                                     <div className="p-2 row col-3 min-h">
        //                                         <div className="col-12 p-l-10">Is Gift Cards Allowed</div>
        //                                         <div className="row col-12 m-l--5">
        //                                             <input
        //                                                 type="checkbox"
        //                                                 name="isGiftCardsAllowed"
        //                                                 defaultChecked={brandDetails.isGiftCardsAllowed}
        //                                                 className="distributor-checkbox"
        //                                                 id="isGiftCardsAllowed"
        //                                                 onChange={(e) => handleInputChange(e, "isGiftCardsAllowed")}
        //                                             />
        //                                         </div>
        //                                     </div>
        //                                     <div className="d-flex row col-3 m-t-10 b-checkbox-contain min-h">
        //                                         <div className="col-12 p-l-10">Wet Venues</div>
        //                                         <input
        //                                             type="checkbox"
        //                                             name="forMap"
        //                                             defaultChecked={brandDetails.forMap}
        //                                             className="b-checkbox"
        //                                             id="forMap"
        //                                             onChange={(e) => handleInputChange(e, "forMap")}
        //                                         />
        //                                     </div>
        //                                     <div className="d-flex row col-3 m-t-10 b-checkbox-contain min-h">
        //                                         <div className="col-12 p-l-10">Brand Live</div>
        //                                         <input
        //                                             type="checkbox"
        //                                             name="isBrandLive"
        //                                             defaultChecked={brandDetails.isBrandLive}
        //                                             className="b-checkbox"
        //                                             id="isBrandLive"
        //                                             onChange={(e) => handleInputChange(e, "isBrandLive")}
        //                                         />
        //                                     </div>
        //                                     <div className="p-2 col-12 input-group justify-content-end">
        //                                         <button
        //                                             className="btn btn-sm btn-primary newbrandBtn"
        //                                             onClick={updateWhiteLabelData}
        //                                         >
        //                                             Update
        //                                         </button>
        //                                     </div>
        //                                 </div>
        //                             </>
        //                         )}

        //                         {selectedBrand && selectedBrand.value && (
        //                             <>
        //                                 <div className="col-12 header-title">Branch Detail</div>
        //                                 <div className="row col-12 b-box-white m-l-0">
        //                                     <div className="p-2 col-3 input-group justify-content-center dropdown-1-1">
        //                                         <label>Select Branch</label>
        //                                         <MultiSelect
        //                                             className="multi-select"
        //                                             options={formattedBranchList}
        //                                             value={selectedBranch.map((branch) => ({
        //                                                 label: branch.branchName,
        //                                                 value: branch,
        //                                             }))}
        //                                             onChange={onSelectedBranch}
        //                                             labelledBy="Select Branch"
        //                                             required={true}
        //                                             overrideStrings={branchStrings}
        //                                             valueRenderer={branchRenderer}
        //                                         />
        //                                     </div>
        //                                 </div>

        //                                 {selectedBranch?.length > 0 ? (
        //                                     <>
        //                                         {selectedBranch.map((b_row, index) => (
        //                                             <div key={b_row.value} className="row col-12 b-box-white m-l-0 m-t-10">
        //                                                 <div className="col-3">
        //                                                     {branchInputField(
        //                                                         b_row,
        //                                                         "KMID",
        //                                                         "value",
        //                                                         index,
        //                                                         "textbox",
        //                                                         true,
        //                                                         true
        //                                                     )}
        //                                                 </div>
        //                                                 <div className="col-3">
        //                                                     {branchInputField(
        //                                                         b_row,
        //                                                         "Branch Name",
        //                                                         "branchName",
        //                                                         index,
        //                                                         "textbox",
        //                                                         true,
        //                                                         false
        //                                                     )}
        //                                                 </div>

        //                                                 {/* Branch Experience */}
        //                                                 <div className="col-12 header-title">Branch Experience</div>

        //                                                 <div className="col-12 m-b-10">
        //                                                     <label>Image List</label>
        //                                                     {/* Add Image List */}
        //                                                     {/* Ensure at least one empty field exists */}
        //                                                     {(!b_row.whitelabel?.KPSNAM01?.branchExperience?.image_list ||
        //                                                         b_row.whitelabel.KPSNAM01.branchExperience.image_list.length ===
        //                                                             0) && (
        //                                                         <div className="d-flex align-items-center m-b-10">
        //                                                             <TextField
        //                                                                 label="Image 1"
        //                                                                 value=""
        //                                                                 onChange={(e) =>
        //                                                                     addArrayFieldValue(
        //                                                                         index,
        //                                                                         "image_list",
        //                                                                         e.target.value
        //                                                                     )
        //                                                                 }
        //                                                                 className="flex-grow-1 mr-2"
        //                                                             />
        //                                                             <Icon
        //                                                                 className="text-success"
        //                                                                 style={{ cursor: "pointer" }}
        //                                                                 onClick={() => addArrayFieldValue(index, "image_list")}
        //                                                             >
        //                                                                 add_circle
        //                                                             </Icon>
        //                                                         </div>
        //                                                     )}
        //                                                 </div>

        //                                                 <div className="branch-expereince-video" />
        //                                             </div>
        //                                         ))}
        //                                         <div className="p-2 col-12 input-group justify-content-end m-t-5">
        //                                             <button
        //                                                 className="btn btn-sm btn-primary newbrandBtn"
        //                                                 onClick={updateBranchWhiteLabelData}
        //                                             >
        //                                                 Update
        //                                             </button>
        //                                         </div>
        //                                     </>
        //                                 ) : (
        //                                     <div>No branches available.</div>
        //                                 )}
        //                             </>
        //                         )}
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>

        //         {isLoader && <div className="loading">Loading...</div>}
        //     </div>
        // </>


    )
};