import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getBranchListByBrandIdAction } from '../../redux/actions';

function ExperienceForm() {
    const Brandstate = useSelector((state) => state.dashboard);
    const [brands, setBrands] = useState([]);
    const [branches, setBranches] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedBranch, setSelectedBranch] = useState('');
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        bookingLink: '',
        copy: '',
        startDate: '',
        endDate: '',
        experienceDays: '',
        experienceTime: '',
        images: [],
        imageThumbnails: [],
        videoLinks: [],
        title: ''
    });

    // Fetch brands on component load
    useEffect(() => {
        fetchBrands();
    }, []);

    // Fetch branches when a brand is selected
    useEffect(() => {
        if (selectedBrand) {
            fetchBranches(selectedBrand);
        }
    }, [selectedBrand]);

    const fetchBrands = async () => {
        try {
            const response = await fetch('https://8z7ohro9ui.execute-api.eu-west-1.amazonaws.com/default/travx-test-cmb-fetch-all-brand-names', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json, text/plain, */*',
                    'Origin': 'https://amex-test.travx.io',
                    'x-api-key': 'N2TjgYXI9s8E5V1Uq6EDa6KyACi9NOqF6ctyb51F',
                },
                body: JSON.stringify({
                    customer_id: '4ec74af4-5a67-420b-9303-cc31b0460c81',
                    latitude: 51.507351,
                    longitude: -0.127758,
                    region: 'uk'
                }),
            });

            if (response.ok) {
                const data = await response.json();
                console.log("Fetched brands:", data);
                setBrands(data.brands || []); // Assuming the API response has a `brands` key
            } else {
                console.error("Failed to fetch brands. Response status:", response.status);
            }
        } catch (error) {
            console.error("Error fetching brands:", error);
        }
    };

    const fetchBranches = async (brandId) => {
        try {
            // Dispatch action to get the branches based on the selected brand ID
            dispatch(getBranchListByBrandIdAction(brandId))
                .then((data) => {
                    setBranches(data); // Set the branches state once data is fetched
                })
                .catch((error) => {
                    console.error("Error fetching branches:", error);
                });
        } catch (error) {
            console.error("Error fetching branches:", error);
        }
    };

    const handleBrandChange = (e) => {
        const brandId = e.target.value;
        setSelectedBrand(brandId);
    };

    const handleBranchChange = (e) => {
        setSelectedBranch(e.target.value);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.bookingLink || !formData.copy || !formData.startDate || !formData.endDate || !formData.experienceDays || !formData.experienceTime) {
            alert("Please fill in all required fields.");
            return;
        }

        try {
            const response = await fetch('/api/submitExperience', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ selectedBranch, formData }),
            });
            if (response.ok) {
                alert("Experience submitted successfully!");
            } else {
                alert("Failed to submit experience.");
            }
        } catch (error) {
            console.error("Error submitting experience:", error);
        }
    };

    const formSectionStyle = {
        marginBottom: '20px',
    };

    const headingStyle = {
        fontSize: '16px',
        marginBottom: '10px',
    };

    const inputStyle = {
        width: '100%',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '4px',
    };

    return (
        <div className="experience-form" style={{ padding: '20px' }}>
            <form onSubmit={handleSubmit}>

                {/* Brand Selection */}
                <div className="form-section" style={formSectionStyle}>
                    <h3 style={headingStyle}>Brand</h3>
                    <select value={selectedBrand} onChange={handleBrandChange} required style={inputStyle}>
                        <option value="">Select Brand</option>
                        {brands.map(brand => (
                            <option key={brand.id} value={brand.id}>{brand.name}</option>
                        ))}
                    </select>
                </div>

                {/* Branch Selection */}
                <div className="form-section" style={formSectionStyle}>
                    <h3 style={headingStyle}>Branch</h3>
                    <select value={selectedBranch} onChange={handleBranchChange} required style={inputStyle}>
                        <option value="">Select Branch</option>
                        {branches.map(branch => (
                            <option key={branch.id} value={branch.id}>{branch.name}</option>
                        ))}
                    </select>
                </div>

                {/* Render only if a branch is selected */}
                {selectedBranch && (
                    <>
                        {/* Booking Link */}
                        <div className="form-section" style={formSectionStyle}>
                            <h3 style={headingStyle}>Booking Link</h3>
                            <input type="url" name="bookingLink" value={formData.bookingLink} onChange={handleChange} required style={inputStyle} />
                        </div>

                        {/* Other Fields */}
                        {/* Include additional fields (start date, end date, etc.) here */}

                        {/* Submit Button */}
                        <button type="submit" style={{ padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '4px' }}>
                            Submit Experience
                        </button>
                    </>
                )}
            </form>
        </div>
    );
}

export default ExperienceForm;
